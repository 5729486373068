import { createAsyncThunk } from '@reduxjs/toolkit';
import { IGetAllTask, IGetTask, TaskResStatus, TaskResValue } from '../@types/tasksType';
import { makeRequest } from './makeRequest';
import { IFormInput } from '../@types/formTypes';
import { selectToken } from '../Slices/user.slice';
import { RootState } from '../store';
import { ChangeStatusData } from '../../Components/TaskPage/Table/CurrentStatusTask';

export const fetchAllTasks = createAsyncThunk<IGetAllTask, void, { rejectValue: string }>(
  'tasks/fetchAllTasks',
  async (_, { rejectWithValue, getState }) => {
    const token = selectToken(getState() as RootState);
    const res = await makeRequest.get(`/GetAllTasks`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });

    if (res.status !== 200) {
      return rejectWithValue('Не удалось получить данные');
    }
    if (res.data.statusCode === 401) {
      return rejectWithValue('needAuth');
    }

    return res.data;
  }
);

export const fetchTasks = createAsyncThunk<
  IGetTask,
  {
    stringSupervisorParam: string | null;
    stringRecipientParam: string | null;
    filterStatus: string | null;
    filterDate: string;
  },
  { rejectValue: string }
>(
  'tasks/fetchTasks',
  async (
    { stringSupervisorParam, stringRecipientParam, filterStatus, filterDate },
    { rejectWithValue, getState }
  ) => {
    const token = selectToken(getState() as RootState);
    const res = await makeRequest.get(`/GetTasks`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
      params: {
        filterSupervisor: stringSupervisorParam,
        filterRecipient: stringRecipientParam,
        filterStatus: filterStatus,
        filterDate: filterDate,
      },
    });

    if (res.status !== 200) {
      return rejectWithValue('Не удалось получить данные');
    }
    if (res.data.statusCode === 401) {
      return rejectWithValue('needAuth');
    }

    return res.data;
  }
);

export const postTasks = createAsyncThunk<
  TaskResValue,
  { data: IFormInput },
  { rejectValue: string }
>('tasks/postTasks', async ({ data }, { rejectWithValue, getState }) => {
  const token = selectToken(getState() as RootState);
  const res = await makeRequest.post(`/PostTasks`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });

  if (res.status !== 200) {
    return rejectWithValue('Не удалось отправить данные');
  }

  if (res.data.statusCode >= 400) {
    alert(res.data.value.message);
    return null;
  }

  return res.data.value;
});

export const patchTask = createAsyncThunk<
  TaskResValue,
  { data: IFormInput },
  { rejectValue: string }
>('tasks/patchTask', async ({ data }, { rejectWithValue, getState }) => {
  const token = selectToken(getState() as RootState);
  const res = await makeRequest.patch(`/PatchTasks`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });

  if (res.status !== 200) {
    return rejectWithValue('Не удалось отправить данные');
  }

  if (res.data.statusCode >= 400) {
    alert(res.data.value.message);
    return null;
  }

  return res.data.value;
});

export const patchTaskStatus = createAsyncThunk<
  TaskResStatus,
  { data: ChangeStatusData },
  { rejectValue: string }
>('tasks/patchTaskStatus', async ({ data }, { rejectWithValue, getState }) => {
  const token = selectToken(getState() as RootState);
  const res = await makeRequest.patch(`/PatchTasksStatus`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });

  if (res.status !== 200) {
    return rejectWithValue('Не удалось отправить данные');
  }

  if (res.data.statusCode >= 400) {
    alert(res.data.value.message);
    location.reload()
    return null;
  }

  return res.data;
});
