import { AddFileIconChat } from './styled';

interface AddFileIconProps {
  onClick: () => void;
}

export const AddFileIcon: React.FC<AddFileIconProps> = ({ onClick }) => {
  return (
    <AddFileIconChat onClick={() => onClick()}>
      <svg
        width='32'
        height='34'
        viewBox='0 0 32 34'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M18.5512 10.6745L8.61782 20.6078C7.90293 21.3456 7.5068 22.335 7.51491 23.3623C7.52302 24.3896 7.93473 25.3726 8.66119 26.099C9.38765 26.8255 10.3706 27.2372 11.3979 27.2453C12.4253 27.2534 13.4146 26.8573 14.1525 26.1424L27.1814 13.1314C27.8098 12.5181 28.3102 11.7861 28.6536 10.978C28.997 10.1699 29.1765 9.30161 29.1819 8.42357C29.1872 7.54552 29.0182 6.67515 28.6846 5.86291C28.3511 5.05067 27.8596 4.31272 27.2387 3.69183C26.6178 3.07095 25.8799 2.57948 25.0676 2.24592C24.2554 1.91237 23.385 1.74335 22.507 1.74868C21.6289 1.754 20.7607 1.93356 19.9525 2.27695C19.1444 2.62034 18.4125 3.12072 17.7992 3.7491L4.69145 16.8568C3.80475 17.7182 3.09814 18.7473 2.61269 19.8842C2.12725 21.0211 1.87265 22.2432 1.8637 23.4794C1.85476 24.7155 2.09165 25.9412 2.56059 27.085C3.02954 28.2288 3.72118 29.268 4.59532 30.1421C5.46946 31.0163 6.50864 31.7079 7.65246 32.1769C8.79628 32.6458 10.0219 32.8827 11.2581 32.8737C12.4943 32.8648 13.7164 32.6102 14.8533 32.1248C15.9902 31.6393 17.0192 30.9327 17.8806 30.046L30.1293 17.7973'
          stroke='#373745'
          strokeWidth='2'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
      </svg>
    </AddFileIconChat>
  );
};
