import React, { useEffect } from 'react';
import {
  ButtonRow,
  ButtonLowPriorityy,
  ButtonMiddlePriorityy,
  ButtonHighPriorityy,
} from './styled';

interface PriorityButtonsProps {
  priority: number;
  currentPriority?: number;
  setPriority: (priority: number) => void;
  disabled: boolean;
}

export const PriorityButtons: React.FC<PriorityButtonsProps> = ({
  priority,
  setPriority,
  disabled,
  currentPriority,
}) => {
  console.log(priority);

  useEffect(() => {
    if (currentPriority) setPriority(currentPriority);
  }, []);

  return (
    <ButtonRow>
      <ButtonLowPriorityy
        disabled={disabled}
        isActive={priority}
        type='button'
        onClick={() => setPriority(2)}
      >
        Низкий
      </ButtonLowPriorityy>
      <ButtonMiddlePriorityy
        disabled={disabled}
        isActive={priority}
        type='button'
        onClick={() => setPriority(1)}
      >
        Средний
      </ButtonMiddlePriorityy>
      <ButtonHighPriorityy
        disabled={disabled}
        isActive={priority}
        type='button'
        onClick={() => setPriority(0)}
      >
        Высокий
      </ButtonHighPriorityy>
    </ButtonRow>
  );
};
