import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectToken } from '../Slices/user.slice';
import { makeRequest } from './makeRequest';
import { RootState } from '../store';
import { IGetChat } from '../@types/chatTypes';

export const GetChat = createAsyncThunk<IGetChat, { id?: number | null }, { rejectValue: string }>(
  'chat/GetChat',
  async ({ id }, { rejectWithValue, getState }) => {
    const token = selectToken(getState() as RootState);
    const res = await makeRequest.get(`/GetChat`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
      params: {
        id: id,
      },
    });

    if (res.status !== 200) {
      return rejectWithValue('Не удалось получить данные');
    }

    return res.data;
  }
);
