import { useAppDispatch, useAppSelector } from '../../../Redux/ReduxHooks';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { Spinner } from '../../../Compounds/spinner/Spinner';
import {
  MessageBlock,
  ContainerForMessage,
  ChatAvatar,
  UserName,
  OtherMessage,
  SysytemMessage,
  MyMessage,
  ChatOtherAvatar,
  MyMessageBlock,
  OtherMessageBlock,
} from './styled';
import { IMessageItem } from '../../../Redux/@types/chatTypes';
import { closeChat } from '../../../Redux/Slices/chat.slice';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { hubConnection } from '../../../Redux/Socket/socket';

dayjs.extend(timezone);
dayjs.extend(utc);

dayjs.tz.setDefault('Europe/Moscow');

interface ChatContentProps {
  searchValue: string;
}

export const ChatContent: React.FC<ChatContentProps> = ({ searchValue }) => {
  const dispatch = useAppDispatch();
  const { getChatResponse, chatLoading } = useAppSelector(state => state.chatSlice);
  const { userResponse } = useAppSelector(state => state.userSlice);
  const [messages, setMessages] = useState<IMessageItem[]>([]);
  const currentDate = dayjs().format('DD.MM.YYYY');

  const sessionId = userResponse?.user_id;

  useEffect(() => {
    if (getChatResponse !== null) {
      setMessages(getChatResponse);
    }
  });

  useEffect(() => {
    hubConnection.on('ChatMessage', message => {
      setMessages([message.date, ...messages]);
    });

    return () => {
      dispatch(closeChat());
    };
  }, [messages]);

  const filtered: IMessageItem[] | undefined = messages?.filter(
    item => item.userMessage?.toLowerCase().includes(searchValue.toLowerCase())
  );

  const getSearch = () => {
    if (searchValue.length > 1) {
      return filtered;
    }
    return messages;
  };

  const messageArr = getSearch();

  return (
    <>
      {chatLoading ? (
        <Box display='flex' justifyContent='center' alignItems='center' height='450px'>
          <Spinner />
        </Box>
      ) : (
        <MessageBlock>
          {messageArr.map((message, index) => (
            <ContainerForMessage key={index}>
              {sessionId !== message.commitorId && message.systemMessage === null && (
                <Box display='flex' padding='4px' width='400px'>
                  <Box display='flex' alignItems='center'>
                    <ChatOtherAvatar>
                      {message.commitor
                        .split(' ')
                        .map(item => item.substring(0, 1))
                        .join('')
                        .slice(0, -1)}
                    </ChatOtherAvatar>
                  </Box>
                  <Box display='flex' flexDirection='column' justifyContent='center'>
                    <OtherMessageBlock>
                      <UserName>{message.commitor}</UserName>
                      <Box display='flex' justifyContent='space-between'>
                        <OtherMessage>{message.userMessage}</OtherMessage>
                        <Box width='36px' color='gray' position='absolute' bottom='4px' right='4px'>
                          {dayjs(message.date).local().format('HH:mm')}
                        </Box>
                      </Box>
                    </OtherMessageBlock>
                  </Box>
                </Box>
              )}
              {message.systemMessage !== null && (
                <SysytemMessage>{message.systemMessage}</SysytemMessage>
              )}
              {sessionId === message.commitorId && message.systemMessage === null && (
                <Box display='flex' padding='4px' width='400px' justifyContent='flex-end'>
                  <Box display='flex' flexDirection='column'>
                    <MyMessageBlock>
                      <UserName>Вы</UserName>
                      <Box display='flex' justifyContent='space-between'>
                        <MyMessage>{message.userMessage}</MyMessage>
                        <Box width='36px' color='gray' position='absolute' bottom='4px' right='4px'>
                          {dayjs(message.date).local().format('HH:mm')}
                        </Box>
                      </Box>
                    </MyMessageBlock>
                  </Box>
                  <Box display='flex' alignItems='center'>
                    <ChatAvatar>
                      {userResponse?.user_name
                        .split(' ')
                        .map(item => item.substring(0, 1))
                        .join('')
                        .slice(0, -1)}
                    </ChatAvatar>
                  </Box>
                </Box>
              )}
              <div>
                {dayjs(message.date).local().format('DD.MM.YYYY') === currentDate
                  ? 'сегодня'
                  : dayjs(message.date).local().format('DD.MM.YYYY')}
              </div>
            </ContainerForMessage>
          ))}
        </MessageBlock>
      )}
    </>
  );
};
