import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { IFormInput } from '../../../Redux/@types/formTypes';
import { IAllProject } from '../../../Redux/@types/projectTypes';
import { useAppDispatch, useAppSelector } from '../../../Redux/ReduxHooks';
import { ConfirmButton } from '../styled';
import { useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PriorityButtons } from '../../../Compounds/form/PriorityButtons';
import { patchProjects } from '../../../Redux/api/projectsApi';
import {
  isCurrentAction,
  isCurrentForm,
  slideBarIsOpen,
} from '../../../Redux/Slices/SlideBar.slice';
import { MainTitle, FakeInput, Author, DisableInput } from '../Forms/styled';

const selectarchive = ['Да', 'Нет'];

export const ViewProject = () => {
  const { allProjectResponse } = useAppSelector(state => state.projectSlice);
  const { currentViewTaskId } = useAppSelector(state => state.slideBarSlice);
  const { userResponse } = useAppSelector(state => state.userSlice);
  const { projectError } = useAppSelector(state => state.projectSlice);
  const { AllStaffResponse } = useAppSelector(state => state.staffSlice);
  const dispatch = useAppDispatch();

  const [currentStateForm, setCurrentStateForm] = useState(true);
  const [dateValue, setDateValue] = useState<Dayjs | null>(dayjs());
  const [priority, setPriority] = useState(0);
  const [archive, setArchive] = useState(' ');
  const [supervisor, setSupervisor] = useState(' ');

  const role = userResponse?.user_role;

  const foundElement = allProjectResponse?.value.projects.find(
    (item: IAllProject) => item.id === currentViewTaskId
  );

  const { register, handleSubmit, control } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = data => {
    dispatch(patchProjects({ data }));
    if (projectError) {
      alert('Ошибка редактирования');
      return;
    }
    dispatch(isCurrentAction('projectChange'));
    dispatch(isCurrentForm('success'));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MainTitle>{currentStateForm ? 'Просмотр' : 'Редактирование'}</MainTitle>
      <FakeInput
        disabled={true}
        value={currentViewTaskId !== null ? currentViewTaskId : ''}
        {...register('id', {
          required: true,
          value: currentViewTaskId,
        })}
      />
      <Author>
        Автор: <DisableInput color='neutral' disabled={true} value={foundElement?.supervisor} />
      </Author>
      <Author>
        Дата создания:{' '}
        <DisableInput color='neutral' disabled={true} value={foundElement?.dateStart} />
      </Author>
      {currentStateForm ? (
        <Box marginTop='20px'>
          <FormControl fullWidth>
            <TextField
              disabled={true}
              id='division'
              label='ГИП*'
              InputLabelProps={{
                shrink: true,
              }}
              variant='outlined'
              multiline
              value={foundElement?.supervisor}
            />
          </FormControl>
        </Box>
      ) : (
        <Box marginTop='20px'>
          <FormControl fullWidth>
            <InputLabel id='divisionId'>ГИП*</InputLabel>
            <Select
              labelId='divisionId'
              id='select-division'
              value={supervisor}
              label='ГИП*'
              onChange={e => setSupervisor(e.target.value)}
            >
              {AllStaffResponse?.value.map((item, i) => (
                <MenuItem key={i} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FakeInput
            disabled={true}
            value={supervisor.length > 1 ? supervisor : foundElement?.supervisor}
            {...register('supervisor', {
              required: true,
              value: supervisor.length > 1 ? supervisor : foundElement?.supervisor,
            })}
          />
        </Box>
      )}
      <Box marginTop='20px'>
        <FormControl fullWidth>
          <TextField
            disabled={currentStateForm}
            id='desc'
            label='Шифр Проекта'
            InputLabelProps={{
              shrink: true,
            }}
            variant='outlined'
            multiline
            defaultValue={foundElement?.code}
            {...register('code', {
              required: false,
            })}
          />
        </FormControl>
      </Box>
      <Box marginTop='20px'>
        <FormControl fullWidth>
          <TextField
            disabled={currentStateForm}
            id='desc'
            label='Описание'
            InputLabelProps={{
              shrink: true,
            }}
            variant='outlined'
            multiline
            defaultValue={foundElement?.description}
            {...register('description', {
              required: false,
            })}
          />
        </FormControl>
      </Box>
      <Box marginTop='20px'>
        <FormControl fullWidth>
          <TextField
            disabled={currentStateForm}
            id='desc'
            label='Ссылка на облако*'
            InputLabelProps={{
              shrink: true,
            }}
            variant='outlined'
            multiline
            defaultValue={foundElement?.link}
            {...register('link', {
              required: true,
            })}
          />
        </FormControl>
      </Box>
      {currentStateForm ? (
        <Box marginTop='20px'>
          <FormControl fullWidth>
            <TextField
              disabled={currentStateForm}
              id='deadline'
              label='Дата Завершения'
              InputLabelProps={{
                shrink: true,
              }}
              variant='outlined'
              multiline
              value={dayjs(foundElement?.plannedFinishDate).format('DD.MM.YYYY')}
            />
          </FormControl>
        </Box>
      ) : (
        <Box display='flex' justifyContent='cneter' alignItems='center' marginTop='20px'>
          <Controller
            control={control}
            name='plannedFinishDate'
            render={() => (
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
                <DatePicker
                  format='DD.MM.YYYY'
                  disabled={currentStateForm}
                  label='Срок сдачи проекта'
                  value={dayjs(foundElement?.plannedFinishDate)}
                  onChange={newValue => setDateValue(newValue)}
                />
              </LocalizationProvider>
            )}
          />
        </Box>
      )}
      <FakeInput
        disabled={true}
        value={dateValue?.format('YYYY.MM.DD')}
        {...register('plannedFinishDate', {
          required: true,
          value: dateValue?.format('YYYY.MM.DD'),
        })}
      />
      <Box marginTop='20px'>Приоритет</Box>
      <PriorityButtons
        disabled={currentStateForm}
        priority={priority}
        currentPriority={foundElement?.priority}
        setPriority={setPriority}
      />
      <FakeInput
        disabled={true}
        value={priority}
        {...register('priority', {
          value: priority,
        })}
      />
      {!currentStateForm && (
        <Box marginTop='20px'>
          <FormControl fullWidth>
            <InputLabel id='archiveId'>Архив</InputLabel>
            <Select
              labelId='archiveId'
              id='select-archive'
              value={archive}
              label={'Архив'}
              disabled={false}
              onChange={e => setArchive(e.target.value)}
            >
              {selectarchive.map((item, i) => (
                <MenuItem key={i} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FakeInput
            disabled={true}
            value={archive}
            {...register('archive', {
              required: false,
              value: archive,
            })}
          />
        </Box>
      )}
      {role !== 'Сотрудник' ? (
        <>
          {currentStateForm ? (
            <Box marginTop='10px' display='flex' justifyContent='center'>
              <ConfirmButton onClick={() => setCurrentStateForm(false)}>
                Редактировать
              </ConfirmButton>
            </Box>
          ) : (
            <Box marginTop='10px' display='flex' justifyContent='space-between'>
              <Box width='200px'>
                <ConfirmButton type='submit'>Сохранить</ConfirmButton>
              </Box>
              <Box width='200px'>
                <ConfirmButton onClick={() => setCurrentStateForm(true)}>Отмена</ConfirmButton>
              </Box>
            </Box>
          )}
        </>
      ) : (
        <Box marginTop='10px'>
          <ConfirmButton onClick={() => dispatch(slideBarIsOpen(false))}>Закрыть</ConfirmButton>
        </Box>
      )}
    </form>
  );
};
