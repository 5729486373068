import { Box, styled } from '@mui/material';
import { useAppSelector } from '../../../Redux/ReduxHooks';
import { ProjectTitle } from './ProjectTitle';

const Menu = styled('div')({
  minWidth: '86vw',
  marginTop: '20px',
  display: 'flex',
  backgroundColor: '#F7F7FC',
  fontWeight: '500',
  fontSize: '16px',
});

export const ProjectTableHeader = () => {
  const { projectResponse } = useAppSelector(state => state.projectSlice);
  return (
    <Menu>
      <Box
        display='flex'
        flexDirection='column'
        minWidth='180px'
        width='180px'
        height='111px'
        borderRight='1px solid #d4d3db'
      >
        <Box display='flex' flexDirection='column' alignItems='center' textAlign='center'>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='37px'
            borderBottom='1px solid #d4d3db'
            width='100%'
          >
            Дата
          </Box>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='37px'
            borderBottom='1px solid #d4d3db'
            width='100%'
          >
            Архив
          </Box>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='37px'
            borderBottom='1px solid #d4d3db'
            width='100%'
          >
            Облако
          </Box>
        </Box>
      </Box>
      {projectResponse?.projects?.map((item, i) => (
        <ProjectTitle
          key={i}
          code={item.code}
          archive={item.archive}
          cloud={item.link}
          id={item.id}
        />
      ))}
    </Menu>
  );
};
