import { Box, styled } from '@mui/material';
import { useAppDispatch } from '../../../Redux/ReduxHooks';
import {
  isCurrentForm,
  openTaskFromId,
  slideBarIsOpen,
} from '../../../Redux/Slices/SlideBar.slice';
import React from 'react';

const ProjectName = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '37px',
  borderBottom: '1px solid #d4d3db',
  width: '100%',
  cursor: 'pointer',
  transition: '0.2s',
  '&:hover': {
    background: '#D4D4FE',
  },
});

const CloudLink = styled('a')`
  &&& {
    text-decoration: underline;
  }
`;

interface ProjectTitleProps {
  code: string;
  archive: string;
  cloud: string;
  id: number;
}

export const ProjectTitle: React.FC<ProjectTitleProps> = ({ code, archive, cloud, id }) => {
  const dispatch = useAppDispatch();

  const openProject = (id: number) => {
    dispatch(openTaskFromId(id));
    dispatch(isCurrentForm('viewProject'));
    dispatch(slideBarIsOpen(true));
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      minWidth='250px'
      width='100%'
      height='111px'
      borderRight='1px solid #d4d3db'
    >
      <Box display='flex' flexDirection='column' alignItems='center' textAlign='center'>
        <ProjectName onClick={() => openProject(id)}>{code}</ProjectName>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='37px'
          borderBottom='1px solid #d4d3db'
          width='100%'
        >
          {archive}
        </Box>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='37px'
          borderBottom='1px solid #d4d3db'
          width='100%'
        >
          {cloud !== null ? (
            <CloudLink href={cloud} target='_blank'>
              {cloud.length > 30 ? cloud.slice(0, 30) + '...' : cloud}
            </CloudLink>
          ) : (
            '-'
          )}
        </Box>
      </Box>
    </Box>
  );
};
