import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectToken } from '../Slices/user.slice';
import { RootState } from '../store';
import { makeRequest } from './makeRequest';
import { StructureResponse } from '../@types/structureTyoes';

export const fetchStructure = createAsyncThunk<StructureResponse, void, { rejectValue: string }>(
  'structure/fetchStructure',
  async (_, { rejectWithValue, getState }) => {
    const token = selectToken(getState() as RootState);
    const res = await makeRequest.get(`/GetStructure`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });

    if (res.status !== 200) {
      return rejectWithValue('Не удалось получить данные');
    }
    if (res.data.statusCode === 401) {
      return rejectWithValue('needAuth');
    }

    return res.data;
  }
);
