import { combineReducers, configureStore } from '@reduxjs/toolkit';
import slideBarSlice from './Slices/SlideBar.slice';
import showTableSlice from './Slices/showTable.slice';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userSlice from './Slices/user.slice';
import taskSlice from './Slices/Tasks/task.slice';
import projectSlice from './Slices/project.slice';
import filtersSlice from './Slices/filters.slice';
import allTasksSlice from './Slices/Tasks/allTasks.slice';
import searchSlice from './Slices/search.slice';
import taskStatusSlice from './Slices/Tasks/taskStatus.slice';
import navBarSlice from './Slices/navBar.slice';
import chatSlice from './Slices/chat.slice';
import staffSlice from './Slices/staff.slice';
import structureSlice from './Slices/structure.slice';
import currentListSlice from './Slices/currentList.slice';
import notifSlice from './Slices/notif.slice';
import settingsSlice from './Slices/settings.slice';
import analyticsSlice from './Slices/analytics.slice';
import dragSlice from './Slices/drag.slice';

const whitelist = [
  'showTableSlice',
  'userSlice',
  'filtersSlice',
  'navBarSlice',
  'notifSlice',
  'settingsSlice',
  'dragSlice',
];

const persistConfig = {
  key: 'root',
  storage,
  whitelist,
};

const rootreducer = combineReducers({
  slideBarSlice,
  showTableSlice,
  userSlice,
  projectSlice,
  filtersSlice,
  allTasksSlice,
  taskSlice,
  searchSlice,
  taskStatusSlice,
  navBarSlice,
  chatSlice,
  staffSlice,
  structureSlice,
  currentListSlice,
  notifSlice,
  settingsSlice,
  analyticsSlice,
  dragSlice,
});

const persistReduce = persistReducer(persistConfig, rootreducer);

export const store = configureStore({
  reducer: persistReduce,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
