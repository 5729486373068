import { Box } from '@mui/material';
import {
  ListElem,
  ListItem,
  StructureHeadBlock,
  StructureTableInfo,
  StructureTableItem,
  StructureTableRole,
  StructureTableTakes,
} from './styled';
import { useAppSelector } from '../../Redux/ReduxHooks';

const structureTableHeader = ['Роль', 'От кого принимает задачи', 'Кому ставит задачи'];

export const CouponTable = () => {
  const { structureResponse } = useAppSelector(state => state.structureSlice);

  return (
    <Box display='flex' flexDirection='column' marginTop='30px' width='86vw'>
      <StructureHeadBlock>
        {structureTableHeader.map((item, i) => (
          <StructureTableInfo key={i}>{item}</StructureTableInfo>
        ))}
      </StructureHeadBlock>
      {structureResponse?.value.map((item, i) => (
        <StructureTableItem currentIndex={i} key={i}>
          <StructureTableRole>{item.name}</StructureTableRole>
          <StructureTableTakes>
            <ListItem>
              {item.supervisor?.map((supervisor, i) => <ListElem key={i}>{supervisor}</ListElem>)}
            </ListItem>
          </StructureTableTakes>
          <StructureTableTakes>
            <ListItem>
              {item.recipient?.map((recipient, i) => <ListElem key={i}>{recipient}</ListElem>)}
            </ListItem>
          </StructureTableTakes>
        </StructureTableItem>
      ))}
    </Box>
  );
};
