import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { Filter } from '../../Compounds/filters/Filter';
import { useAppDispatch, useAppSelector } from '../../Redux/ReduxHooks';
import { useEffect, useState } from 'react';
import { GetStatisticFilters, fetchAnalytics } from '../../Redux/api/analyticsApi';
import {
  addActiveAnalyticsFilter,
  addAnalyticsFilterJobTitle,
  addAnalyticsFilterProj,
  addAnalyticsFilterStaff,
  addAnalyticsFilterTaskFrom,
  addAnalyticsFilterTaskTo,
} from '../../Redux/Slices/filters.slice';
import { ArrowBlock, SelectBlock } from '../ProjectPage/styled';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { CloseFilter, DateFilter } from './styled';
import { handleChangeTableRow, showTableRows } from '../../Redux/Slices/showTable.slice';
import { FilterInc } from '../../Compounds/filters/FilterInc';

export const AnalyticFilters = () => {
  const { analyticsFilterProj, analyticsFilterStaff, analyticsFilterJobTitle, activeAnalytics } =
    useAppSelector(state => state.filtersSlice);
  const { analyticsFIlterRes } = useAppSelector(state => state.analyticsSlice);
  const { analyticCurrent, analyticCompleted } = useAppSelector(state => state.showTableSlice);
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [fromDateValue, setFromDateValue] = useState<Dayjs | null>(dayjs());
  const [toDateValue, setToDateValue] = useState<Dayjs | null>(dayjs());

  const FilterProj =
    analyticsFilterProj.length <= 1 ? analyticsFilterProj[0] : analyticsFilterProj.join(',');
  const FilterStaff =
    analyticsFilterStaff.length <= 1 ? analyticsFilterStaff[0] : analyticsFilterStaff.join(',');
  const FilterJobTitle =
    analyticsFilterJobTitle.length <= 1
      ? analyticsFilterJobTitle[0]
      : analyticsFilterJobTitle.join(',');

  useEffect(() => {
    dispatch(GetStatisticFilters());
    dispatch(
      fetchAnalytics({
        filterProj: FilterProj,
        filterStaff: FilterStaff,
        filterJobTitle: FilterJobTitle,
        filterTaskFrom: dayjs(fromDateValue).format('DD.MM.YYYY'),
        filterTaskTo: dayjs(toDateValue).format('DD.MM.YYYY'),
        filterArchiveProj: activeAnalytics,
      })
    );
  }, [FilterProj, FilterStaff, FilterJobTitle, fromDateValue, toDateValue, activeAnalytics]);

  useEffect(() => {
    dispatch(addAnalyticsFilterTaskFrom(dayjs(fromDateValue).format('DD.MM.YYYY')));
  }, [fromDateValue]);

  useEffect(() => {
    dispatch(addAnalyticsFilterTaskTo(dayjs(toDateValue).format('DD.MM.YYYY')));
  }, [toDateValue]);

  const handleProjectChange = (option: string) => {
    dispatch(addAnalyticsFilterProj(option));
  };

  const handleStaffChange = (option: string) => {
    dispatch(addAnalyticsFilterStaff(option));
  };

  const handleJobTitleChange = (option: string) => {
    dispatch(addAnalyticsFilterJobTitle(option));
  };

  const handleShowing = (fieldName: keyof showTableRows, value: boolean) => {
    dispatch(handleChangeTableRow({ fieldName: fieldName, value: !value }));
  };

  const handleActiveChange = (option: string | null) => {
    dispatch(addActiveAnalyticsFilter(option));
  };

  return (
    <Box display='flex'>
      <Filter
        label={analyticsFilterProj.length > 0 ? 'Несколько проектов' : 'Все проекты'}
        handleChange={handleProjectChange}
        options={analyticsFIlterRes?.value.filterProj}
        selectedOptions={analyticsFilterProj}
        width={'220px'}
        left={'160px'}
      />
      <Filter
        label={analyticsFilterStaff.length > 0 ? 'Несколько cотрудников' : 'Все сотрудники'}
        handleChange={handleStaffChange}
        options={analyticsFIlterRes?.value.filterStaff}
        selectedOptions={analyticsFilterStaff}
        width={'350px'}
        left={'332px'}
      />
      <Filter
        label={analyticsFilterJobTitle.length > 0 ? 'Несколько должностей' : 'Все должности'}
        handleChange={handleJobTitleChange}
        options={analyticsFIlterRes?.value.filterJobTitle}
        selectedOptions={analyticsFilterJobTitle}
        width={'390px'}
        left={'526px'}
      />
      <>
        <SelectBlock onClick={() => setIsOpen(!isOpen)}>
          {'Задачи'}
          <ArrowBlock isOpen={isOpen}>
            <KeyboardArrowDownIcon />
          </ArrowBlock>
        </SelectBlock>
        {isOpen && (
          <>
            <CloseFilter onClick={() => setIsOpen(!isOpen)}>Закрыть фильтр</CloseFilter>
            <DateFilter width={'400px'} left={'710px'}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={analyticCurrent}
                    onChange={() => handleShowing('analyticCurrent', analyticCurrent)}
                  />
                }
                label={'Текущие'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={analyticCompleted}
                    onChange={() => handleShowing('analyticCompleted', analyticCompleted)}
                  />
                }
                label={'Выполненные'}
              />
              <Box marginLeft='30px'>За период :</Box>
              <Box marginTop='10px' display='flex'>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
                  <DatePicker
                    format='DD.MM.YYYY'
                    label='От'
                    value={fromDateValue}
                    onChange={newValue => setFromDateValue(newValue)}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
                  <DatePicker
                    format='DD.MM.YYYY'
                    label='По'
                    value={toDateValue}
                    onChange={newValue => setToDateValue(newValue)}
                  />
                </LocalizationProvider>
              </Box>
            </DateFilter>
          </>
        )}
      </>
      <FilterInc
        label={activeAnalytics !== null ? `${activeAnalytics}` : 'Все проекты'}
        handleChange={handleActiveChange}
        options={analyticsFIlterRes?.value.filterArchiveProj}
        selectedOptions={activeAnalytics}
        width={'200px'}
        left={'866px'}
      />
    </Box>
  );
};
