import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../Redux/ReduxHooks';
import { handleChangeTableRow, showTableRows } from '../../Redux/Slices/showTable.slice';
import { MainTitle } from './Forms/styled';

export const TableConfig = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(state => state.showTableSlice);

  const handleShowing = (fieldName: keyof showTableRows, value: boolean) => {
    dispatch(handleChangeTableRow({ fieldName: fieldName, value: !value }));
  };

  return (
    <div>
      <MainTitle>Настройка столбцов</MainTitle>
      <FormGroup>
        <Box marginTop='6px'>
          <FormControlLabel
            control={
              <Checkbox
                color='default'
                checked={data.dateRow}
                onChange={() => handleShowing('dateRow', data.dateRow)}
              />
            }
            label='Дата'
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                color='default'
                checked={data.chatRow}
                onChange={() => handleShowing('chatRow', data.chatRow)}
              />
            }
            label='Чат'
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                color='default'
                checked={data.projectCodeRow}
                onChange={() => handleShowing('projectCodeRow', data.projectCodeRow)}
              />
            }
            label='Шифр Проекта'
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                color='default'
                checked={data.taskRow}
                onChange={() => handleShowing('taskRow', data.taskRow)}
              />
            }
            label='Задача'
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                color='default'
                checked={data.statusRow}
                onChange={() => handleShowing('statusRow', data.statusRow)}
              />
            }
            label='Статус'
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                color='default'
                checked={data.respomsobleRow}
                onChange={() => handleShowing('respomsobleRow', data.respomsobleRow)}
              />
            }
            label='Контроль'
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                color='default'
                checked={data.reassignRow}
                onChange={() => handleShowing('reassignRow', data.reassignRow)}
              />
            }
            label='Исполнитель'
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                color='default'
                checked={data.priorityRow}
                onChange={() => handleShowing('priorityRow', data.priorityRow)}
              />
            }
            label='Приоритет'
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                color='default'
                checked={data.dedlineRow}
                onChange={() => handleShowing('dedlineRow', data.dedlineRow)}
              />
            }
            label='Дедлайн'
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                color='default'
                checked={data.planTimeRow}
                onChange={() => handleShowing('planTimeRow', data.planTimeRow)}
              />
            }
            label='План. время'
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                color='default'
                checked={data.factTimeRow}
                onChange={() => handleShowing('factTimeRow', data.factTimeRow)}
              />
            }
            label='Факт. время'
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                color='default'
                checked={data.beganRow}
                onChange={() => handleShowing('beganRow', data.beganRow)}
              />
            }
            label='Начал'
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                color='default'
                checked={data.completedRow}
                onChange={() => handleShowing('completedRow', data.completedRow)}
              />
            }
            label='Завершил'
          />
        </Box>
      </FormGroup>
    </div>
  );
};
