import { keyframes, styled } from '@mui/material';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const FilterItem = styled('div')<{ width: string; left: string }>(({ width, left }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '4px',
  position: 'absolute',
  left: left,
  top: '106px',
  marginTop: '20px',
  border: '1px solid #f2f2f2',
  borderRadius: '4px',
  maxHeight: '300px',
  width: width,
  background: 'white',
  zIndex: '1',
  overflowY: 'auto',
  boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)',
  opacity: '0',
  animationName: `${fadeIn}`,
  animationDuration: '0.2s',
  animationFillMode: 'forwards',
}));
