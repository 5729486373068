import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../Redux/ReduxHooks';
import {
  addRecipientTaskFilter,
  addStatusTaskFilter,
  addSupervisorTaskFilter,
} from '../../../Redux/Slices/filters.slice';
import { FilterInc } from '../../../Compounds/filters/FilterInc';
import { Filter } from '../../../Compounds/filters/Filter';
import { currentTask } from '../Table/CurrentStatusTask';

export const FilterTasks = () => {
  const dispatch = useAppDispatch();
  const { supervisorTaskFilter, recipientTaskFilter, statusTaskFilter } = useAppSelector(
    state => state.filtersSlice
  );
  const { userResponse } = useAppSelector(state => state.userSlice);

  const userName = userResponse?.user_name;
  const supervisorFiltersArray = [userName ? userName : '', 'Другие'];

  const handleSupervisorChange = (option: string | null) => {
    dispatch(addSupervisorTaskFilter(option));
  };

  const handleRecipientChange = (option: string | null) => {
    dispatch(addRecipientTaskFilter(option));
  };

  const handleStatusChange = (option: string) => {
    dispatch(addStatusTaskFilter(option));
  };

  return (
    <Box display='flex'>
      <FilterInc
        label={supervisorTaskFilter !== null ? `${supervisorTaskFilter}` : 'Все ответственные'}
        handleChange={handleSupervisorChange}
        options={supervisorFiltersArray}
        selectedOptions={supervisorTaskFilter}
        width={'350px'}
        left={'160px'}
      />
      <FilterInc
        label={recipientTaskFilter !== null ? `${recipientTaskFilter}` : 'Все исполнители'}
        handleChange={handleRecipientChange}
        options={supervisorFiltersArray}
        selectedOptions={recipientTaskFilter}
        width={'350px'}
        left={'378px'}
      />
      <Filter
        label={
          statusTaskFilter.length === 1
            ? statusTaskFilter[0]
            : statusTaskFilter.length === 0
            ? 'Статус'
            : 'Выбрано несколько статусов'
        }
        handleChange={handleStatusChange}
        options={[...currentTask, 'Создана']}
        selectedOptions={statusTaskFilter}
        width={'200px'}
        left={'582px'}
      />
    </Box>
  );
};
