import { ChatButton } from './styled';

interface SendMessageButtonProps {
  onClick: () => void;
}

export const SendMessageButton: React.FC<SendMessageButtonProps> = ({ onClick }) => {
  return (
    <ChatButton onClick={() => onClick()}>
      <svg
        width='33'
        height='30'
        viewBox='0 0 33 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M5.95318 15.2132L31.4263 15.2132M5.95318 15.2132L1.90063 27.3708C1.84058 27.5325 1.8282 27.708 1.86496 27.8765C1.90173 28.045 1.9861 28.1994 2.10804 28.3213C2.22998 28.4433 2.38437 28.5276 2.55286 28.5644C2.72134 28.6012 2.89684 28.5888 3.0585 28.5287L31.4263 15.2132M5.95318 15.2132L1.90063 3.05556C1.84058 2.8939 1.8282 2.7184 1.86496 2.54991C1.90173 2.38143 1.9861 2.22704 2.10804 2.1051C2.22998 1.98315 2.38437 1.89879 2.55286 1.86202C2.72134 1.82525 2.89684 1.83763 3.0585 1.89769L31.4263 15.2132'
          stroke='#373745'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </ChatButton>
  );
};
