import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../Redux/ReduxHooks';
import { openTaskFromId, isCurrentForm, slideBarIsOpen } from '../../Redux/Slices/SlideBar.slice';
import { GetSearch } from '../../Redux/api/searchApi';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import dayjs from 'dayjs';
import { SearchInputBlock, SearchIcon, SearchInput, SearchValueBlock, SearchItem } from './styled';

export const Search = () => {
  const { searchResponse } = useAppSelector(state => state.searchSlice);
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [dropDown, setDropDown] = useState(false);
  const filtered = searchResponse?.value;
  const { refElement } = useOutsideClick(() => setDropDown(false));

  useEffect(() => {
    if (searchValue.length < 3) {
      return;
    }
    dispatch(GetSearch({ searchValue }));
  }, [dispatch, searchValue]);

  useEffect(() => {
    if (!filtered) return;
    setDropDown(searchValue.length > 2 && filtered?.length > 0);
  }, [searchValue, filtered]);

  const viewCurrentTask = (id: number) => {
    setDropDown(false);
    dispatch(openTaskFromId(id));
    dispatch(isCurrentForm('viewTask'));
    dispatch(slideBarIsOpen(true));
  };

  return (
    <>
      <SearchInputBlock>
        <SearchIcon>
          <svg
            width='24'
            height='25'
            viewBox='0 0 24 25'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M15.5 14.48H14.71L14.43 14.21C15.63 12.81 16.25 10.9 15.91 8.87001C15.44 6.09001 13.12 3.87001 10.32 3.53001C6.09002 3.01001 2.53002 6.57001 3.05002 10.8C3.39002 13.6 5.61002 15.92 8.39002 16.39C10.42 16.73 12.33 16.11 13.73 14.91L14 15.19V15.98L18.25 20.23C18.66 20.64 19.33 20.64 19.74 20.23C20.15 19.82 20.15 19.15 19.74 18.74L15.5 14.48ZM9.50002 14.48C7.01002 14.48 5.00002 12.47 5.00002 9.98001C5.00002 7.49001 7.01002 5.48001 9.50002 5.48001C11.99 5.48001 14 7.49001 14 9.98001C14 12.47 11.99 14.48 9.50002 14.48Z'
              fill='#9A9AB0'
            />
          </svg>
        </SearchIcon>
        <SearchInput
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder='Поиск ...'
        ></SearchInput>
      </SearchInputBlock>
      {dropDown && (
        <SearchValueBlock ref={refElement}>
          {filtered?.map((item, i) => (
            <SearchItem key={i} onClick={() => viewCurrentTask(item.id)}>{`${
              item.desc?.length > 80 ? item.desc.slice(0, 80) + ' ...' : item.desc
            } ${item.projectCode} ${dayjs(item.date).format('DD.MM.YYYY')}`}</SearchItem>
          ))}
        </SearchValueBlock>
      )}
    </>
  );
};
