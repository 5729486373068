import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../Redux/ReduxHooks';
import { fetchStructure } from '../../Redux/api/StructureApi';
import { Box } from '@mui/material';
import { PageTitle } from '../ProjectPage/styled';
import { Spinner } from '../../Compounds/spinner/Spinner';
import { CouponTable } from './CouponTable';
import { LayoutStructure } from './styled';

export const Coupon = () => {
  const dispatch = useAppDispatch();
  const { structureLoading } = useAppSelector(state => state.structureSlice);

  useEffect(() => {
    dispatch(fetchStructure());
  }, []);

  return (
    <LayoutStructure>
      <Box display='flex' alignItems='center' marginTop='4px'>
        <PageTitle>Справка</PageTitle>
      </Box>
      {structureLoading ? (
        <Box height='50vh' width='86vw' display='flex' justifyContent='center' alignItems='center'>
          <Spinner />
        </Box>
      ) : (
        <CouponTable />
      )}
    </LayoutStructure>
  );
};
