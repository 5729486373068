import React from 'react';
import { useAppDispatch } from '../../Redux/ReduxHooks';
import { isCurrentForm, slideBarIsOpen } from '../../Redux/Slices/SlideBar.slice';
import { TaskButtons, ButtonCreate } from './styled';

interface CreateTaskButtonProps {
  currentTaskCreate: string;
  disabled?: boolean;
}

export const CreateTaskButton: React.FC<CreateTaskButtonProps> = ({
  currentTaskCreate,
  disabled,
}) => {
  const dispatch = useAppDispatch();

  const openSideBarTask = () => {
    dispatch(slideBarIsOpen(true));
    dispatch(isCurrentForm(currentTaskCreate));
  };

  return (
    <TaskButtons>
      <ButtonCreate disabled={disabled} onClick={openSideBarTask}>
        Создать
      </ButtonCreate>
    </TaskButtons>
  );
};
