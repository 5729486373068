import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../Redux/ReduxHooks';
import { isCurrentForm, openStaffById, slideBarIsOpen } from '../../Redux/Slices/SlideBar.slice';
import { StaffItem } from './styled';
import { sortRows } from '../../helpers/dragHelpers';
import { rowItem, setCurrentRow, setRowList } from '../../Redux/Slices/drag.slice';

interface StaffTableItemProps {
  index: number;
  id: number;
  name: string;
  role: string;
  division: string;
  post: string;
}

export const StaffTableItem: React.FC<StaffTableItemProps> = ({
  id,
  index,
  name,
  role,
  division,
  post,
}) => {
  const { rowList, currentRow } = useAppSelector(state => state.dragSlice);
  const dispatch = useAppDispatch();

  const handleDragStart = (card: rowItem) => {
    dispatch(setCurrentRow(card));
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragDrop = (e: React.DragEvent<HTMLDivElement>, card: rowItem) => {
    e.preventDefault();
    const sortArr = rowList.map(item => {
      if (item.id === card.id) {
        return { ...item, order: currentRow?.order };
      }
      if (item.id === currentRow?.id) {
        return { ...item, order: card.order };
      }
      return item;
    });
    dispatch(setRowList(sortArr));
  };

  const viewCurrentStaff = (id: number) => {
    dispatch(openStaffById(id));
    dispatch(isCurrentForm('viewStaff'));
    dispatch(slideBarIsOpen(true));
  };

  const staffItem = rowList.map(item => ({
    ...item,
    name: name,
    role: role,
    division: division,
    post: post,
  }));

  return (
    <StaffItem currentIndex={index} onClick={() => viewCurrentStaff(id)}>
      {staffItem.sort(sortRows).map((card, i) => (
        <Box
          borderLeft='1px solid #d4d3db'
          display='flex'
          justifyContent='center'
          alignItems='center'
          width='100%'
          minWidth='300px'
          height='60px'
          padding='6px'
          textAlign='center'
          onDragStart={() => handleDragStart(card)}
          onDragOver={e => handleDragOver(e)}
          onDrop={e => handleDragDrop(e, card)}
          key={card.id}
          draggable={true}
        >
          {card.text === 'ФИО' && card.name}
          {card.text === 'Отдел' && card.division}
          {card.text === 'Должность' && card.post}
          {card.text === 'Роль' && card.role}
        </Box>
      ))}
    </StaffItem>
  );
};
