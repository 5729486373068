import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { ISettingsType } from '../../Redux/@types/settingsType';

interface CheckBoxBlockProps {
  onChange: (fieldName: keyof ISettingsType, value: boolean) => void;
  inApp: boolean;
  onMail: boolean;
  stateInApp: keyof ISettingsType;
  stateOnMail: keyof ISettingsType;
}

export const CheckBoxBlock: React.FC<CheckBoxBlockProps> = ({
  onChange,
  inApp,
  onMail,
  stateInApp,
  stateOnMail,
}) => {
  return (
    <>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        borderLeft='1px solid #d4d3db'
        minWidth='100px'
        width='100%'
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={onMail}
              onChange={() => onChange(stateOnMail, onMail)}
              color='default'
            />
          }
          label=''
        />
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        borderLeft='1px solid #d4d3db'
        minWidth='100px'
        width='100%'
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={inApp}
              onChange={() => onChange(stateInApp, inApp)}
              color='default'
            />
          }
          label=''
        />
      </Box>
    </>
  );
};
