import { Box } from '@mui/material';
import { useEffect } from 'react';
import { PageTitle } from '../ProjectPage/styled';
import { CreateTaskButton } from '../../Compounds/buttons/CreateTaskButton';
import { StaffTable } from './StaffTable';
import { FilterGroup } from './FIlterGroup/FilterGroup';
import { useAppDispatch, useAppSelector } from '../../Redux/ReduxHooks';
import { fetchAllStaffs, fetchEmployees, fetchFilterStaffs } from '../../Redux/api/staffApi';
import { StaffSearch } from './StaffSearch';
import { refreshTokenPost } from '../../Redux/api/userApi';

export const Staffs = () => {
  const { userResponse } = useAppSelector(state => state.userSlice);
  const dispatch = useAppDispatch();

  const isAdmin = userResponse?.admin;

  useEffect(() => {
    dispatch(refreshTokenPost());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchEmployees());
    dispatch(
      fetchAllStaffs({
        stringDepartmentParam: null,
        stringJobTitleParam: null,
        stringRoleParam: null,
        stringActiveParam: null,
      })
    );
    dispatch(fetchFilterStaffs());
  }, [dispatch]);

  return (
    <Box padding='30px' height='90vh' display='flex' flexDirection='column' overflow='hidden'>
      <Box display='flex' alignItems='center'>
        <PageTitle>Сотрудники</PageTitle>
        <CreateTaskButton disabled={!isAdmin} currentTaskCreate={'staff'} />
        <StaffSearch />
      </Box>
      <FilterGroup />
      <StaffTable />
    </Box>
  );
};
