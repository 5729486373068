import { styled } from '@mui/material';

export const SearchBlock = styled('div')({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  marginLeft: '30px',
});

export const SearchButtons = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '40px',
});

export const TableIcon = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '36px',
  width: '36px',
  borderRadius: '4px',
  cursor: 'pointer',
  transition: '0.2s',
  '&:hover': {
    background: '#F8F8F8',
  },
});

export const SearchStaffsBlock = styled('div')({
  borderRadius: '4px',
  position: 'absolute',
  maxHeight: '190px',
  width: '500px',
  background: 'white',
  overflowY: 'scroll',
  left: '500px',
  top: '70px',
  boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.4)',
});
