import { Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useEffect, useRef, useState } from 'react';
import { CurrentStatusTask } from './CurrentStatusTask';
import { useAppDispatch, useAppSelector } from '../../../Redux/ReduxHooks';
import dayjs from 'dayjs';
import {
  openTaskFromId,
  isCurrentForm,
  slideBarIsOpen,
  openChatById,
} from '../../../Redux/Slices/SlideBar.slice';
import { ChatButton, CurrentStatus, Status, TodoInfo } from './styled';
import { ITaskItem } from '../../../Redux/@types/taskItem';
import { GetChat } from '../../../Redux/api/chatApi';

interface TaskItemProps extends ITaskItem {
  index: number;
}

export const TaskItem: React.FC<TaskItemProps> = ({
  date,
  projectCode,
  priority,
  desc,
  start,
  supervisor,
  plannedTime,
  actualTime,
  finish,
  status,
  index,
  dedline,
  id,
  recipient,
}) => {
  const {
    dateRow,
    dedlineRow,
    chatRow,
    projectCodeRow,
    taskRow,
    statusRow,
    respomsobleRow,
    reassignRow,
    priorityRow,
    planTimeRow,
    factTimeRow,
    beganRow,
    completedRow,
  } = useAppSelector(state => state.showTableSlice);
  const dispatch = useAppDispatch();
  const statusRef = useRef<HTMLDivElement>(null);

  const [taskModalIsOpen, setTaskModalIsOpen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(status);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (statusRef.current && !statusRef.current.contains(event.target as Node)) {
        setTaskModalIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const viewCurrentTask = (id: number) => {
    dispatch(openTaskFromId(id));
    dispatch(isCurrentForm('viewTask'));
    dispatch(slideBarIsOpen(true));
  };

  const handleNestedClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setTaskModalIsOpen(!taskModalIsOpen);
  };

  const showChat = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    dispatch(openChatById(id));
    dispatch(isCurrentForm('chat'));
    dispatch(slideBarIsOpen(true));
    dispatch(GetChat({ id }));
  };

  const arrow = {
    transform: 'rotate(270deg)',
  };

  return (
    <TodoInfo onClick={() => viewCurrentTask(id)} currentIndex={index} isActive={currentStatus}>
      {dateRow && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='90px'
          minWidth='58px'
          width='100%'
          fontSize='14px'
          textAlign='center'
          borderRight='1px solid #d4d3db'
          padding='2px'
        >
          {date ? dayjs(date.replace(' ', '')).format('DD.MM.YYYY') : '-'}
        </Box>
      )}
      {projectCodeRow && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='90px'
          minWidth='56px'
          width='100%'
          fontSize='14px'
          textAlign='center'
          borderRight='1px solid #d4d3db'
          padding='2px'
        >
          {projectCode ? projectCode : '-'}
        </Box>
      )}
      {taskRow && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='90px'
          minWidth='150px'
          width='100%'
          fontSize='14px'
          textAlign='center'
          borderRight='1px solid #d4d3db'
          padding='2px'
        >
          {desc ? <div>{desc?.length > 60 ? desc.slice(0, 60) + ' ...' : desc}</div> : '-'}
        </Box>
      )}
      {chatRow && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='90px'
          minWidth='28px'
          width='100%'
          fontSize='14px'
          textAlign='center'
          borderRight='1px solid #d4d3db'
          padding='2px'
        >
          <ChatButton index={index} onClick={e => showChat(e)}>
            Чат
          </ChatButton>
        </Box>
      )}
      {statusRow && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='90px'
          minWidth='130px'
          width='100%'
          fontSize='14px'
          borderRight='1px solid #d4d3db'
          padding='2px'
        >
          {status === 'Выполнена' ? (
            <span>Выполнена</span>
          ) : (
            <Status onClick={e => handleNestedClick(e)} isActive={currentStatus}>
              {currentStatus}
              <div style={arrow}>
                <KeyboardArrowDownIcon />
              </div>
              {taskModalIsOpen && (
                <CurrentStatus>
                  <div ref={statusRef}>
                    <CurrentStatusTask
                      userName={supervisor}
                      id={id}
                      setCurrentStatus={setCurrentStatus}
                      recipient={recipient}
                      currentStatus={currentStatus}
                    />
                  </div>
                </CurrentStatus>
              )}
            </Status>
          )}
        </Box>
      )}
      {respomsobleRow && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='90px'
          minWidth='120px'
          width='100%'
          fontSize='14px'
          textAlign='center'
          borderRight='1px solid #d4d3db'
          padding='2px'
        >
          {supervisor ? supervisor : '-'}
        </Box>
      )}
      {reassignRow && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='90px'
          minWidth='110px'
          width='100%'
          fontSize='14px'
          textAlign='center'
          borderRight='1px solid #d4d3db'
          padding='2px'
        >
          {recipient ? recipient : 'Не выбран'}
        </Box>
      )}
      {priorityRow && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='90px'
          minWidth='80px'
          width='100%'
          fontSize='14px'
          textAlign='center'
          borderRight='1px solid #d4d3db'
          padding='2px'
        >
          {priority === 0 && 'Высокий'}
          {priority === 1 && 'Средний'}
          {priority === 2 && 'Низкий'}
        </Box>
      )}
      {dedlineRow && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='90px'
          minWidth='60px'
          width='100%'
          fontSize='14px'
          textAlign='center'
          borderRight='1px solid #d4d3db'
          padding='2px'
        >
          {dedline ? dayjs(dedline).format('DD.MM.YYYY HH:mm') : '-'}
        </Box>
      )}
      {planTimeRow && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='90px'
          minWidth='60px'
          width='100%'
          fontSize='14px'
          textAlign='center'
          borderRight='1px solid #d4d3db'
          padding='2px'
        >
          {plannedTime ? plannedTime : '-'}
        </Box>
      )}
      {factTimeRow && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='90px'
          minWidth='64px'
          width='100%'
          fontSize='14px'
          textAlign='center'
          borderRight='1px solid #d4d3db'
          padding='2px'
        >
          {actualTime ? actualTime : '-'}
        </Box>
      )}
      {beganRow && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='90px'
          minWidth='74px'
          width='100%'
          fontSize='14px'
          textAlign='center'
          borderRight='1px solid #d4d3db'
          padding='2px'
        >
          {start ? dayjs(start).format('DD.MM.YYYY HH:mm') : '-'}
        </Box>
      )}
      {completedRow && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='90px'
          fontSize='14px'
          minWidth='84px'
          width='100%'
          textAlign='center'
          padding='2px'
        >
          {finish === null ? <span>-</span> : dayjs(finish).format('DD.MM.YYYY HH:mm')}
        </Box>
      )}
    </TodoInfo>
  );
};
