import { AnyAction, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TaskResValue } from '../@types/tasksType';
import { fetchAllProjects, fetchProjects, patchProjects, postProject } from '../api/projectsApi';
import { IGetAllProjects, Value } from '../@types/projectTypes';

interface ProjectState {
  allProjectResponse: IGetAllProjects | null;
  projectCreateResponse: TaskResValue | null;
  projectResponse: Value | null;
  projectLoading: boolean;
  myProjectLoading: boolean;
  projectError?: string;
}

const initialState: ProjectState = {
  allProjectResponse: null,
  projectCreateResponse: null,
  projectResponse: null,
  projectLoading: false,
  myProjectLoading: false,
  projectError: undefined,
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchProjects.pending, state => {
        state.myProjectLoading = true;
        state.projectError = undefined;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.projectResponse = action.payload.value;
        state.myProjectLoading = false;
      })
      .addCase(postProject.pending, state => {
        state.projectLoading = true;
        state.projectError = undefined;
      })
      .addCase(postProject.fulfilled, (state, action) => {
        state.projectCreateResponse = action.payload;
        state.projectLoading = false;
      })
      .addCase(fetchAllProjects.pending, state => {
        state.projectLoading = true;
        state.projectError = undefined;
      })
      .addCase(fetchAllProjects.fulfilled, (state, action) => {
        state.allProjectResponse = action.payload;
        state.projectLoading = false;
      })
      .addCase(patchProjects.pending, state => {
        state.projectLoading = true;
        state.projectError = undefined;
      })
      .addCase(patchProjects.fulfilled, (state, action) => {
        state.projectCreateResponse = action.payload;
        state.projectLoading = false;
      })
      .addMatcher(isError, (state, action: PayloadAction<string>) => {
        state.projectError = action.payload;
        state.projectLoading = false;
      });
  },
});

export default projectSlice.reducer;

function isError(action: AnyAction) {
  return action.type.endsWith('rejected');
}
