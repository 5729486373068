import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface showTableRows {
  dateRow: boolean;
  projectCodeRow: boolean;
  taskRow: boolean;
  statusRow: boolean;
  respomsobleRow: boolean;
  reassignRow: boolean;
  priorityRow: boolean;
  planTimeRow: boolean;
  factTimeRow: boolean;
  beganRow: boolean;
  completedRow: boolean;
  dedlineRow: boolean;
  chatRow: boolean;
  analyticCurrent: boolean;
  analyticCompleted: boolean;
}

interface UpdateStateAction {
  type: string;
  payload: {
    fieldName: keyof showTableRows;
    value: boolean;
  };
}

const initialState: showTableRows = {
  dateRow: true,
  projectCodeRow: true,
  taskRow: true,
  statusRow: true,
  respomsobleRow: true,
  reassignRow: true,
  priorityRow: true,
  planTimeRow: true,
  factTimeRow: false,
  beganRow: true,
  completedRow: true,
  dedlineRow: true,
  chatRow: true,
  analyticCurrent: true,
  analyticCompleted: true,
};

export const showTableSlice = createSlice({
  name: 'rows',
  initialState,
  reducers: {
    handleChangeTableRow(state, action: PayloadAction<UpdateStateAction['payload']>) {
      const { fieldName, value } = action.payload;
      state[fieldName] = value;
    },
  },
});

export const { handleChangeTableRow } = showTableSlice.actions;
export default showTableSlice.reducer;
