import { Box, Typography, FormControlLabel, Checkbox, Button, Input, styled } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../Redux/ReduxHooks';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ErrorInForm } from '../../Compounds/form/ErrorInForm';
import { useState } from 'react';
import { login } from '../../Redux/api/userApi';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { CashButton, PasswordButton } from './styled';

export interface ILoginForm {
  UserName: string;
  Password: string;
}

export const SignIn = () => {
  const { userError } = useAppSelector(state => state.userSlice);
  const dispatch = useAppDispatch();

  const [seePassword, setSeePassword] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginForm>();

  const onSubmit: SubmitHandler<ILoginForm> = data => {
    dispatch(login(data));
  };

  return (
    <Box height='80vh' width='100vw' display='flex' justifyContent='center' alignItems='center'>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Typography component='h1' variant='h5'>
          Войти в систему
        </Typography>
        <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
          <Box marginTop='10px'>
            <Input
              disabled={false}
              autoComplete=''
              fullWidth
              placeholder='Логин'
              {...register('UserName', {
                required: true,
                minLength: 3,
              })}
            />
            {(userError || errors.UserName) && (
              <ErrorInForm value={'Неправильный логин или пароль'} />
            )}
          </Box>
          <Box marginTop='30px'>
            <Box display='flex'>
              <Input
                type={seePassword ? 'password' : 'text'}
                autoComplete=''
                disabled={false}
                fullWidth
                placeholder='Пароль'
                {...register('Password', {
                  required: true,
                  minLength: 3,
                })}
              />
              <PasswordButton onClick={() => setSeePassword(!seePassword)}>
                <RemoveRedEyeIcon />
              </PasswordButton>
            </Box>
            {(userError || errors.Password) && (
              <ErrorInForm value={'Неправильный логин или пароль'} />
            )}
          </Box>
          <Box marginTop='10px' width='350px'>
            <FormControlLabel
              control={<Checkbox value='remember' color='primary' />}
              label='Запомнить меня'
            />
          </Box>
          <Button fullWidth variant='contained' sx={{ mt: 2, mb: 2 }} type='submit'>
            Войти в систему
          </Button>

          <Box display='flex' justifyContent='center' alignItems='center' marginTop='20px'>
            <CashButton onClick={() => localStorage.clear()}>Очистить кэш</CashButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
