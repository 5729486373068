import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { selectToken } from '../Slices/user.slice';
import { makeRequest } from './makeRequest';
import { INotifItem } from '../@types/notifTypes';

export const GetOldNotif = createAsyncThunk<INotifItem[], void, { rejectValue: string }>(
  'notif/GetOldNotif',
  async (_, { getState }) => {
    const token = selectToken(getState() as RootState);
    const res = await makeRequest.get(`/GetOldNotif`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });

    return res.data.value;
  }
);

export const GetNewNotif = createAsyncThunk<INotifItem[], void, { rejectValue: string }>(
  'notif/GetNewNotif',
  async (_, { rejectWithValue, getState }) => {
    const token = selectToken(getState() as RootState);
    const res = await makeRequest.get(`/GetNewNotif`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });

    if (res.status !== 200) {
      return rejectWithValue('Не удалось получить данные');
    }
    if (res.data.statusCode === 401) {
      return rejectWithValue('needAuth');
    }

    return res.data.value;
  }
);

export const TetsNotif = createAsyncThunk<unknown, void, { rejectValue: string }>(
  'notif/TetsNotif',
  async (_, { getState }) => {
    const token = selectToken(getState() as RootState);
    try {
      const response = await makeRequest.post(
        '/TetsNotif',
        {},
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const PatchSeeNotifications = createAsyncThunk<unknown, void, { rejectValue: string }>(
  'notif/PatchSeeNotifications',
  async (_, { getState }) => {
    const token = selectToken(getState() as RootState);
    try {
      const response = await makeRequest.patch(
        '/PatchSeeNotifications',
        {},
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
