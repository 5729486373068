import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface FiltersState {
  tasksFilter: string[];
  postsFilter: string[];
  staffsFilter: string[];
  gipFilter: string[];
  projFilter: string | null;
  recipientFilter: string[];
  departmentFilter: string[];
  jobTitleFilter: string[];
  roleFilter: string[];
  activeFilter: string | null;
  activeAnalytics: string | null;
  supervisorTaskFilter: string | null;
  recipientTaskFilter: string | null;
  statusTaskFilter: string[];
  analyticsFilterProj: string[];
  analyticsFilterStaff: string[];
  analyticsFilterJobTitle: string[];
  analyticsFilterTaskFrom: string | null;
  analyticsFilterTaskTo: string | null;
}

const initialState: FiltersState = {
  tasksFilter: [],
  postsFilter: [],
  staffsFilter: [],
  gipFilter: [],
  projFilter: null,
  recipientFilter: [],
  departmentFilter: [],
  jobTitleFilter: [],
  roleFilter: [],
  activeFilter: null,
  activeAnalytics: null,
  supervisorTaskFilter: null,
  recipientTaskFilter: null,
  statusTaskFilter: [],
  analyticsFilterProj: [],
  analyticsFilterStaff: [],
  analyticsFilterJobTitle: [],
  analyticsFilterTaskFrom: null,
  analyticsFilterTaskTo: null,
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    addTasksFilter: (state, action: PayloadAction<string>) => {
      if (state.tasksFilter.includes(action.payload)) {
        state.tasksFilter = state.tasksFilter.filter(item => item !== action.payload);
      } else {
        state.tasksFilter = [...state.tasksFilter, action.payload];
      }
    },
    addPostsFilter: (state, action: PayloadAction<string>) => {
      if (state.postsFilter.includes(action.payload)) {
        state.postsFilter = state.postsFilter.filter(item => item !== action.payload);
      } else {
        state.postsFilter = [...state.postsFilter, action.payload];
      }
    },
    addStaffsFilter: (state, action: PayloadAction<string>) => {
      if (state.staffsFilter.includes(action.payload)) {
        state.staffsFilter = state.staffsFilter.filter(item => item !== action.payload);
      } else {
        state.staffsFilter = [...state.staffsFilter, action.payload];
      }
    },
    addGipFilter: (state, action: PayloadAction<string>) => {
      if (state.gipFilter.includes(action.payload)) {
        state.gipFilter = state.gipFilter.filter(item => item !== action.payload);
      } else {
        state.gipFilter = [...state.gipFilter, action.payload];
      }
    },
    addprojFilter: (state, action: PayloadAction<string | null>) => {
      if (action.payload === state.projFilter) {
        state.projFilter = null;
      } else {
        state.projFilter = action.payload;
      }
    },
    addRecipientFilter: (state, action: PayloadAction<string>) => {
      if (state.recipientFilter.includes(action.payload)) {
        state.recipientFilter = state.recipientFilter.filter(item => item !== action.payload);
      } else {
        state.recipientFilter = [...state.recipientFilter, action.payload];
      }
    },
    addDepartmentFilter: (state, action: PayloadAction<string>) => {
      if (state.departmentFilter.includes(action.payload)) {
        state.departmentFilter = state.departmentFilter.filter(item => item !== action.payload);
      } else {
        state.departmentFilter = [...state.departmentFilter, action.payload];
      }
    },
    addJobTitleFilter: (state, action: PayloadAction<string>) => {
      if (state.jobTitleFilter.includes(action.payload)) {
        state.jobTitleFilter = state.jobTitleFilter.filter(item => item !== action.payload);
      } else {
        state.jobTitleFilter = [...state.jobTitleFilter, action.payload];
      }
    },
    addRoleFilter: (state, action: PayloadAction<string>) => {
      if (state.roleFilter.includes(action.payload)) {
        state.roleFilter = state.roleFilter.filter(item => item !== action.payload);
      } else {
        state.roleFilter = [...state.roleFilter, action.payload];
      }
    },
    addActiveFilter: (state, action: PayloadAction<string | null>) => {
      if (action.payload === state.activeFilter) {
        state.activeFilter = null;
      } else {
        state.activeFilter = action.payload;
      }
    },
    addActiveAnalyticsFilter: (state, action: PayloadAction<string | null>) => {
      if (action.payload === state.activeAnalytics) {
        state.activeAnalytics = null;
      } else {
        state.activeAnalytics = action.payload;
      }
    },
    addSupervisorTaskFilter: (state, action: PayloadAction<string | null>) => {
      if (action.payload === state.supervisorTaskFilter) {
        state.supervisorTaskFilter = null;
      } else {
        state.supervisorTaskFilter = action.payload;
      }
    },
    addRecipientTaskFilter: (state, action: PayloadAction<string | null>) => {
      if (action.payload === state.recipientTaskFilter) {
        state.recipientTaskFilter = null;
      } else {
        state.recipientTaskFilter = action.payload;
      }
    },
    addAnalyticsFilterProj: (state, action: PayloadAction<string>) => {
      if (state.analyticsFilterProj.includes(action.payload)) {
        state.analyticsFilterProj = state.analyticsFilterProj.filter(
          item => item !== action.payload
        );
      } else {
        state.analyticsFilterProj = [...state.analyticsFilterProj, action.payload];
      }
    },
    addAnalyticsFilterStaff: (state, action: PayloadAction<string>) => {
      if (state.analyticsFilterStaff.includes(action.payload)) {
        state.analyticsFilterStaff = state.analyticsFilterStaff.filter(
          item => item !== action.payload
        );
      } else {
        state.analyticsFilterStaff = [...state.analyticsFilterStaff, action.payload];
      }
    },
    addAnalyticsFilterJobTitle: (state, action: PayloadAction<string>) => {
      if (state.analyticsFilterJobTitle.includes(action.payload)) {
        state.analyticsFilterJobTitle = state.analyticsFilterJobTitle.filter(
          item => item !== action.payload
        );
      } else {
        state.analyticsFilterJobTitle = [...state.analyticsFilterJobTitle, action.payload];
      }
    },
    addAnalyticsFilterTaskFrom: (state, action: PayloadAction<string | null>) => {
      if (action.payload === state.analyticsFilterTaskFrom) {
        state.analyticsFilterTaskFrom = null;
      } else {
        state.analyticsFilterTaskFrom = action.payload;
      }
    },
    addAnalyticsFilterTaskTo: (state, action: PayloadAction<string | null>) => {
      if (action.payload === state.analyticsFilterTaskTo) {
        state.analyticsFilterTaskTo = null;
      } else {
        state.analyticsFilterTaskTo = action.payload;
      }
    },
    addStatusTaskFilter: (state, action: PayloadAction<string>) => {
      {
        if (state.statusTaskFilter.includes(action.payload)) {
          state.statusTaskFilter = state.statusTaskFilter.filter(item => item !== action.payload);
        } else {
          state.statusTaskFilter = [...state.statusTaskFilter, action.payload];
        }
      }
    },
  },
});

export const {
  addTasksFilter,
  addPostsFilter,
  addStaffsFilter,
  addGipFilter,
  addprojFilter,
  addRecipientFilter,
  addDepartmentFilter,
  addJobTitleFilter,
  addRoleFilter,
  addActiveFilter,
  addSupervisorTaskFilter,
  addRecipientTaskFilter,
  addAnalyticsFilterProj,
  addAnalyticsFilterStaff,
  addAnalyticsFilterJobTitle,
  addAnalyticsFilterTaskFrom,
  addAnalyticsFilterTaskTo,
  addStatusTaskFilter,
  addActiveAnalyticsFilter,
} = filtersSlice.actions;
export default filtersSlice.reducer;
