import { AnyAction, PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  fetchAllStaffs,
  fetchEmployees,
  fetchFilterStaffs,
  patchStaff,
  postStaff,
} from '../api/staffApi';
import { FilterStaffResponse, IStaffsResponse, RootCreateStaff } from '../@types/staffTypes';

interface StaffState {
  staffCreateResponse: RootCreateStaff | null;
  AllStaffResponse: IStaffsResponse | null;
  staffLoading: boolean;
  staffError?: string;
  staffFilters: FilterStaffResponse | null;
  staffResponse: IStaffsResponse | null;
}

const initialState: StaffState = {
  staffCreateResponse: null,
  AllStaffResponse: null,
  staffLoading: false,
  staffError: undefined,
  staffFilters: null,
  staffResponse: null,
};

const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    modalClose(state) {
      state.staffCreateResponse = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAllStaffs.pending, state => {
        state.staffLoading = true;
        state.staffError = undefined;
      })
      .addCase(fetchAllStaffs.fulfilled, (state, action) => {
        state.AllStaffResponse = action.payload;
        state.staffLoading = false;
      })
      .addCase(fetchFilterStaffs.pending, state => {
        state.staffLoading = true;
        state.staffError = undefined;
      })
      .addCase(fetchFilterStaffs.fulfilled, (state, action) => {
        state.staffFilters = action.payload;
        state.staffLoading = false;
      })
      .addCase(fetchEmployees.pending, state => {
        state.staffLoading = true;
        state.staffError = undefined;
      })
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        state.staffResponse = action.payload;
        state.staffLoading = false;
      })
      .addCase(postStaff.pending, state => {
        state.staffLoading = true;
        state.staffError = undefined;
      })
      .addCase(postStaff.fulfilled, (state, action) => {
        state.staffCreateResponse = action.payload;
        state.staffLoading = false;
      })
      .addCase(patchStaff.pending, state => {
        state.staffLoading = true;
        state.staffError = undefined;
      })
      .addCase(patchStaff.fulfilled, (state, action) => {
        state.staffCreateResponse = action.payload;
        state.staffLoading = false;
      })
      .addMatcher(isError, (state, action: PayloadAction<string>) => {
        state.staffError = action.payload;
        state.staffLoading = false;
      });
  },
});

export const { modalClose } = staffSlice.actions;
export default staffSlice.reducer;

function isError(action: AnyAction) {
  return action.type.endsWith('rejected');
}
