import { keyframes, styled } from '@mui/material';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const LayoutAnalytics = styled('div')({
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '86vw',
  overflow: 'hidden',
});

export const List = styled('div')({
  marginLeft: '10px',
  maxHeight: '70vh',
  transition: '0.5s',
  overflowY: 'scroll',
  opacity: '0',
  animationName: `${fadeIn}`,
  animationDuration: '0.5s',
  animationDelay: '0.1s',
  animationFillMode: 'forwards',
  '::-webkit-scrollbar': {
    width: '10px',
  },
  '::-webkit-scrollbar-track': {
    background: '#e1e1fb',
  },
  '::-webkit-scrollbar-thumb': {
    background: '#f6f6f6',
  },
});

export const Table = styled('div')({
  marginTop: '20px',
  display: 'flex',
  flexDirection: 'column',
  width: '86vw',
  maxHeight: '75vh',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const TableHeader = styled('div')({
  display: 'flex',
  width: '86vw',
  height: '66px',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: '#e1e1fb',
  fontWeight: '600',
});

export const TableItemAnalytics = styled('div')<{ currentIndex: number }>(({ currentIndex }) => ({
  width: '86vw',
  display: 'flex',
  minHeight: '60px',
  backgroundColor: currentIndex % 2 === 0 ? 'white' : '#f6f6f6',
}));

export const TableElement = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '4px',
  width: '100%',
  height: '80px',
  borderBottom: '1px solid #d4d3db',
});

export const DateFilter = styled('div')<{ width: string; left: string }>(({ width, left }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '4px',
  position: 'absolute',
  left: left,
  marginTop: '20px',
  border: '1px solid #f2f2f2',
  borderRadius: '4px',
  maxHeight: '170px',
  width: width,
  background: 'white',
  zIndex: '1',
  overflowY: 'scroll',
  boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)',
  opacity: '0',
  animationName: `${fadeIn}`,
  animationDuration: '0.2s',
  animationFillMode: 'forwards',
  '::-webkit-scrollbar': {
    width: '0px',
  },
}));

export const CloseFilter = styled('div')({
  zIndex: '3',
  left: '972px',
  top: '86px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  height: '30px',
  padding: '4px',
  borderRadius: '4px',
  border: 'none',
  fontSize: '18px',
  background: '#4f51f4',
  color: 'white',
  cursor: 'pointer',
  boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.4)',
  transition: '0.2s',
  fontFamily: "'Mulish', sans-serif",
  '&:hover': {
    background: '#1314b0',
  },
});

export const StaffContentItem = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '150px',
  width: '100%',
  textAlign: 'center',
  borderRight: '1px solid #d4d3db',
});

export const StaffListAnalytics = styled('div')({
  display: 'flex',
  width: '100%',
  transition: '0.2s',
  cursor: 'help',
  '&:hover': {
    background: '#d4d3db',
  },
});
