import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ISettingsType } from '../@types/settingsType';
import { PatchNotificationSettings } from '../api/settingsApi';

interface settingsState extends ISettingsType {
  settingsResponse: unknown;
  settingsLoading?: boolean;
  settingsError?: string;
}

interface UpdateStateAction {
  type: string;
  payload: {
    fieldName: keyof ISettingsType;
    value: boolean;
  };
}

const initialState: settingsState = {
  settingsResponse: null,
  settingsLoading: false,
  settingsError: undefined,
  brow_newTask: true,
  brow_changeStatus: true,
  brow_confirmation: true,
  brow_changePrioroty: true,
  brow_changeDedline: true,
  brow_reassigning: true,
  mail_newTask: true,
  mail_changeStatus: true,
  mail_confirmation: true,
  mail_changePrioroty: true,
  mail_changeDedline: true,
  mail_reassigning: true,
};

const settingsSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    handleSettingChange(state, action: PayloadAction<UpdateStateAction['payload']>) {
      const { fieldName, value } = action.payload;
      state[fieldName] = value;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(PatchNotificationSettings.pending, state => {
        state.settingsLoading = true;
        state.settingsError = undefined;
      })
      .addCase(PatchNotificationSettings.fulfilled, (state, action) => {
        state.settingsResponse = action.payload;
        state.settingsLoading = false;
      })
      .addCase(PatchNotificationSettings.rejected, (state, action) => {
        state.settingsError = action.error.message;
        state.settingsLoading = false;
      });
  },
});

export const { handleSettingChange } = settingsSlice.actions;
export default settingsSlice.reducer;
