import { styled } from '@mui/material';

export const List = styled('div')({
  display: 'flex',
  alignItems: 'cneter',
  height: '40px',
  background: '#e1e1fb',
  cursor: 'pointer',
  borderTop: '1px solid gray',
  transition: '0.2s',
  '&:hover': {
    background: '#D4D4FE',
  },
});

export const TodosName = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '200px',
  padding: '1vw',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '20px',
  alignItems: 'center',
});

export const ArrowBlock = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '10px',
  transition: '0.2s',
  '&:hover': {
    background: '#e1e1fb',
  },
});
