import { Box } from '@mui/material';
import { CreateTaskButton } from '../../Compounds/buttons/CreateTaskButton';
import { ProjectTableHeader } from './Table/ProjectTableHeader';
import { ProjectTaskList } from './ProjectTaskList';
import { useAppDispatch, useAppSelector } from '../../Redux/ReduxHooks';
import { useCallback, useEffect, useState } from 'react';
import { PageTitle } from './styled';
import { addGipFilter, addRecipientFilter, addprojFilter } from '../../Redux/Slices/filters.slice';
import { Spinner } from '../../Compounds/spinner/Spinner';
import { logout } from '../../Redux/Slices/user.slice';
import { Search } from '../../Compounds/search/Search';
import { fetchAllTasks } from '../../Redux/api/tasksApi';
import { fetchAllProjects, fetchProjects } from '../../Redux/api/projectsApi';
import { refreshTokenPost } from '../../Redux/api/userApi';
import { Filter } from '../../Compounds/filters/Filter';
import { FilterInc } from '../../Compounds/filters/FilterInc';
import { fetchAllStaffs } from '../../Redux/api/staffApi';

export const Project = () => {
  const { userResponse } = useAppSelector(state => state.userSlice);
  const { projectResponse, myProjectLoading, projectError, projectLoading } = useAppSelector(
    state => state.projectSlice
  );
  const { taskLoading } = useAppSelector(state => state.taskSlice);
  const { projectCurrentList } = useAppSelector(state => state.currentListSlice);
  const { gipFilter, projFilter, recipientFilter } = useAppSelector(state => state.filtersSlice);
  const dispatch = useAppDispatch();

  const [pageLoading, setPageLoading] = useState(false);

  const role = userResponse?.user_role;

  const stringGip = useCallback(() => {
    return gipFilter.length <= 1 ? gipFilter[0] : gipFilter.join(',');
  }, [gipFilter]);

  const stringRecipient = useCallback(() => {
    return recipientFilter.length <= 1 ? recipientFilter[0] : recipientFilter.join(',');
  }, [recipientFilter]);

  const stringGipParam = stringGip();
  const stringProjParam = projFilter;
  const stringRecipientParam = stringRecipient();
  const filterDate = projectCurrentList;

  useEffect(() => {
    setPageLoading(true);
    dispatch(refreshTokenPost());
    setPageLoading(false);
  }, [dispatch]);

  useEffect(() => {
    setPageLoading(true);
    dispatch(fetchAllTasks());
    dispatch(fetchAllProjects());
    dispatch(
      fetchAllStaffs({
        stringDepartmentParam: null,
        stringJobTitleParam: null,
        stringRoleParam: null,
        stringActiveParam: null,
      })
    );
    dispatch(fetchProjects({ stringGipParam, stringProjParam, stringRecipientParam, filterDate }));
    if (projectError) {
      dispatch(logout());
    }
    setPageLoading(false);
  }, [stringGipParam, stringProjParam, stringRecipientParam, dispatch, projectError, filterDate]);

  const handleGipChange = (option: string) => {
    dispatch(addGipFilter(option));
  };

  const handleProjChange = (option: string | null) => {
    dispatch(addprojFilter(option));
  };

  const handleRecipientChange = (option: string) => {
    dispatch(addRecipientFilter(option));
  };

  return (
    <Box padding='30px' display='flex' flexDirection='column'>
      <Box display='flex' alignItems='center'>
        <PageTitle>Проекты</PageTitle>
        {role !== 'Сотрудник' && <CreateTaskButton currentTaskCreate={'project'} />}
        <Search />
      </Box>
      <Box display='flex' alignItems='center' width='800px'>
        <Filter
          label={
            gipFilter.length === 1
              ? gipFilter[0]
              : gipFilter.length === 0
              ? 'Все ГИПы'
              : 'Выбрано несколько ГИПов'
          }
          handleChange={handleGipChange}
          options={projectResponse?.filters.filterGip}
          selectedOptions={gipFilter}
          width='320px'
          left='160px'
        />
        <FilterInc
          label={projFilter !== null ? `${projFilter}` : 'Все проекты'}
          handleChange={handleProjChange}
          options={projectResponse?.filters.filterProj}
          selectedOptions={projFilter}
          width='210px'
          left='310px'
        />
        {role !== 'Сотрудник' && (
          <Filter
            label={
              recipientFilter.length === 1
                ? recipientFilter[0]
                : recipientFilter.length === 0
                ? 'Все ответственные'
                : 'Выбрано несколько ответственных'
            }
            handleChange={handleRecipientChange}
            options={projectResponse?.filters.filterRecipient}
            selectedOptions={recipientFilter}
            width='320px'
            left='480px'
          />
        )}
      </Box>
      <ProjectTableHeader />
      {myProjectLoading || taskLoading || projectLoading || pageLoading ? (
        <Box height='60vh' width='80vw' display='flex' justifyContent='center' alignItems='center'>
          <Spinner />
        </Box>
      ) : (
        <ProjectTaskList />
      )}
    </Box>
  );
};
