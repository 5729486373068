import { AnyAction, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GetNewNotif, GetOldNotif, PatchSeeNotifications } from '../api/notifApi';
import { INotifItem } from '../@types/notifTypes';

interface notifState {
  newNotifResponse: INotifItem[] | null;
  notifResponse: INotifItem[] | null;
  newNotifLoading: boolean;
  notifLoading: boolean;
  newNotifError?: string;
  notifError?: string;
}

const initialState: notifState = {
  newNotifResponse: null,
  notifResponse: null,
  newNotifLoading: false,
  notifLoading: false,
  notifError: undefined,
  newNotifError: undefined,
};

const notifSlice = createSlice({
  name: 'notif',
  initialState,
  reducers: {
    notifSeeing(state) {
      state.newNotifResponse = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(GetOldNotif.pending, state => {
        state.notifLoading = true;
        state.notifError = undefined;
      })
      .addCase(GetOldNotif.fulfilled, (state, action) => {
        state.notifResponse = action.payload;
        state.notifLoading = false;
      })
      .addCase(GetNewNotif.pending, state => {
        state.newNotifLoading = true;
        state.newNotifError = undefined;
      })
      .addCase(GetNewNotif.fulfilled, (state, action) => {
        if (action.payload === null) {
          return;
        }
        state.newNotifResponse = action.payload;
        state.newNotifLoading = false;
      })
      .addCase(PatchSeeNotifications.pending, state => {
        state.notifLoading = true;
        state.notifError = undefined;
      })
      .addCase(PatchSeeNotifications.fulfilled, (state, action) => {
        state.notifLoading = false;
      })
      .addMatcher(isError, (state, action: PayloadAction<string>) => {
        state.notifError = action.payload;
        state.newNotifError = action.payload;
        state.notifLoading = false;
      });
  },
});

export const { notifSeeing } = notifSlice.actions;
export default notifSlice.reducer;

function isError(action: AnyAction) {
  return action.type.endsWith('rejected');
}
