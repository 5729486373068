import { Link, useNavigate } from 'react-router-dom';
import {
  Analytics,
  Coupon,
  Dashboard,
  Employee,
  NavigationIcons,
  Product,
  Settings,
  NavBarWrapper,
  Exit,
  Notifications,
  Dot,
} from './styled';
import { useAppDispatch, useAppSelector } from '../../Redux/ReduxHooks';
import { logout } from '../../Redux/Slices/user.slice';
import { changeCurrentPage } from '../../Redux/Slices/navBar.slice';
import { Box } from '@mui/material';
import { Logo } from './Logo';

const navigateIsActive = [
  'Notifications',
  'Tasks',
  'Project',
  'Staffs',
  'Coupon',
  'Analytics',
  'Settings',
];

export const NavBar = () => {
  const { currentPage } = useAppSelector(state => state.navBarSlice);
  const { userResponse } = useAppSelector(state => state.userSlice);
  const { newNotifResponse } = useAppSelector(state => state.notifSlice);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const role = userResponse?.user_role;

  const exitApp = () => {
    dispatch(logout());
    navigate('/SignIn');
    dispatch(changeCurrentPage('Tasks'));
  };

  const setCurrentPage = (pageName: string) => {
    dispatch(changeCurrentPage(pageName));
  };

  return (
    <NavBarWrapper>
      <NavigationIcons>
        <Logo />
        <Link to='/Notifications'>
          <Notifications onClick={() => setCurrentPage(navigateIsActive[0])} isActive={currentPage}>
            <Box display='flex' justifyContent='space-between' alignItems='center' width='120px'>
              <span>Уведомления</span>
              {newNotifResponse !== null && <Dot></Dot>}
            </Box>
          </Notifications>
        </Link>
        <Link to='/Task'>
          <Dashboard onClick={() => setCurrentPage(navigateIsActive[1])} isActive={currentPage}>
            Задачи
          </Dashboard>
        </Link>
        <Link to='/Project'>
          <Product onClick={() => setCurrentPage(navigateIsActive[2])} isActive={currentPage}>
            Проекты
          </Product>
        </Link>
        {role !== 'Сотрудник' && (
          <>
            <Link to='/Staffs'>
              <Employee onClick={() => setCurrentPage(navigateIsActive[3])} isActive={currentPage}>
                Сотрудники
              </Employee>
            </Link>
            <Link to='/Coupon'>
              <Coupon onClick={() => setCurrentPage(navigateIsActive[4])} isActive={currentPage}>
                Справка
              </Coupon>
            </Link>
            <Link to='/Analytics'>
              <Analytics onClick={() => setCurrentPage(navigateIsActive[5])} isActive={currentPage}>
                Аналитика
              </Analytics>
            </Link>
          </>
        )}
        <Link to='/Settings'>
          <Settings onClick={() => setCurrentPage(navigateIsActive[6])} isActive={currentPage}>
            Настройки
          </Settings>
        </Link>
        <Exit onClick={() => exitApp()}>Выход</Exit>
      </NavigationIcons>
    </NavBarWrapper>
  );
};
