import { AnyAction, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GetChat } from '../api/chatApi';
import { IMessageItem } from '../@types/chatTypes';

interface ChatState {
  getChatResponse: IMessageItem[] | null;
  chatLoading: boolean;
  chatError?: string;
}

const initialState: ChatState = {
  getChatResponse: null,
  chatLoading: false,
  chatError: undefined,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    closeChat(state) {
      state.getChatResponse = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(GetChat.pending, state => {
        state.chatLoading = true;
        state.chatError = undefined;
      })
      .addCase(GetChat.fulfilled, (state, action) => {
        state.getChatResponse = action.payload.value;
        state.chatLoading = false;
      })
      .addMatcher(isError, (state, action: PayloadAction<string>) => {
        state.chatError = action.payload;
        state.chatLoading = false;
      });
  },
});

export const { closeChat } = chatSlice.actions;
export default chatSlice.reducer;

function isError(action: AnyAction) {
  return action.type.endsWith('rejected');
}
