import { Box } from '@mui/material';
import { TaskItem } from './TaskItem';
import { useAppSelector } from '../../../Redux/ReduxHooks';
import { TodosList } from './styled';

export const TodayTodos = () => {
  const { taskResponse } = useAppSelector(state => state.taskSlice);

  return (
    <TodosList>
      {taskResponse?.value.today && taskResponse?.value.today.length !== 0 ? (
        taskResponse?.value.today.map((item, i) => (
          <TaskItem
            key={i}
            index={i}
            id={item.id}
            code={item.code}
            desc={item.desc}
            taskCodeParent={item.taskCodeParent}
            projectCode={item.projectCode}
            projectId={item.projectId}
            supervisor={item.supervisor}
            supervisorId={item.supervisorId}
            recipient={item.recipient}
            recipientId={item.recipientId}
            priority={item.priority}
            comment={item.comment}
            plannedTime={item.plannedTime}
            actualTime={item.actualTime}
            start={item.start}
            finish={item.finish}
            date={item.date}
            stage={item.stage}
            liteTask={item.liteTask}
            status={item.status}
            startWork={item.startWork}
            creator={item.creator}
            creatorId={item.creatorId}
            historyWorc={item.historyWorc}
            dedline={item.dedline}
            subTask={item.subTask}
          />
        ))
      ) : (
        <Box
          height='60px'
          fontWeight='600'
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          Задач на сегодня нет
        </Box>
      )}
    </TodosList>
  );
};
