import { createSlice } from '@reduxjs/toolkit';
import { patchTaskStatus } from '../../api/tasksApi';
import { TaskResStatus } from '../../@types/tasksType';

interface AllTasksState {
  taskStatusResponse: TaskResStatus | null;
  taskStatusLoading: boolean;
  taskStatusError?: string;
}

const initialState: AllTasksState = {
  taskStatusResponse: null,
  taskStatusLoading: false,
  taskStatusError: undefined,
};

const taskStatusSlice = createSlice({
  name: 'taskStatus',
  initialState,
  reducers: {
    clearStatus(state) {
      state.taskStatusResponse = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(patchTaskStatus.pending, state => {
        state.taskStatusLoading = true;
        state.taskStatusError = undefined;
      })
      .addCase(patchTaskStatus.fulfilled, (state, action) => {
        state.taskStatusResponse = action.payload;
        state.taskStatusLoading = false;
      })
      .addCase(patchTaskStatus.rejected, (state, action) => {
        state.taskStatusLoading = false;
        state.taskStatusError = action.error.message;
        state.taskStatusResponse = null;
      });
  },
});

export const { clearStatus } = taskStatusSlice.actions;
export default taskStatusSlice.reducer;
