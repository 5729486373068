import { createAsyncThunk } from '@reduxjs/toolkit';
import { makeRequest } from './makeRequest';
import { IUser } from '../@types/userTypes';
import { ILoginForm } from '../../Components/SignIn/SignIn';
import { getRefreshToken, logout } from '../Slices/user.slice';
import { RootState } from '../store';

export const login = createAsyncThunk<IUser, ILoginForm, { rejectValue: string }>(
  'user/login',
  async (userPostData, { rejectWithValue }) => {
    try {
      {
        const response = await makeRequest.post(`/Token`, userPostData);

        return response.data;
      }
    } catch (e) {
      return rejectWithValue('Ошибка авторизации');
    }
  }
);

export const refreshTokenPost = createAsyncThunk<IUser, void, { rejectValue: string }>(
  'user/refresh',
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      {
        const refresh_token = getRefreshToken(getState() as RootState);
        const response = await makeRequest.post(`/Token`, { refresh_token: refresh_token });

        return response.data;
      }
    } catch (error) {
      rejectWithValue('Ошибка авторизации');
      dispatch(logout());
    }
  }
);
