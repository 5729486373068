import { AnyAction, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GetStatisticFilters, fetchAnalytics } from '../api/analyticsApi';
import { IAnalyticsFilterRes, IAnalyticsResponse } from '../@types/analyticsType';

interface analyticsState {
  analyticsFIlterRes: IAnalyticsFilterRes | null;
  analyticsResponse: IAnalyticsResponse | null;
  analyticsLoading: boolean;
  analyticsError?: string;
}

const initialState: analyticsState = {
  analyticsFIlterRes: null,
  analyticsResponse: null,
  analyticsLoading: false,
  analyticsError: undefined,
};

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAnalytics.pending, state => {
        state.analyticsLoading = true;
        state.analyticsError = undefined;
      })
      .addCase(fetchAnalytics.fulfilled, (state, action) => {
        state.analyticsResponse = action.payload;
        state.analyticsLoading = false;
      })
      .addCase(GetStatisticFilters.pending, state => {
        state.analyticsLoading = true;
        state.analyticsError = undefined;
      })
      .addCase(GetStatisticFilters.fulfilled, (state, action) => {
        state.analyticsFIlterRes = action.payload;
        state.analyticsLoading = false;
      })
      .addMatcher(isError, (state, action: PayloadAction<string>) => {
        state.analyticsError = action.payload;
        state.analyticsLoading = false;
      });
  },
});

export default analyticsSlice.reducer;

function isError(action: AnyAction) {
  return action.type.endsWith('rejected');
}
