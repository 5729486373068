import { keyframes, styled } from '@mui/material';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const TodosList = styled('div')({
  maxHeight: '70vh',
  transition: '0.5s',
  opacity: '0',
  animationName: `${fadeIn}`,
  animationDuration: '0.5s',
  animationDelay: '0.1s',
  animationFillMode: 'forwards',
  overflowY: 'scroll',
  '::-webkit-scrollbar': {
    width: '2px',
  },
});
