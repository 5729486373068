import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface currentList {
  projectCurrentList: string;
  taskCurrentList: string;
}

const initialState: currentList = {
  projectCurrentList: 'today',
  taskCurrentList: 'today',
};

const currentListSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    changeProjectList(state, action: PayloadAction<string>) {
      state.projectCurrentList = action.payload;
    },
    changeTaskList(state, action: PayloadAction<string>) {
      state.taskCurrentList = action.payload;
    },
  },
});

export const { changeProjectList, changeTaskList } = currentListSlice.actions;
export default currentListSlice.reducer;
