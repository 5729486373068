import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface rowItem {
  id: number;
  order?: number;
  text: string;
}

export const rows = [
  { id: 1, order: 1, text: 'ФИО' },
  { id: 2, order: 2, text: 'Отдел' },
  { id: 3, order: 3, text: 'Должность' },
  { id: 4, order: 4, text: 'Роль' },
];

interface dragState {
  rowList: rowItem[];
  currentRow: rowItem | null;
}

const initialState: dragState = {
  rowList: rows,
  currentRow: null,
};

const dragSlice = createSlice({
  name: 'structure',
  initialState,
  reducers: {
    setCurrentRow(state, action: PayloadAction<rowItem | null>) {
      state.currentRow = action.payload;
    },
    setRowList(state, action: PayloadAction<rowItem[]>) {
      state.rowList = action.payload;
    },
  },
});

export const { setCurrentRow, setRowList } = dragSlice.actions;
export default dragSlice.reducer;
