import dayjs, { Dayjs } from 'dayjs';
import { useAppDispatch, useAppSelector } from '../../../Redux/ReduxHooks';
import { useEffect, useState } from 'react';
import { Box, MenuItem, InputLabel, Select, FormControl, TextField } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { IFormInput } from '../../../Redux/@types/formTypes';
import {
  isCurrentAction,
  isCurrentForm,
  openChatById,
  slideBarIsOpen,
} from '../../../Redux/Slices/SlideBar.slice';
import { ErrorInForm } from '../../../Compounds/form/ErrorInForm';
import { PriorityButtons } from '../../../Compounds/form/PriorityButtons';
import { ConfirmButton } from '../styled';
import { ITaskItem } from '../../../Redux/@types/taskItem';
import { patchTask } from '../../../Redux/api/tasksApi';
import { TimeSelect } from '../TimeSelect';
import { GetChat } from '../../../Redux/api/chatApi';
import { MainTitle, FakeInput, Author, DisableInput } from '../Forms/styled';

export const ViewTask = () => {
  const { currentViewTaskId } = useAppSelector(state => state.slideBarSlice);
  const { allTaskResponse } = useAppSelector(state => state.allTasksSlice);
  const { projectResponse } = useAppSelector(state => state.projectSlice);
  const { staffResponse } = useAppSelector(state => state.staffSlice);
  const { userResponse } = useAppSelector(state => state.userSlice);
  const { taskError } = useAppSelector(state => state.taskSlice);
  const dispatch = useAppDispatch();

  const role = userResponse?.user_role;

  const [currentStateForm, setCurrentStateForm] = useState(true);
  const [priority, setPriority] = useState(0);
  const [projectCode, setProjectCode] = useState(' ');
  const [recipient, setRecipient] = useState(' ');
  const [supervisor, setSupervisor] = useState(' ');
  const [dateValue, setDateValue] = useState<Dayjs | null>(dayjs());
  const [number, setNumber] = useState('0');
  const [timeValue, setTimeValue] = useState('минут');
  const [planTimeInForm, setPlanTimeInForm] = useState('');

  useEffect(() => {
    setPlanTimeInForm(`${number} ${timeValue}`);
  }, [number, timeValue]);

  const foundElement = allTaskResponse?.value.find(
    (item: ITaskItem) => item.id === currentViewTaskId
  );

  const id = foundElement?.id;
  const foundPlannedTime = foundElement?.plannedTime.split(' ');

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = data => {
    dispatch(patchTask({ data }));
    if (taskError) {
      alert('Ошибка редактирования');
      return;
    }
    dispatch(isCurrentAction('taskChange'));
    dispatch(isCurrentForm('success'));
  };

  const showChat = () => {
    if (id) dispatch(openChatById(id));
    dispatch(isCurrentForm('chat'));
    dispatch(slideBarIsOpen(true));
    dispatch(GetChat({ id }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MainTitle>{currentStateForm ? 'Просмотр' : 'Редактирование'}</MainTitle>
      <FakeInput
        disabled={true}
        value={currentViewTaskId !== null ? currentViewTaskId : ''}
        {...register('id', {
          value: currentViewTaskId,
        })}
      />
      <Author>
        Автор: <DisableInput color='neutral' disabled={true} value={foundElement?.creator} />
      </Author>
      <Author>
        Дата создания:{' '}
        <DisableInput
          color='neutral'
          disabled={true}
          value={dayjs(foundElement?.date).format('DD.MM.YYYY')}
        />
      </Author>
      {currentStateForm ? (
        <Box marginTop='20px'>
          <FormControl fullWidth>
            <TextField
              disabled={currentStateForm}
              id='desc'
              label='Шифр Проекта'
              InputLabelProps={{
                shrink: true,
              }}
              variant='outlined'
              multiline
              value={foundElement?.projectCode}
            />
          </FormControl>
        </Box>
      ) : (
        <>
          <Box marginTop='20px'>
            <FormControl fullWidth>
              <InputLabel shrink={true} id='projectCode'>
                Шифр Проекта
              </InputLabel>
              <Select
                labelId='projectCode'
                id='select-project'
                value={projectCode.length > 1 ? projectCode : foundElement?.projectCode}
                label={'Шифр Проекта'}
                onChange={e => setProjectCode(e.target.value)}
              >
                {projectResponse?.projects.map((item, i) => (
                  <MenuItem key={i} value={item.code}>
                    {item.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <FakeInput
            disabled={true}
            {...register('projectCode', {
              value: projectCode.length > 1 ? projectCode : foundElement?.projectCode,
            })}
          />
        </>
      )}
      <Box marginTop='20px'>
        <FormControl fullWidth>
          <TextField
            disabled={currentStateForm}
            id='desc'
            label='Описание'
            InputLabelProps={{
              shrink: true,
            }}
            variant='outlined'
            multiline
            defaultValue={foundElement?.desc}
            {...register('desc', {
              minLength: 3,
            })}
          />
        </FormControl>
      </Box>
      {role !== 'Сотрудник' && (
        <>
          {currentStateForm ? (
            <Box marginTop='20px'>
              <FormControl fullWidth>
                <TextField
                  disabled={currentStateForm}
                  id='recipient'
                  label='Контроль'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant='outlined'
                  multiline
                  value={foundElement?.supervisor}
                />
              </FormControl>
            </Box>
          ) : (
            <Box marginTop='20px'>
              <FormControl fullWidth>
                <InputLabel id='supervisorId'>Контроль</InputLabel>
                <Select
                  labelId='supervisorId'
                  id='select-supervisor'
                  value={supervisor.length > 1 ? supervisor : foundElement?.supervisor}
                  label={'Контроль'}
                  disabled={false}
                  onChange={e => setSupervisor(e.target.value)}
                >
                  {staffResponse?.value.map((item, i) => (
                    <MenuItem key={i} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FakeInput
                disabled={true}
                value={supervisor}
                {...register('supervisor', {
                  value: supervisor,
                })}
              />
            </Box>
          )}
          {currentStateForm ? (
            <Box marginTop='20px'>
              <FormControl fullWidth>
                <TextField
                  disabled={currentStateForm}
                  id='recipient'
                  label='Исполнитель'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant='outlined'
                  multiline
                  value={foundElement?.recipient}
                />
              </FormControl>
            </Box>
          ) : (
            <Box marginTop='20px'>
              <FormControl fullWidth>
                <InputLabel id='recipientId'>Исполнитель</InputLabel>
                <Select
                  labelId='recipientId'
                  id='select-recipient'
                  value={
                    recipient.length > 1 || !foundElement?.recipient
                      ? recipient
                      : foundElement?.recipient
                  }
                  label={'Исполнитель'}
                  disabled={false}
                  onChange={e => setRecipient(e.target.value)}
                >
                  <MenuItem value={'не выбран'}>{'не выбран'}</MenuItem>
                  {staffResponse?.value.map((item, i) => (
                    <MenuItem key={i} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FakeInput
                disabled={true}
                value={
                  recipient === 'не выбран' || recipient.length > 2
                    ? recipient
                    : foundElement?.recipient
                }
                {...register('recipient', {
                  value: recipient,
                })}
              />
            </Box>
          )}
        </>
      )}
      {currentStateForm ? (
        <Box marginTop='20px'>
          <FormControl fullWidth>
            <TextField
              disabled={currentStateForm}
              id='deadline'
              label='Дата Завершения'
              InputLabelProps={{
                shrink: true,
              }}
              variant='outlined'
              multiline
              value={dayjs(foundElement?.date).format('DD.MM.YYYY')}
            />
          </FormControl>
        </Box>
      ) : (
        <Box display='flex' justifyContent='cneter' alignItems='center' marginTop='20px'>
          <Controller
            control={control}
            name='dedline'
            render={() => (
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
                <DatePicker
                  format='DD.MM.YYYY'
                  label='Дата завершения'
                  value={dayjs(foundElement?.date)}
                  onChange={newValue => setDateValue(newValue)}
                />
              </LocalizationProvider>
            )}
          />
        </Box>
      )}
      <FakeInput
        disabled={true}
        value={dateValue?.format('YYYY.MM.DD')}
        {...register('dedline', {
          value: dateValue?.format('YYYY.MM.DD'),
        })}
      />
      {errors.dedline && <ErrorInForm value={'Укажите дату завершения'} />}
      {currentStateForm ? (
        <Box marginTop='10px'>
          <FormControl fullWidth>
            <TextField
              disabled={currentStateForm}
              id='plannedTime'
              label='Планируемое время исполнения (в формате ЧЧ:ММ)'
              variant='outlined'
              defaultValue={foundElement?.plannedTime}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Box>
      ) : (
        <>
          <Box fontSize='14px'>Планируемое время исполнения</Box>
          <TimeSelect
            foundPlannedTime={foundPlannedTime}
            setNumber={setNumber}
            setTimeValue={setTimeValue}
            number={number}
            timeValue={timeValue}
          />
          <FakeInput
            disabled={true}
            value={planTimeInForm}
            {...register('plannedTime', {
              value: planTimeInForm,
            })}
          />
        </>
      )}
      <Box marginTop='10px'>Приоритет</Box>
      <PriorityButtons
        disabled={currentStateForm}
        priority={priority}
        currentPriority={foundElement?.priority}
        setPriority={setPriority}
      />
      <FakeInput
        disabled={true}
        value={priority}
        {...register('priority', {
          value: priority,
        })}
      />
      {currentStateForm ? (
        <Box marginTop='10px' display='flex' justifyContent='space-between' alignItems='center'>
          <ConfirmButton onClick={() => showChat()}>Открыть чат</ConfirmButton>
          {foundElement?.status !== 'Выполнена' && (
            <ConfirmButton onClick={() => setCurrentStateForm(false)}>Редактировать</ConfirmButton>
          )}
        </Box>
      ) : (
        <Box marginTop='10px' display='flex' justifyContent='space-between'>
          <Box width='200px'>
            <ConfirmButton type='submit'>Сохранить</ConfirmButton>
          </Box>
          <Box width='200px'>
            <ConfirmButton onClick={() => setCurrentStateForm(true)}>Отмена</ConfirmButton>
          </Box>
        </Box>
      )}
    </form>
  );
};
