import { Box, FormControl, MenuItem, Select } from '@mui/material';
import React, { useEffect } from 'react';

const timeValueChange = ['минут', 'часов', 'дней'];
const numbers = Array.from({ length: 161 }, (_, index) => index);

interface TimeSelectProps {
  setNumber: (value: string) => void;
  setTimeValue: (value: string) => void;
  number: string;
  timeValue: string;
  foundPlannedTime?: string[];
}

export const TimeSelect: React.FC<TimeSelectProps> = ({
  setNumber,
  number,
  timeValue,
  setTimeValue,
  foundPlannedTime,
}) => {
  useEffect(() => {
    if (foundPlannedTime) {
      setNumber(foundPlannedTime[0]);
      setTimeValue(foundPlannedTime[1]);
    }
  }, []);

  return (
    <Box marginTop='10px' display='flex' justifyContent='space-between'>
      <Box width='200px'>
        <FormControl fullWidth>
          <Select
            id='select-time'
            value={number}
            disabled={false}
            onChange={e => setNumber(e.target.value)}
          >
            {numbers.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box width='200px'>
        <FormControl fullWidth>
          <Select
            id='select-date'
            value={timeValue}
            disabled={false}
            onChange={e => setTimeValue(e.target.value)}
          >
            {timeValueChange.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};
