import { Box } from '@mui/material';
import { useAppSelector } from '../../../Redux/ReduxHooks';
import { TodosList } from './styled';
import { ProjectTaskItem } from '../ProjectTaskItem';

export const ProjectFutureTodos = () => {
  const { projectResponse } = useAppSelector(state => state.projectSlice);

  return (
    <TodosList>
      {projectResponse?.future && projectResponse?.future.length > 0 ? (
        projectResponse?.future.map((response, i) => (
          <ProjectTaskItem key={i} index={i} date={response.date} content={response.content} />
        ))
      ) : (
        <Box
          height='60px'
          fontWeight='600'
          display='flex'
          alignItems='center'
          justifyContent='center'
          width='86vw'
        >
          Предстоящих задач нет, либо указаны неверные параметры фильтрации
        </Box>
      )}
    </TodosList>
  );
};
