import { Box } from '@mui/material';
import { StaffTableItem } from './StaffTableItem';
import { useAppDispatch, useAppSelector } from '../../Redux/ReduxHooks';
import { Table, TableContent, TableHeadStaff, TableHeader } from './styled';
import { Spinner } from '../../Compounds/spinner/Spinner';
import { rowItem, setCurrentRow, setRowList } from '../../Redux/Slices/drag.slice';
import { sortRows } from '../../helpers/dragHelpers';

export const StaffTable = () => {
  const { AllStaffResponse, staffLoading } = useAppSelector(state => state.staffSlice);
  const { rowList, currentRow } = useAppSelector(state => state.dragSlice);
  const dispatch = useAppDispatch();

  const handleDragStart = (card: rowItem) => {
    dispatch(setCurrentRow(card));
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragDrop = (e: React.DragEvent<HTMLDivElement>, card: rowItem) => {
    e.preventDefault();
    const sortArr = rowList.map(item => {
      if (item.id === card.id) {
        return { ...item, order: currentRow?.order };
      }
      if (item.id === currentRow?.id) {
        return { ...item, order: card.order };
      }
      return item;
    });
    dispatch(setRowList(sortArr));
  };

  const sortList = [...rowList];

  return (
    <Table>
      <TableHeader>
        {sortList.sort(sortRows).map(card => (
          <TableHeadStaff
            onDragStart={() => handleDragStart(card)}
            onDragOver={e => handleDragOver(e)}
            onDrop={e => handleDragDrop(e, card)}
            key={card.id}
            draggable={true}
          >
            {card.text}
          </TableHeadStaff>
        ))}
      </TableHeader>
      <TableContent>
        {staffLoading ? (
          <Box height='60vh' display='flex' justifyContent='center' alignItems='center'>
            <Spinner />
          </Box>
        ) : (
          <>
            {AllStaffResponse?.value !== null ? (
              AllStaffResponse?.value.map((item, i) => (
                <StaffTableItem
                  key={i}
                  index={i}
                  id={item.id}
                  name={item.name}
                  role={item.role}
                  division={item.division}
                  post={item.post}
                />
              ))
            ) : (
              <Box height='60vh' display='flex' justifyContent='center' alignItems='center'>
                Сотрудников нет либо неверные параметры фильтрации
              </Box>
            )}
          </>
        )}
      </TableContent>
    </Table>
  );
};
