import { CompletedTodos } from './CompletedTodos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FutureTodos } from './FutureTodos';
import { TodayTodos } from './TodayTodos';
import { ArrowBlock, List, TodosName } from '../../TableStyles/styled';
import { useAppDispatch, useAppSelector } from '../../../Redux/ReduxHooks';
import { changeTaskList } from '../../../Redux/Slices/currentList.slice';

export const TaskList = () => {
  const { taskCurrentList } = useAppSelector(state => state.currentListSlice);
  const dispatch = useAppDispatch();

  const changeSection = (isCurrentSection: string) => {
    if (taskCurrentList === isCurrentSection) {
      dispatch(changeTaskList(''));
      return;
    }
    dispatch(changeTaskList(isCurrentSection));
  };

  console.log(taskCurrentList);

  return (
    <>
      <List onClick={() => changeSection('future')}>
        <TodosName>
          Предстоящие задачи
          <ArrowBlock>
            <KeyboardArrowDownIcon />
          </ArrowBlock>
        </TodosName>
      </List>
      {taskCurrentList === 'future' && <FutureTodos />}
      <List onClick={() => changeSection('today')}>
        <TodosName>
          Задачи на сегодня
          <ArrowBlock>
            <KeyboardArrowDownIcon />
          </ArrowBlock>
        </TodosName>
      </List>
      {taskCurrentList === 'today' && <TodayTodos />}
      <List onClick={() => changeSection('completed')}>
        <TodosName>
          Выполненые задачи
          <ArrowBlock>
            <KeyboardArrowDownIcon />
          </ArrowBlock>
        </TodosName>
      </List>
      {taskCurrentList === 'completed' && <CompletedTodos />}
    </>
  );
};
