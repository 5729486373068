import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectToken } from '../Slices/user.slice';
import { RootState } from '../store';
import { makeRequest } from './makeRequest';
import { FilterStaffResponse, IStaffsResponse, RootCreateStaff } from '../@types/staffTypes';
import { IFormInput } from '../@types/formTypes';

export const fetchAllStaffs = createAsyncThunk<
  IStaffsResponse,
  {
    stringDepartmentParam: string | null;
    stringJobTitleParam: string | null;
    stringRoleParam: string | null;
    stringActiveParam: string | null;
  },
  { rejectValue: string }
>(
  'staffs/fetchAllStaffs',
  async (
    { stringDepartmentParam, stringJobTitleParam, stringRoleParam, stringActiveParam },
    { rejectWithValue, getState }
  ) => {
    const token = selectToken(getState() as RootState);
    const res = await makeRequest.get(`/GetAllEmployees`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
      params: {
        filterDepartment: stringDepartmentParam,
        filterJobTitle: stringJobTitleParam,
        filterRole: stringRoleParam,
        filterActive: stringActiveParam,
      },
    });

    if (res.status !== 200) {
      return rejectWithValue('Не удалось получить данные');
    }
    if (res.data.statusCode === 401) {
      return rejectWithValue('needAuth');
    }

    return res.data;
  }
);

export const fetchFilterStaffs = createAsyncThunk<
  FilterStaffResponse,
  void,
  { rejectValue: string }
>('staffs/fetchFilterStaffs', async (_, { rejectWithValue, getState }) => {
  const token = selectToken(getState() as RootState);
  const res = await makeRequest.get(`/GetEmployeesFilters`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });

  if (res.status !== 200) {
    return rejectWithValue('Не удалось получить данные');
  }
  if (res.data.statusCode === 401) {
    return rejectWithValue('needAuth');
  }

  return res.data.value;
});

export const fetchEmployees = createAsyncThunk<IStaffsResponse, void, { rejectValue: string }>(
  'staffs/fetchEmployees',
  async (_, { rejectWithValue, getState }) => {
    const token = selectToken(getState() as RootState);
    const res = await makeRequest.get(`/GetEmployees`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });

    if (res.status !== 200) {
      return rejectWithValue('Не удалось получить данные');
    }

    return res.data;
  }
);

export const postStaff = createAsyncThunk<
  RootCreateStaff,
  { data: IFormInput },
  { rejectValue: string }
>('staffs/postStaff', async ({ data }, { rejectWithValue, getState }) => {
  const token = selectToken(getState() as RootState);
  const res = await makeRequest.post(`/PostEmployees`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });

  if (res.status !== 200) {
    return rejectWithValue('Не удалось отправить данные');
  }

  if (res.data.statusCode >= 400) {
    alert(res.data.value.message);
    return null;
  }

  return res.data;
});

export const patchStaff = createAsyncThunk<
  RootCreateStaff,
  { data: IFormInput },
  { rejectValue: string }
>('staffs/patchStaff', async ({ data }, { rejectWithValue, getState }) => {
  const token = selectToken(getState() as RootState);
  const res = await makeRequest.patch(`/PatchEmployees`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });

  if (res.status !== 200) {
    return rejectWithValue('Не удалось отправить данные');
  }

  if (res.data.statusCode === 202) {
    alert(res.data.value.message);
    return res.data.value;
  }

  if (res.data.statusCode >= 400) {
    alert(res.data.value.message);
    return rejectWithValue('Не удалось отправить данные');
  }

  return res.data;
});
