import { keyframes, styled } from '@mui/material';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const TodoInfo = styled('li')<{ isActive: string; currentIndex: number }>(
  ({ currentIndex }) => ({
    width: '86vw',
    height: '94px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '1vh',
    paddingBottom: '1vh',
    overflowX: 'hidden',
    cursor: 'pointer',
    transition: '0.2s',
    backgroundColor: currentIndex % 2 === 0 ? 'white' : '#f6f6f6',
    '&:hover': {
      background: '#d4d4fa',
    },
  })
);

export const Status = styled('button')<{ isActive: string }>(({ isActive }) => ({
  position: 'relative',
  height: '40px',
  padding: '4px',
  minWidth: '110px',
  border: '1px solid #a1a1a1',
  borderRadius: '4px',
  cursor: 'pointer',
  display: 'flex',
  fontWeight: '500',
  fontSize: '14px',
  justifyContent: 'space-between',
  alignItems: 'center',
  transition: '0.2s',
  background: isActive === 'В работе' ? '#BCF495' : isActive === 'На паузе' ? '#DDDDDD' : 'white',
  '&:hover': {
    background: 'white',
  },
}));

export const CurrentStatus = styled('ul')({
  listStyle: 'none',
  backgroundColor: '#F8F8F8',
  boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.4)',
  position: 'absolute',
  fontSize: '14px',
  left: '122px',
  minWidth: '90px',
  animationName: `${fadeIn}`,
  animationDuration: '0.3s',
  animationFillMode: 'forwards',
  borderRadius: '4px',
});

export const ListElement = styled('li')({
  height: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: '0.2s',
  width: '142px',
  '&:hover': {
    background: '#e1e1fb',
  },
});

export const ChatButton = styled('button')<{ index: number }>(({ index }) => ({
  height: '40px',
  width: '60px',
  border: '1px solid #a1a1a1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: '0.2s',
  borderRadius: '4px',
  cursor: 'pointer',
  fontSize: '14px',
  backgroundColor: index % 2 === 0 ? 'white' : 'white',
  '&:hover': {
    background: '#e1e1fb',
  },
}));

export const TodosList = styled('div')({
  maxHeight: '70vh',
  transition: '0.5s',
  overflow: 'auto',
  opacity: '0',
  animationName: `${fadeIn}`,
  animationDuration: '0.5s',
  animationDelay: '0.1s',
  animationFillMode: 'forwards',
  overflowY: 'scroll',
  '::-webkit-scrollbar': {
    width: '0px',
  },
});
