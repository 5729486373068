import { styled } from '@mui/material';

export const SearchInput = styled('input')({
  outline: 'none',
  border: 'none',
  height: '22px',
  width: '12vw',
  borderRadius: '4px',
  padding: '4px',
  background: '#f2f2f2',
  fontSize: '16px',
  transition: 'width 0.5s ease',
  '&:focus': {
    width: '25vw',
    border: '3px solid #D4D4FE',
  },
});

export const SearchIcon = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '36px',
  width: '36px',
  borderRadius: '4px',
  transition: '0.2s',
});

export const SearchInputBlock = styled('div')({
  marginLeft: '30px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '8px',
  background: '#f2f2f2',
  height: '36px',
});

export const SearchItem = styled('div')({
  background: '#F7F7FC',
  display: 'flex',
  alignItems: 'center',
  padding: '4px',
  height: '60px',
  cursor: 'pointer',
  transition: '0.2s',
  borderBottom: '1px solid gray',
  '&:hover': {
    background: '#e1e1fb',
  },
});

export const SearchValueBlock = styled('div')({
  zIndex: '4',
  borderRadius: '4px',
  position: 'absolute',
  maxHeight: '344px',
  width: '500px',
  background: 'white',
  overflowY: 'scroll',
  left: '540px',
  top: '70px',
  boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)',
});
