import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ProjectFutureTodos } from './Todos/ProjectFutureTodos';
import { ProjectTodayTodos } from './Todos/ProjectTodayTodos';
import { ProjectCompletedTodos } from './Todos/ProjectCompletedTodos';
import { ArrowBlock, List, TodosName } from '../TableStyles/styled';
import { useAppDispatch, useAppSelector } from '../../Redux/ReduxHooks';
import { changeProjectList } from '../../Redux/Slices/currentList.slice';

export const ProjectTaskList = () => {
  const { projectCurrentList } = useAppSelector(state => state.currentListSlice);
  const dispatch = useAppDispatch();

  const changeSection = (isCurrentSection: string) => {
    if (projectCurrentList === isCurrentSection) {
      dispatch(changeProjectList(''));
      return;
    }
    dispatch(changeProjectList(isCurrentSection));
  };

  return (
    <>
      <List onClick={() => changeSection('future')}>
        <TodosName>
          Предстоящие задачи
          <ArrowBlock>
            <KeyboardArrowDownIcon />
          </ArrowBlock>
        </TodosName>
      </List>
      {projectCurrentList === 'future' && <ProjectFutureTodos />}
      <List onClick={() => changeSection('today')}>
        <TodosName>
          Задачи на сегодня
          <ArrowBlock>
            <KeyboardArrowDownIcon />
          </ArrowBlock>
        </TodosName>
      </List>
      {projectCurrentList === 'today' && <ProjectTodayTodos />}
      <List onClick={() => changeSection('completed')}>
        <TodosName>
          Выполненые задачи
          <ArrowBlock>
            <KeyboardArrowDownIcon />
          </ArrowBlock>
        </TodosName>
      </List>
      {projectCurrentList === 'completed' && <ProjectCompletedTodos />}
    </>
  );
};
