import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface sideBarShowing {
  sliderBarIsOpen: boolean;
  currentFormContent: string;
  currentViewTaskId: number | null;
  currentAction: string;
  currentChatId: number | null;
  currentStaffId: number | null;
}

const initialState: sideBarShowing = {
  sliderBarIsOpen: false,
  currentFormContent: '',
  currentViewTaskId: null,
  currentAction: '',
  currentChatId: null,
  currentStaffId: null,
};

export const slideBarSlice = createSlice({
  name: 'showSideBar',
  initialState,
  reducers: {
    slideBarIsOpen: (state, action: PayloadAction<boolean>) => {
      state.sliderBarIsOpen = action.payload;
    },
    isCurrentForm: (state, action: PayloadAction<string>) => {
      state.currentFormContent = action.payload;
    },
    openTaskFromId: (state, action: PayloadAction<number>) => {
      state.currentViewTaskId = action.payload;
    },
    isCurrentAction: (state, action: PayloadAction<string>) => {
      state.currentAction = action.payload;
    },
    openChatById: (state, action: PayloadAction<number | null>) => {
      state.currentChatId = action.payload;
    },
    openStaffById: (state, action: PayloadAction<number>) => {
      state.currentStaffId = action.payload;
    },
  },
});

export const {
  slideBarIsOpen,
  isCurrentForm,
  openTaskFromId,
  isCurrentAction,
  openChatById,
  openStaffById,
} = slideBarSlice.actions;
export default slideBarSlice.reducer;
