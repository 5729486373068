import { styled } from '@mui/material';

export const TableHeadStaff = styled('div')({
  borderLeft: '1px solid #d4d3db',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  minWidth: '300px',
  height: '60px',
  cursor: 'grab',
});

export const StaffItem = styled('div')<{ currentIndex: number }>(({ currentIndex }) => ({
  display: 'flex',
  width: '86vw',
  height: '60px',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: currentIndex % 2 === 0 ? 'white' : '#f6f6f6',
  cursor: 'pointer',
  transition: '0.2s',
  '&:hover': {
    background: '#d4d4fa',
  },
}));

export const TableHeader = styled('div')({
  marginTop: '20px',
  display: 'flex',
  width: '86vw',
  height: '60px',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: '#e1e1fb',
  fontWeight: '600',
});

export const Table = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '86vw',
  maxHeight: '80vh',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const TableContent = styled('div')({
  overflowY: 'scroll',
  overflowX: 'hidden',
  '::-webkit-scrollbar': {
    width: '00px',
  },
});
