import { TaskHeader } from './Table/TaskHeader';
import { TaskList } from './Table/TaskList';
import { Box } from '@mui/material';
import { SearchMenu } from './SearchMenu';
import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../Redux/ReduxHooks';
import { Spinner } from '../../Compounds/spinner/Spinner';
import { logout } from '../../Redux/Slices/user.slice';
import { fetchAllTasks, fetchTasks } from '../../Redux/api/tasksApi';
import { fetchAllProjects, fetchProjects } from '../../Redux/api/projectsApi';
import { refreshTokenPost } from '../../Redux/api/userApi';
import { fetchEmployees } from '../../Redux/api/staffApi';
import { FilterTasks } from './Filters/FilterTasks';
import { PageTitle } from '../ProjectPage/styled';

export const TaskPage = () => {
  const { taskCurrentList } = useAppSelector(state => state.currentListSlice);
  const { userResponse } = useAppSelector(state => state.userSlice);
  const { taskLoading, taskError } = useAppSelector(state => state.taskSlice);
  const { allTaskLoading } = useAppSelector(state => state.allTasksSlice);
  const { projectLoading } = useAppSelector(state => state.projectSlice);
  const { supervisorTaskFilter, recipientTaskFilter, statusTaskFilter } = useAppSelector(
    state => state.filtersSlice
  );
  const dispatch = useAppDispatch();

  const role = userResponse?.user_role;
  const filterDate = taskCurrentList;
  const stringFilterStatus =
    statusTaskFilter.length <= 1 ? statusTaskFilter[0] : statusTaskFilter.join(',');

  useEffect(() => {
    dispatch(refreshTokenPost());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchProjects({
        stringGipParam: null,
        stringProjParam: null,
        stringRecipientParam: null,
        filterDate: '',
      })
    );
    dispatch(fetchAllTasks());
    dispatch(
      fetchTasks({
        stringSupervisorParam: supervisorTaskFilter,
        stringRecipientParam: recipientTaskFilter,
        filterStatus: stringFilterStatus,
        filterDate: filterDate,
      })
    );
    dispatch(fetchAllProjects());
    dispatch(fetchEmployees());
    if (taskError) {
      dispatch(logout());
    }
  }, [dispatch, role, supervisorTaskFilter, recipientTaskFilter, statusTaskFilter, filterDate]);

  return (
    <Box padding='30px' display='flex' flexDirection='column' maxWidth='88vw'>
      <Box display='flex' alignItems='center'>
        <PageTitle>Задачи</PageTitle>
        <SearchMenu />
      </Box>
      <FilterTasks />
      <TaskHeader />
      {taskLoading || allTaskLoading || projectLoading ? (
        <Box height='50vh' width='88vw' display='flex' justifyContent='center' alignItems='center'>
          <Spinner />
        </Box>
      ) : (
        <TaskList />
      )}
    </Box>
  );
};
