import { Box } from '@mui/joy';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../Redux/ReduxHooks';
import { fetchAllStaffs } from '../../../Redux/api/staffApi';
import {
  addActiveFilter,
  addDepartmentFilter,
  addJobTitleFilter,
  addRoleFilter,
} from '../../../Redux/Slices/filters.slice';
import { Filter } from '../../../Compounds/filters/Filter';
import { FilterInc } from '../../../Compounds/filters/FilterInc';

export const FilterGroup = () => {
  const { departmentFilter, jobTitleFilter, roleFilter, activeFilter } = useAppSelector(
    state => state.filtersSlice
  );
  const { staffFilters } = useAppSelector(state => state.staffSlice);
  const dispatch = useAppDispatch();

  const stringDepartmentParam =
    departmentFilter.length <= 1 ? departmentFilter[0] : departmentFilter.join(',');
  const stringJobTitleParam =
    jobTitleFilter.length <= 1 ? jobTitleFilter[0] : jobTitleFilter.join(',');
  const stringRoleParam = roleFilter.length <= 1 ? roleFilter[0] : roleFilter.join(',');
  const stringActiveParam = activeFilter;

  useEffect(() => {
    dispatch(
      fetchAllStaffs({
        stringDepartmentParam,
        stringJobTitleParam,
        stringRoleParam,
        stringActiveParam,
      })
    );
  }, [stringDepartmentParam, stringJobTitleParam, stringRoleParam, stringActiveParam]);

  const handleDepartmentChange = (option: string) => {
    dispatch(addDepartmentFilter(option));
  };

  const handleJobTitleChange = (option: string) => {
    dispatch(addJobTitleFilter(option));
  };

  const handleRoleChange = (option: string) => {
    dispatch(addRoleFilter(option));
  };

  const handleActiveChange = (option: string | null) => {
    dispatch(addActiveFilter(option));
  };

  return (
    <Box display='flex'>
      <Filter
        label={
          departmentFilter.length === 1
            ? departmentFilter[0]
            : departmentFilter.length === 0
            ? 'Все отделы'
            : 'Несколько отделов'
        }
        handleChange={handleDepartmentChange}
        options={staffFilters?.filterDivisions}
        selectedOptions={departmentFilter}
        width={'220px'}
        left={'160px'}
      />
      <Filter
        label={
          jobTitleFilter.length === 1
            ? jobTitleFilter[0]
            : jobTitleFilter.length === 0
            ? 'Все должности'
            : 'Несколько должностей'
        }
        handleChange={handleJobTitleChange}
        options={staffFilters?.filterPosts}
        selectedOptions={jobTitleFilter}
        width={'320px'}
        left={'320px'}
      />
      <Filter
        label={
          roleFilter.length === 1
            ? roleFilter[0]
            : roleFilter.length === 0
            ? 'Все роли'
            : 'Несколько ролей'
        }
        handleChange={handleRoleChange}
        options={staffFilters?.filterRoles}
        selectedOptions={roleFilter}
        width={'200px'}
        left={'510px'}
      />
      <FilterInc
        label={activeFilter !== null ? `${activeFilter}` : 'Все сотрудники'}
        handleChange={handleActiveChange}
        options={staffFilters?.filterStaffs}
        selectedOptions={activeFilter}
        width={'200px'}
        left={'660px'}
      />
    </Box>
  );
};
