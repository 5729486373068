import { AnyAction, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IUser } from '../@types/userTypes';
import { login, refreshTokenPost } from '../api/userApi';

export interface UserState {
  userResponse: IUser | null;
  isLogin: boolean;
  loading: boolean;
  userError?: string;
}

const initialState: UserState = {
  userResponse: null,
  isLogin: false,
  loading: false,
  userError: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout(state) {
      state.isLogin = false;
      state.userResponse = null;
      state.userError = undefined;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(login.pending, state => {
        state.loading = true;
        state.userError = undefined;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.userResponse = action.payload;
        if (state.userResponse.access_token) {
          state.loading = false;
          state.isLogin = true;
          state.userError = undefined;
        }
      })
      .addCase(refreshTokenPost.pending, state => {
        state.loading = true;
        state.userError = undefined;
      })
      .addCase(refreshTokenPost.fulfilled, (state, action) => {
        state.userResponse = action.payload;
        if (state.userResponse.access_token) {
          state.loading = false;
          state.isLogin = true;
          state.userError = undefined;
        }
      })
      .addMatcher(isError, (state, action: PayloadAction<string>) => {
        state.userError = action.payload;
        state.loading = false;
      });
  },
});

export const { logout } = userSlice.actions;
export default userSlice.reducer;
export const selectToken = (state: RootState) => state.userSlice.userResponse?.access_token;
export const getRefreshToken = (state: RootState) => state.userSlice.userResponse?.refresh_token;

function isError(action: AnyAction) {
  return action.type.endsWith('rejected');
}
