import { styled } from '@mui/material';

export const LayoutNotif = styled('div')({
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '86vw',
  overflowX: 'hidden',
});

export const DateNotifTableInfo = styled('div')({
  height: '70px',
  fontSize: '20px',
  fontWeight: '600',
  borderLeft: '1px solid #d4d3db',
  width: '400px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const NotifTableItem = styled('ul')<{ currentIndex: number; opacity: boolean }>(
  ({ currentIndex, opacity }) => ({
    height: '70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: currentIndex % 2 === 0 ? 'white' : '#f6f6f6',
    opacity: opacity ? '0.5' : '1',
  })
);

export const DateNotifInfo = styled('div')({
  height: '70px',
  fontSize: '18px',
  fontWeight: '600',
  borderLeft: '1px solid #d4d3db',
  width: '400px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const NotifTableInfo = styled('div')({
  height: '70px',
  fontSize: '20px',
  fontWeight: '600',
  borderLeft: '1px solid #d4d3db',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const NotifInfo = styled('div')({
  height: '70px',
  fontSize: '18px',
  fontWeight: '600',
  borderLeft: '1px solid #d4d3db',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
