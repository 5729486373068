import { createSlice } from '@reduxjs/toolkit';
import { IGetAllTask } from '../../@types/tasksType';
import { fetchAllTasks } from '../../api/tasksApi';

interface AllTasksState {
  allTaskResponse: IGetAllTask | null;
  allTaskLoading: boolean;
  allTaskError?: string;
}

const initialState: AllTasksState = {
  allTaskResponse: null,
  allTaskLoading: false,
  allTaskError: undefined,
};

const allTasksSlice = createSlice({
  name: 'allTask',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAllTasks.pending, state => {
        state.allTaskLoading = true;
        state.allTaskError = undefined;
      })
      .addCase(fetchAllTasks.fulfilled, (state, action) => {
        state.allTaskResponse = action.payload;
        state.allTaskLoading = false;
      })
      .addCase(fetchAllTasks.rejected, (state, action) => {
        state.allTaskLoading = false;
        state.allTaskError = action.error.message;
        state.allTaskResponse = null;
      });
  },
});

export default allTasksSlice.reducer;
