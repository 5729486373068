import { Box } from '@mui/material';
import { LayoutNotif } from './styled';
import { PageTitle } from '../ProjectPage/styled';
import { useAppDispatch, useAppSelector } from '../../Redux/ReduxHooks';
import { Spinner } from '../../Compounds/spinner/Spinner';
import { NotifTable } from './NotifTable';
import { useEffect } from 'react';
import { GetOldNotif, PatchSeeNotifications } from '../../Redux/api/notifApi';
import { notifSeeing } from '../../Redux/Slices/notif.slice';

export const Notifications = () => {
  const { notifLoading } = useAppSelector(state => state.notifSlice);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(notifSeeing());
    dispatch(GetOldNotif());

    const timer = setTimeout(() => {
      dispatch(PatchSeeNotifications());
    }, 0);

    return () => {
      dispatch(PatchSeeNotifications());
      clearTimeout(timer);
    };
  }, [dispatch]);

  return (
    <LayoutNotif>
      <Box display='flex' alignItems='center' marginTop='4px'>
        <PageTitle>Уведомления</PageTitle>
      </Box>
      {notifLoading ? (
        <Box height='50vh' width='86vw' display='flex' justifyContent='center' alignItems='center'>
          <Spinner />
        </Box>
      ) : (
        <>
          <NotifTable />
        </>
      )}
    </LayoutNotif>
  );
};
