import { AnyAction, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchStructure } from '../api/StructureApi';
import { StructureResponse } from '../@types/structureTyoes';

interface structureState {
  structureResponse: StructureResponse | null;
  structureLoading: boolean;
  structureError?: string;
}

const initialState: structureState = {
  structureResponse: null,
  structureLoading: false,
  structureError: undefined,
};

const structureSlice = createSlice({
  name: 'structure',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchStructure.pending, state => {
        state.structureLoading = true;
        state.structureError = undefined;
      })
      .addCase(fetchStructure.fulfilled, (state, action) => {
        state.structureResponse = action.payload;
        state.structureLoading = false;
      })
      .addMatcher(isError, (state, action: PayloadAction<string>) => {
        state.structureError = action.payload;
        state.structureLoading = false;
      });
  },
});

export default structureSlice.reducer;

function isError(action: AnyAction) {
  return action.type.endsWith('rejected');
}
