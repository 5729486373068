import { styled } from '@mui/material';

export const NotFoundPage = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  height: '50vh',
  padding: '20px',
  fontWeight: '600',
  fontSize: '24px',
});

export const MainInfo = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});
