import { Box } from '@mui/material';
import { PageTitle } from '../ProjectPage/styled';
import { SettingsTable } from './SettingsTable';
import { SaveButton } from './styled';
import { useAppDispatch, useAppSelector } from '../../Redux/ReduxHooks';
import { PatchNotificationSettings } from '../../Redux/api/settingsApi';
import { Spinner } from '../../Compounds/spinner/Spinner';

export const Settings = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(state => state.settingsSlice);

  const saveSettings = () => {
    dispatch(PatchNotificationSettings({ data }));
  };

  return (
    <Box padding='30px' display='flex' flexDirection='column' maxWidth='86vw'>
      <Box display='flex' alignItems='center' marginTop='4px'>
        <PageTitle>Настройки</PageTitle>
      </Box>
      <SettingsTable />
      <Box display='flex' justifyContent='center' alignItems='center' width='86vw' height='10vh'>
        <SaveButton onClick={() => saveSettings()}>Сохранить</SaveButton>
      </Box>
      <Box width='86vw' marginTop='20px' display='flex' justifyContent='center' alignItems='center'>
        {data.settingsLoading && <Spinner />}
      </Box>
    </Box>
  );
};
