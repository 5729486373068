import React, { useState } from 'react';
import { ArrowBlock, SelectBlock } from '../../Components/ProjectPage/styled';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Checkbox, FormControlLabel } from '@mui/material';
import { FilterItem } from './styled';
import { useOutsideClick } from '../../hooks/useOutsideClick';

interface FilterIncProps {
  label: string;
  handleChange: (option: string | null) => void;
  options?: string[];
  selectedOptions: string | null;
  width: string;
  left: string;
}

export const FilterInc: React.FC<FilterIncProps> = ({
  options,
  label,
  handleChange,
  selectedOptions,
  width,
  left,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { refElement } = useOutsideClick(() => setIsOpen(false));

  return (
    <>
      <SelectBlock onClick={() => setIsOpen(!isOpen)}>
        {label}
        <ArrowBlock isOpen={isOpen}>
          <KeyboardArrowDownIcon />
        </ArrowBlock>
      </SelectBlock>
      {isOpen && (
        <div ref={refElement}>
          <FilterItem width={width} left={left}>
            {options?.map((item, i) => (
              <FormControlLabel
                key={i}
                control={
                  <Checkbox
                    checked={selectedOptions === item}
                    onChange={() => handleChange(item)}
                  />
                }
                label={item}
              />
            ))}
          </FilterItem>
        </div>
      )}
    </>
  );
};
