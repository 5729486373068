import { createAsyncThunk } from '@reduxjs/toolkit';
import { IGetAllProjects, IGetProjects } from '../@types/projectTypes';
import { makeRequest } from './makeRequest';
import { TaskResValue } from '../@types/tasksType';
import { IFormInput } from '../@types/formTypes';
import { selectToken } from '../Slices/user.slice';
import { RootState } from '../store';

export const fetchProjects = createAsyncThunk<
  IGetProjects,
  {
    stringGipParam: string | null;
    stringProjParam: string | null;
    stringRecipientParam: string | null;
    filterDate: string;
  },
  { rejectValue: string }
>(
  'projects/fetchProjects',
  async (
    { stringGipParam, stringProjParam, stringRecipientParam, filterDate },
    { rejectWithValue, getState }
  ) => {
    const token = selectToken(getState() as RootState);
    const res = await makeRequest.get(`/GetProjects`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
      params: {
        filterGip: stringGipParam,
        filterProj: stringProjParam,
        filterRecipient: stringRecipientParam,
        filterDate: filterDate,
      },
    });

    if (res.status !== 200) {
      return rejectWithValue('Не удалось получить данные');
    }

    return res.data;
  }
);

export const fetchAllProjects = createAsyncThunk<IGetAllProjects, void, { rejectValue: string }>(
  'projects/fetchAllProjects',
  async (_, { rejectWithValue, getState }) => {
    const token = selectToken(getState() as RootState);
    const res = await makeRequest.get(`/GetAllProjects`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });

    if (res.status !== 200) {
      return rejectWithValue('Не удалось получить данные');
    }

    return res.data;
  }
);

export const postProject = createAsyncThunk<
  TaskResValue,
  { data: IFormInput },
  { rejectValue: string }
>('tasks/postProject', async ({ data }, { rejectWithValue, getState }) => {
  const token = selectToken(getState() as RootState);
  const res = await makeRequest.post(`/PostProj`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });

  if (res.status !== 200) {
    return rejectWithValue('Не удалось отправить данные');
  }

  if (res.data.statusCode >= 400) {
    alert(res.data.value.message);
    return null;
  }

  return res.data.value;
});

export const patchProjects = createAsyncThunk<
  TaskResValue,
  { data: IFormInput },
  { rejectValue: string }
>('tasks/patchProjects', async ({ data }, { rejectWithValue, getState }) => {
  const token = selectToken(getState() as RootState);
  const res = await makeRequest.patch(`/PatchProj`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });

  if (res.status !== 200) {
    return rejectWithValue('Не удалось отправить данные');
  }

  if (res.data.statusCode >= 400) {
    alert(res.data.value.message);
    return null;
  }

  return res.data.value;
});
