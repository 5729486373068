import { keyframes, styled } from '@mui/material';

const slideInRight = keyframes`
from {
    width: 0;
  }
  to {
    width: 380px;
  }
  }`;

export const SearchInChat = styled('input')({
  fontSize: '14px',
  position: 'absolute',
  outline: 'none',
  height: '24px',
  padding: '4px',
  width: '380px',
  borderRadius: '6px',
  border: 'none',
  animationName: `${slideInRight}`,
  animationDuration: '0.4s',
  '&:focus': {
    border: '2px solid #D4D4FE',
  },
});

export const ChatHeader = styled('div')({
  borderRadius: '4px',
  padding: '14px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '50px',
  fontWeight: '600',
  fontSize: '16px',
  background: '#E1E1FB',
  boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.4)',
});

export const SearchIconChat = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: '0.3s',
});

export const MessageBlock = styled('div')({
  height: '60vh',
  display: 'flex',
  flexDirection: 'column-reverse',
  justifyContent: 'flex-start',
  alignItems: 'center',
  overflowX: 'hidden',
  overflowY: 'scroll',
  '::-webkit-scrollbar': {
    width: '0px',
  },
});

export const OtherMessageBlock = styled('div')({
  marginLeft: '15px',
  width: '286px',
  background: '#F1F0F6',
  borderRadius: '4px',
  padding: '6px',
  fontSize: '14px',
  position: 'relative',
});

export const OtherMessage = styled('div')({
  marginTop: '4px',
  width: '290px',
  overflowWrap: 'break-word',
  textAlign: 'left',
  verticalAlign: 'bottom',
});

export const MyMessageBlock = styled('div')({
  marginRight: '15px',
  width: '286px',
  background: '#D4D4FE',
  borderRadius: '4px',
  padding: '6px',
  fontSize: '14px',
  position: 'relative',
});

export const MyMessage = styled('div')({
  marginTop: '4px',
  width: '290px',
  overflowWrap: 'break-word',
  textAlign: 'left',
  verticalAlign: 'bottom',
});

export const SysytemMessage = styled('div')({
  minHeight: '20px',
  background: '#016FD4',
  borderRadius: '6px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: '4px',
  width: '290px',
  fontSize: '12px',
  color: 'white',
});

export const ChatAvatar = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '36px',
  width: '36px',
  borderRadius: '50%',
  background: '#E1E1FB',
});

export const ChatOtherAvatar = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '36px',
  width: '36px',
  borderRadius: '50%',
  background: '#F1F0F6',
});

export const ContainerForMessage = styled('div')({
  display: 'flex',
  flexDirection: 'column-reverse',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '10px',
  boxSizing: 'border-box',
});

export const UserName = styled('div')({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'right',
  width: '200px',
  height: '16px',
  fontSize: '12px',
  fontWeight: '600',
});

export const BottomMenu = styled('div')({
  width: '450px',
  borderRadius: '4px',
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: '76px',
  fontWeight: '500',
  fontSize: '20px',
  background: '#E1E1FB',
  boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.4)',
});

export const ChatInput = styled('textarea')({
  marginLeft: '10px',
  borderRadius: '10px',
  padding: '4px',
  minHeight: '30px',
  width: '320px',
  outline: 'none',
  border: 'none',
  fontSize: '16px',
  fontWeight: '500',
  resize: 'none',
});

export const ChatButton = styled('div')({
  marginLeft: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2px',
  borderRadius: '4px',
  border: 'none',
  cursor: 'pointer',
});

export const FileInput = styled('input')({
  width: '0px',
  height: '0px',
});

export const FileBlock = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  background: '#F1F0F6',
  width: '308px',
  marginLeft: '10px',
  borderRadius: '4px',
  padding: '10px',
});
