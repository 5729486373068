import { keyframes, styled } from '@mui/material';

export const fadeIn = keyframes`
  from {
    transform: rotate(0deg) scaleX(-1);
  }
  to {
    transform: rotate(180deg) scaleX(-1);
  }
`;

export const PageTitle = styled('span')({
  fontSize: '24px',
  fontWeight: '600',
});

export const ArrowBlock = styled('div')<{ isOpen: boolean }>(({ isOpen }) => ({
  transform: isOpen ? 'rotate(180deg) scaleX(-1)' : '',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '10px',
  '&:hover': {
    background: '#d4d3db',
  },
}));

export const SelectBlock = styled('div')({
  border: '1px solid #d4d3db',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '30px',
  minWidth: '100px',
  marginTop: '20px',
  marginRight: '40px',
  padding: '4px',
  borderRadius: '8px',
  outline: 'none',
  fontSize: '16px',
  fontWeight: '500',
  cursor: 'pointer',
  transition: '0.2s',
  '&:hover': {
    background: '#e1e1fb',
  },
});

export const CurrentTask = styled('span')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '20px',
  fontSize: '16px',
  padding: '2px',
  cursor: 'pointer',
  transition: '0.2s',
  '&:hover': {
    background: '#D4D4FE',
  },
});

export const CurrnetTaskInfo = styled('div')({
  display: 'flex',
  minWidth: '250px',
  width: '100%',
  borderRight: '1px solid #d4d3db',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
});
