import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '@mui/joy';
import { ErrorInForm } from '../../../Compounds/form/ErrorInForm';
import { IFormInput } from '../../../Redux/@types/formTypes';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useAppDispatch, useAppSelector } from '../../../Redux/ReduxHooks';
import { PriorityButtons } from '../../../Compounds/form/PriorityButtons';
import { isCurrentAction, isCurrentForm } from '../../../Redux/Slices/SlideBar.slice';
import { Author, DisableInput, FakeInput, MainTitle } from './styled';
import { postProject } from '../../../Redux/api/projectsApi';

export const ProjectForm = () => {
  const currentDate = dayjs().format('DD.MM.YYYY');

  const { userResponse } = useAppSelector(state => state.userSlice);
  const { AllStaffResponse } = useAppSelector(state => state.staffSlice);
  const dispatch = useAppDispatch();

  const [dateValue, setDateValue] = useState<Dayjs | null>(dayjs());
  const [priority, setPriority] = useState(0);
  const [supervisor, setSupervisor] = useState(' ');

  const authorName = userResponse?.user_name;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = data => {
    dispatch(postProject({ data }));
    dispatch(isCurrentAction('projectCreate'));
    dispatch(isCurrentForm('success'));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MainTitle>Создание Проекта</MainTitle>
      <Author>
        Автор:{' '}
        <DisableInput
          color='neutral'
          disabled={true}
          value={authorName}
          {...register('supervisor', {
            required: true,
            value: userResponse?.user_name,
          })}
        />
      </Author>
      <Author>
        Дата создания: <DisableInput color='neutral' disabled={true} value={currentDate} />
      </Author>

      <Box marginTop='20px'>
        <FormControl fullWidth>
          <InputLabel id='supervisorId'>ГИП*</InputLabel>
          <Select
            labelId='supervisorId'
            id='select-supervisor'
            value={supervisor}
            label={'ГИП'}
            disabled={false}
            onChange={e => setSupervisor(e.target.value)}
          >
            {AllStaffResponse?.value.map((item, i) => (
              <MenuItem key={i} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FakeInput
          disabled={true}
          value={supervisor}
          {...register('supervisor', {
            required: true,
            minLength: 3,
            value: supervisor,
          })}
        />
        {errors.supervisor && <ErrorInForm value={'Укажите руководителя'} />}
      </Box>

      <Box marginTop='20px'>
        <FormControl fullWidth>
          <TextField
            id='code'
            label='Шифр Проекта*'
            InputLabelProps={{
              shrink: true,
            }}
            variant='outlined'
            multiline
            {...register('code', {
              required: true,
            })}
          />
        </FormControl>
        {errors.desc && <ErrorInForm value={'Укажите шифр проекта'} />}
      </Box>
      <Box marginTop='20px'>
        <FormControl fullWidth>
          <TextField
            id='desc'
            label='Описание*'
            InputLabelProps={{
              shrink: true,
            }}
            variant='outlined'
            multiline
            {...register('description', {
              required: true,
            })}
          />
        </FormControl>
        {errors.description && <ErrorInForm value={'Укажите описание'} />}
      </Box>
      <Box marginTop='20px'>
        <FormControl fullWidth>
          <TextField
            id='desc'
            label='Ссылка на облако*'
            InputLabelProps={{
              shrink: true,
            }}
            variant='outlined'
            multiline
            {...register('link', {
              required: true,
            })}
          />
        </FormControl>
        {errors.link && <ErrorInForm value={'Укажите ссылку'} />}
      </Box>
      <Box display='flex' justifyContent='cneter' alignItems='center' marginTop='20px'>
        <Controller
          control={control}
          name='date'
          render={() => (
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
              <DatePicker
                format='DD.MM.YYYY'
                label='Срок сдачи проекта*'
                value={dateValue}
                onChange={newValue => setDateValue(newValue)}
                disablePast={true}
              />
            </LocalizationProvider>
          )}
        />
      </Box>
      <FakeInput
        disabled={true}
        value={dateValue?.format('YYYY.MM.DD')}
        {...register('plannedFinishDate', {
          required: true,
          value: dateValue?.format('YYYY.MM.DD'),
        })}
      />
      <Box marginTop='10px' fontSize='14px'>
        Приоритет*
      </Box>
      <PriorityButtons disabled={false} priority={priority} setPriority={setPriority} />
      <FakeInput
        disabled={true}
        value={priority}
        {...register('priority', {
          required: true,
          value: priority,
        })}
      />
      <Button fullWidth sx={{ mt: 4, fontSize: '20px' }} type='submit'>
        Создать
      </Button>
    </form>
  );
};
