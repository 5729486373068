import { Box, styled } from '@mui/material';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Redux/ReduxHooks';
import { NavBar } from './NavBar/NavBar';
import { NotFound } from './ErrorPages/NotFound';
import { Project } from './ProjectPage/Project';
import { SlideBar } from './RightSideBar/SlideBar';
import { Staffs } from './Staffs/Staffs';
import { TaskPage } from './TaskPage/TaskPage';
import { Coupon } from './StructurePage/Coupon';
import { Notifications } from './Notifications/Notifications';
import { Settings } from './SettingsPage/Settings';
import { Analytics } from './Analytics/Analytics';
import { useEffect } from 'react';
import { GetNewNotif } from '../Redux/api/notifApi';

const Wrapper = styled('div')({
  boxSizing: 'border-box',
  position: 'relative',
});

export const Layout = () => {
  const { userResponse } = useAppSelector(state => state.userSlice);
  const { sliderBarIsOpen } = useAppSelector(state => state.slideBarSlice);
  const dispatch = useAppDispatch();

  const role = userResponse?.user_role;

  useEffect(() => {
    const interval = window.setInterval(() => {
      dispatch(GetNewNotif());
    }, 20000);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <Wrapper>
      {sliderBarIsOpen && <SlideBar />}
      <Box display='flex'>
        <NavBar />
        <Routes>
          <Route path='/' element={<Navigate to={'/Task'} />} />
          <Route path='/SignIn' element={<Navigate to={'/Task'} />} />
          <Route path='/Notifications' element={<Notifications />} />
          <Route path='/Task' element={<TaskPage />} />
          <Route path='/Settings' element={<Settings />} />
          <Route path='/Project' element={<Project />} />
          {role !== 'Сотрудник' && (
            <>
              <Route path='/Coupon' element={<Coupon />} />
              <Route path='/Analytics' element={<Analytics />} />
              <Route path='/Staffs' element={<Staffs />} />
            </>
          )}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Box>
    </Wrapper>
  );
};
