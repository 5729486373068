import { styled } from '@mui/material';

export const LayoutStructure = styled('div')({
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '86vw',
  overflowX: 'hidden',
});

export const StructureTableInfo = styled('div')({
  height: '70px',
  fontSize: '20px',
  fontWeight: '600',
  borderLeft: '1px solid #d4d3db',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StructureTableItem = styled('ul')<{ currentIndex: number }>(({ currentIndex }) => ({
  minHeight: '70px',
  fontSize: '20px',
  width: '100%',
  minWidth: '300px',
  display: 'flex',
  backgroundColor: currentIndex % 2 === 0 ? 'white' : '#f6f6f6',
}));

export const StructureTableRole = styled('div')({
  paddingLeft: '40px',
  minHeight: '70px',
  fontSize: '20px',
  borderLeft: '1px solid #d4d3db',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
});

export const StructureTableTakes = styled('div')({
  paddingLeft: '40px',
  borderLeft: '1px solid #d4d3db',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
});

export const ListItem = styled('ul')({
  display: 'flex',
  flexDirection: 'column',
});

export const ListElem = styled('li')({
  listStyle: 'inside',
  marginTop: '4px',
  fontSize: '20px',
});

export const StructureHeadBlock = styled('div')({
  display: 'flex',
  background: '#f6f6f6',
});
