import { LegacyRef } from 'react';
import { SearchInChat } from './styled';

interface SearchChatInputProps {
  inputRef?: LegacyRef<HTMLInputElement>;
  searchValue: string;
  setSearchValue: (value: string) => void;
}

export const SearchChatInput: React.FC<SearchChatInputProps> = ({
  inputRef,
  searchValue,
  setSearchValue,
}) => {
  return (
    <SearchInChat
      ref={inputRef}
      value={searchValue}
      onChange={e => setSearchValue(e.target.value)}
      placeholder='Посик по сообщениям ...'
    />
  );
};
