import { useAppDispatch, useAppSelector } from '../../../Redux/ReduxHooks';
import { patchTaskStatus } from '../../../Redux/api/tasksApi';
import { ListElement } from './styled';
import React from 'react';

export const currentTask = ['В работе', 'На паузе', 'Подтвердить', 'Выполнена'];

export interface ChangeStatusData {
  id: number;
  status: string;
}

interface CurrentStatusTaskProps {
  setCurrentStatus: (value: string) => void;
  id: number;
  userName: string;
  recipient: string;
  currentStatus: string;
}

export const CurrentStatusTask: React.FC<CurrentStatusTaskProps> = ({
  setCurrentStatus,
  id,
  userName,
  recipient,
  currentStatus,
}) => {
  const dispatch = useAppDispatch();
  const { userResponse } = useAppSelector(state => state.userSlice);

  const sessionName = userResponse?.user_name;

  const filterStatus = () => {
    if (userName === sessionName && recipient === sessionName) {
      return ['В работе', 'На паузе', 'Выполнена'];
    }
    return currentTask;
  };

  const statusArray = filterStatus();

  const changeStatus = (index: number) => {
    if (currentStatus === 'Выполнена') {
      return alert('Нельзя изменить статус выполненной задачи');
    }
    const data = { id: id, status: statusArray[index] };

    dispatch(patchTaskStatus({ data }));
    setCurrentStatus(statusArray[index]);
  };

  return (
    <>
      {statusArray.map((item, i) => (
        <ListElement key={item} onClick={() => changeStatus(i)}>
          {item}
        </ListElement>
      ))}
    </>
  );
};
