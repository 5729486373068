import { styled } from '@mui/material';

export const PasswordButton = styled('div')({
  borderRadius: '4px',
  padding: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  transition: '0.2s',
  '&:hover': {
    background: '#e1e1fb',
  },
});

export const CashButton = styled('div')({
  borderRadius: '4px',
  padding: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  transition: '0.2s',
  '&:hover': {
    background: '#e1e1fb',
  },
});
