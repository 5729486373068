import React, { useState } from 'react';
import { ArrowBlock, SelectBlock } from '../../Components/ProjectPage/styled';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Checkbox, FormControlLabel } from '@mui/material';
import { FilterItem } from './styled';
import { useOutsideClick } from '../../hooks/useOutsideClick';

interface FilterProps {
  label: string;
  handleChange: (option: string) => void;
  options?: string[];
  selectedOptions: string[];
  width: string;
  left: string;
}

export const Filter: React.FC<FilterProps> = ({
  options,
  label,
  handleChange,
  selectedOptions,
  width,
  left,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { refElement } = useOutsideClick(() => setIsOpen(false));

  return (
    <>
      <SelectBlock onClick={() => setIsOpen(!isOpen)}>
        {label}
        <ArrowBlock isOpen={isOpen}>
          <KeyboardArrowDownIcon />
        </ArrowBlock>
      </SelectBlock>
      {isOpen && (
        <div ref={refElement}>
          <FilterItem width={width} left={left}>
            {options?.map((item, i) => (
              <FormControlLabel
                key={i}
                control={
                  <Checkbox
                    checked={selectedOptions.includes(item)}
                    onChange={() => handleChange(item)}
                  />
                }
                label={item}
              />
            ))}
          </FilterItem>
        </div>
      )}
    </>
  );
};
