import { Navigate, Route, Routes } from 'react-router-dom';
import { useAppSelector } from './Redux/ReduxHooks';
import { SignIn } from './Components/SignIn/SignIn';
import { NeedAuthPage } from './Components/ErrorPages/NeedAuthPage';
import { Layout } from './Components/Layout';

export const App = () => {
  const { isLogin } = useAppSelector(state => state.userSlice);

  return (
    <>
      {!isLogin ? (
        <Routes>
          <Route path='/' element={<Navigate to='/SignIn' />} />
          <Route path='/SignIn' element={<SignIn />} />
          <Route path='*' element={<NeedAuthPage />} />
        </Routes>
      ) : (
        <Layout />
      )}
    </>
  );
};
