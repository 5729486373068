import { Box } from '@mui/joy';
import { SettingsHeader } from './SettingsHeader';
import { CheckBoxBlock } from './CheckBoxBlock';
import { SettingsTableItem } from './styled';
import { useAppDispatch, useAppSelector } from '../../Redux/ReduxHooks';
import { handleSettingChange } from '../../Redux/Slices/settings.slice';
import { ISettingsType } from '../../Redux/@types/settingsType';

export const SettingsTable = () => {
  const data = useAppSelector(state => state.settingsSlice);
  const dispatch = useAppDispatch();

  const changeNotif = (fieldName: keyof ISettingsType, value: boolean) => {
    dispatch(handleSettingChange({ fieldName: fieldName, value: !value }));
  };

  return (
    <Box display='flex' flexDirection='column' width='86vw' marginTop='30px'>
      <SettingsHeader />
      <SettingsTableItem>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          borderLeft='1px solid #d4d3db'
          minWidth='800px'
          width='100%'
        >
          О новых задачах
        </Box>
        <CheckBoxBlock
          onChange={changeNotif}
          inApp={data.brow_newTask}
          onMail={data.mail_newTask}
          stateInApp={'brow_newTask'}
          stateOnMail={'mail_newTask'}
        />
      </SettingsTableItem>
      <SettingsTableItem>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          borderLeft='1px solid #d4d3db'
          minWidth='800px'
          width='100%'
        >
          Смена статусов задач
        </Box>
        <CheckBoxBlock
          onChange={changeNotif}
          inApp={data.brow_changeStatus}
          onMail={data.mail_changeStatus}
          stateInApp={'brow_changeStatus'}
          stateOnMail={'mail_changeStatus'}
        />
      </SettingsTableItem>
      <SettingsTableItem>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          borderLeft='1px solid #d4d3db'
          minWidth='800px'
          width='100%'
        >
          Подтверждение выполнения задач
        </Box>
        <CheckBoxBlock
          onChange={changeNotif}
          inApp={data.brow_confirmation}
          onMail={data.mail_confirmation}
          stateInApp={'brow_confirmation'}
          stateOnMail={'mail_confirmation'}
        />
      </SettingsTableItem>
      <SettingsTableItem>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          borderLeft='1px solid #d4d3db'
          minWidth='800px'
          width='100%'
        >
          Изменение приоритета задач
        </Box>
        <CheckBoxBlock
          onChange={changeNotif}
          inApp={data.brow_changePrioroty}
          onMail={data.mail_changePrioroty}
          stateInApp={'brow_changePrioroty'}
          stateOnMail={'mail_changePrioroty'}
        />
      </SettingsTableItem>
      <SettingsTableItem>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          borderLeft='1px solid #d4d3db'
          minWidth='800px'
          width='100%'
        >
          Смена дедлайна
        </Box>
        <CheckBoxBlock
          onChange={changeNotif}
          inApp={data.brow_changeDedline}
          onMail={data.mail_changeDedline}
          stateInApp={'brow_changeDedline'}
          stateOnMail={'mail_changeDedline'}
        />
      </SettingsTableItem>
      <SettingsTableItem>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          borderLeft='1px solid #d4d3db'
          minWidth='800px'
          width='100%'
        >
          Переназначение задач
        </Box>
        <CheckBoxBlock
          onChange={changeNotif}
          inApp={data.brow_reassigning}
          onMail={data.mail_reassigning}
          stateInApp={'brow_reassigning'}
          stateOnMail={'mail_reassigning'}
        />
      </SettingsTableItem>
    </Box>
  );
};
