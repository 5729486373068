import { useState } from 'react';
import { FileImage, FileName, HoverText } from './styled';

interface fileIconProps {
  name: string;
}

export const FileIcon: React.FC<fileIconProps> = ({ name }) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <FileImage
      onMouseOver={() => {
        setIsHovering(true);
      }}
      onMouseOut={() => {
        setIsHovering(false);
      }}
    >
      <svg
        width='33'
        height='40'
        viewBox='0 0 33 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M21.7083 16.6667C20.0507 16.6667 18.461 16.0082 17.2889 14.8361C16.1168 13.664 15.4583 12.0743 15.4583 10.4167V2.08333H7.125C6.01993 2.08333 4.96012 2.52232 4.17872 3.30372C3.39732 4.08512 2.95833 5.14493 2.95833 6.25V33.3333C2.95833 34.4384 3.39732 35.4982 4.17872 36.2796C4.96012 37.061 6.01993 37.5 7.125 37.5H25.875C26.9801 37.5 28.0399 37.061 28.8213 36.2796C29.6027 35.4982 30.0417 34.4384 30.0417 33.3333V16.6667H21.7083ZM17.5417 10.4167C17.5417 11.5217 17.9807 12.5815 18.7621 13.3629C19.5435 14.1443 20.6033 14.5833 21.7083 14.5833H29.1875L17.5417 2.9375V10.4167ZM7.125 0H17.5417L32.125 14.5833V33.3333C32.125 34.9909 31.4665 36.5806 30.2944 37.7528C29.1223 38.9249 27.5326 39.5833 25.875 39.5833H7.125C5.4674 39.5833 3.87769 38.9249 2.70558 37.7528C1.53348 36.5806 0.875 34.9909 0.875 33.3333V6.25C0.875 4.5924 1.53348 3.00269 2.70558 1.83058C3.87769 0.65848 5.4674 0 7.125 0Z'
          fill='#4C4C4C'
        />
      </svg>

      <FileName>{name.length > 10 ? name.slice(0, 10) + '...' : name}</FileName>
      {isHovering && name.length > 10 && <HoverText>{name}</HoverText>}
    </FileImage>
  );
};
