import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface navBar {
  currentPage: string;
}

const initialState: navBar = {
  currentPage: 'Tasks',
};

export const navBarSlice = createSlice({
  name: 'navBar',
  initialState,
  reducers: {
    changeCurrentPage: (state, action: PayloadAction<string>) => {
      state.currentPage = action.payload;
    },
  },
});

export const { changeCurrentPage } = navBarSlice.actions;
export default navBarSlice.reducer;
