import { useAppDispatch, useAppSelector } from '../../Redux/ReduxHooks';
import { slideBarIsOpen } from '../../Redux/Slices/SlideBar.slice';
import { ProjectForm } from './Forms/ProjectForm';
import { SuccessTaskCreate } from './SuccessTaskCreate';
import { TableConfig } from './TableConfig';
import { TaksForm } from './Forms/TaksForm';
import { ViewTask } from './ChangedForms/ViewTask';
import { ChatSideBar, CloseButton, Overlay, Shadow, SideBar } from './styled';
import CloseIcon from '@mui/icons-material/Close';
import { ViewProject } from './ChangedForms/VIewProject';
import { Chat } from './Chat/Chat';
import { ViewStaff } from './ChangedForms/ViewStaff';
import { StaffForm } from './Forms/StaffForm';
import { modalClose } from '../../Redux/Slices/staff.slice';

export const SlideBar = () => {
  const dispatch = useAppDispatch();
  const { currentFormContent } = useAppSelector(state => state.slideBarSlice);

  const closeIsSideBar = () => {
    dispatch(slideBarIsOpen(false));
    dispatch(modalClose());
  };

  return (
    <Overlay>
      <Shadow onClick={closeIsSideBar}>
        <CloseButton>
          <CloseIcon />
        </CloseButton>
      </Shadow>

      {currentFormContent === 'chat' ? (
        <ChatSideBar>{<Chat />}</ChatSideBar>
      ) : (
        <SideBar>
          {currentFormContent === 'task' && <TaksForm />}
          {currentFormContent === 'viewTask' && <ViewTask />}
          {currentFormContent === 'project' && <ProjectForm />}
          {currentFormContent === 'viewProject' && <ViewProject />}
          {currentFormContent === 'staff' && <StaffForm />}
          {currentFormContent === 'viewStaff' && <ViewStaff />}
          {currentFormContent === 'tableConfig' && <TableConfig />}
          {currentFormContent === 'success' && <SuccessTaskCreate />}
        </SideBar>
      )}
    </Overlay>
  );
};
