import { useEffect, useRef } from 'react';

export const useOutsideClick = (callback: () => void) => {
  const refElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (refElement.current && !refElement.current.contains(event.target as HTMLDivElement)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refElement, callback]);

  return { refElement };
};
