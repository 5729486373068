import { useLayoutEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../Redux/ReduxHooks';
import { IStaff } from '../../../Redux/@types/staffTypes';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IFormInput } from '../../../Redux/@types/formTypes';
import { ConfirmButton } from '../styled';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { ErrorInForm } from '../../../Compounds/form/ErrorInForm';
import { mailRegEx, passwordRegEx } from '../Forms/StaffForm';
import { patchStaff } from '../../../Redux/api/staffApi';
import { isCurrentAction, isCurrentForm } from '../../../Redux/Slices/SlideBar.slice';
import { MainTitle, FakeInput } from '../Forms/styled';

export const ViewStaff = () => {
  const dispatch = useAppDispatch();
  const { userResponse } = useAppSelector(state => state.userSlice);
  const { currentStaffId } = useAppSelector(state => state.slideBarSlice);
  const { staffFilters, AllStaffResponse, staffCreateResponse } = useAppSelector(
    state => state.staffSlice
  );

  const role = userResponse?.user_role;
  const isAdmin = userResponse?.admin;
  const foundElement = AllStaffResponse?.value.find((item: IStaff) => item.id === currentStaffId);

  const [currentStateForm, setCurrentStateForm] = useState(true);
  const [division, setDivision] = useState(' ');
  const [supervisor, setSupervisor] = useState(' ');
  const [post, setPost] = useState(' ');
  const [isArchive, serIsArchive] = useState(' ');

  useLayoutEffect(() => {
    if (staffCreateResponse !== null) {
      dispatch(isCurrentAction('staffChange'));
      dispatch(isCurrentForm('success'));
    }
  }, [staffCreateResponse, dispatch]);

  const staffArchive = isArchive === 'Да' ? true : false;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = data => {
    dispatch(patchStaff({ data }));
    if (staffCreateResponse === null) {
      return;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MainTitle>{currentStateForm ? 'Просмотр' : 'Редактирование'}</MainTitle>
      <FakeInput
        disabled={true}
        value={currentStaffId !== null ? currentStaffId : ''}
        {...register('id', {
          value: currentStaffId,
        })}
      />
      <Box marginTop='20px'>
        <FormControl fullWidth>
          <TextField
            disabled={currentStateForm}
            id='name'
            label='ФИО*'
            InputLabelProps={{
              shrink: true,
            }}
            variant='outlined'
            multiline
            defaultValue={foundElement?.name}
            {...register('name', {
              required: false,
            })}
          />
        </FormControl>
        {errors.name && <ErrorInForm value={'Укажите ФИО Сотрудника'} />}
      </Box>
      <Box marginTop='20px'>
        <FormControl fullWidth>
          <TextField
            disabled={currentStateForm}
            type='email'
            id='mail'
            label='Email*'
            defaultValue={foundElement?.email}
            InputLabelProps={{
              shrink: true,
            }}
            variant='outlined'
            multiline
            {...register('mail', {
              required: true,
              pattern: mailRegEx,
            })}
          />
        </FormControl>
        {errors.mail && <ErrorInForm value={'Укажите корректный Email Сотрудника'} />}
      </Box>
      {role === 'ГИП' && (
        <Box marginTop='20px'>
          <FormControl fullWidth>
            <TextField
              disabled={currentStateForm}
              id='password'
              label='Пароль*'
              defaultValue={foundElement?.password}
              InputLabelProps={{
                shrink: true,
              }}
              variant='outlined'
              multiline
              {...register('password', {
                required: true,
                minLength: 8,
                pattern: passwordRegEx,
              })}
            />
          </FormControl>
          {errors.password && (
            <ErrorInForm value={'Пароль должен содержать 8 символов, заглавную букву и цифру'} />
          )}
        </Box>
      )}
      {currentStateForm ? (
        <Box marginTop='20px'>
          <FormControl fullWidth>
            <TextField
              disabled={true}
              id='division'
              label='Отдел*'
              InputLabelProps={{
                shrink: true,
              }}
              variant='outlined'
              multiline
              value={foundElement?.division}
            />
          </FormControl>
        </Box>
      ) : (
        <Box marginTop='20px'>
          <FormControl fullWidth>
            <InputLabel id='divisionId'>Отдел*</InputLabel>
            <Select
              labelId='divisionId'
              id='select-division'
              value={division.length > 1 ? division : foundElement?.division}
              label={'Отдел*'}
              onChange={e => setDivision(e.target.value)}
            >
              {staffFilters?.filterDivisions.map((item, i) => (
                <MenuItem key={i} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FakeInput
            disabled={true}
            value={division.length > 1 ? division : foundElement?.division}
            {...register('division', {
              required: false,
              value: division.length > 1 ? division : foundElement?.division,
            })}
          />
          {errors.division && <ErrorInForm value={'Укажите отдел'} />}
        </Box>
      )}
      {currentStateForm ? (
        <Box marginTop='20px'>
          <FormControl fullWidth>
            <TextField
              disabled={true}
              id='division'
              label='Руководитель*'
              InputLabelProps={{
                shrink: true,
              }}
              variant='outlined'
              multiline
              value={foundElement?.supervisor}
            />
          </FormControl>
        </Box>
      ) : (
        <Box marginTop='20px'>
          <FormControl fullWidth>
            <InputLabel id='divisionId'>Руководитель*</InputLabel>
            <Select
              labelId='divisionId'
              id='select-division'
              value={supervisor.length > 1 ? supervisor : foundElement?.supervisor}
              label='Руководитель*'
              onChange={e => setSupervisor(e.target.value)}
            >
              {AllStaffResponse?.value.map((item, i) => (
                <MenuItem key={i} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FakeInput
            disabled={true}
            value={supervisor.length > 1 ? supervisor : foundElement?.supervisor}
            {...register('supervisor', {
              required: true,
              value: supervisor.length > 1 ? supervisor : foundElement?.supervisor,
            })}
          />
          {errors.supervisor && <ErrorInForm value={'Укажите руководителя'} />}
        </Box>
      )}

      {currentStateForm ? (
        <Box marginTop='20px'>
          <FormControl fullWidth>
            <TextField
              disabled={true}
              id='post'
              label='Должность*'
              InputLabelProps={{
                shrink: true,
              }}
              variant='outlined'
              multiline
              value={foundElement?.post}
            />
          </FormControl>
        </Box>
      ) : (
        <Box marginTop='20px'>
          <FormControl fullWidth>
            <InputLabel id='postId'>Должность*</InputLabel>
            <Select
              labelId='postId'
              id='select-post'
              value={post.length > 1 ? post : foundElement?.post}
              label={'Должность*'}
              onChange={e => setPost(e.target.value)}
            >
              {staffFilters?.filterPosts.map((item, i) => (
                <MenuItem key={i} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FakeInput
            disabled={true}
            value={post.length > 1 ? post : foundElement?.post}
            {...register('post', {
              required: false,
              value: post.length > 1 ? post : foundElement?.post,
            })}
          />
          {errors.post && <ErrorInForm value={'Укажите должность'} />}
        </Box>
      )}
      {!currentStateForm && (
        <Box marginTop='20px'>
          <FormControl fullWidth>
            <InputLabel id='postId'>Перенести в архив*</InputLabel>
            <Select
              labelId='postId'
              id='select-post'
              value={isArchive}
              label={'Перенести в архив*'}
              onChange={e => serIsArchive(e.target.value)}
            >
              <MenuItem value={'Да'}>Да</MenuItem>
              <MenuItem value={'Нет'}>Нет</MenuItem>
            </Select>
          </FormControl>
          <FakeInput
            disabled={true}
            value={isArchive}
            {...register('staffArchive', {
              required: false,
              value: staffArchive,
            })}
          />
          {errors.archive && <ErrorInForm value={'Укажите должность'} />}
        </Box>
      )}
      {currentStateForm ? (
        <Box marginTop='10px' display='flex' justifyContent='center'>
          <ConfirmButton disabled={!isAdmin} onClick={() => setCurrentStateForm(false)}>
            Редактировать
          </ConfirmButton>
        </Box>
      ) : (
        <Box marginTop='10px' display='flex' justifyContent='space-between'>
          <Box width='200px'>
            <ConfirmButton type='submit'>Сохранить</ConfirmButton>
          </Box>
          <Box width='200px'>
            <ConfirmButton onClick={() => setCurrentStateForm(true)}>Отмена</ConfirmButton>
          </Box>
        </Box>
      )}
    </form>
  );
};
