import { useAppDispatch } from '../../Redux/ReduxHooks';
import { isCurrentForm, slideBarIsOpen } from '../../Redux/Slices/SlideBar.slice';
import { CreateTaskButton } from '../../Compounds/buttons/CreateTaskButton';
import { SearchBlock, SearchButtons, TableIcon } from './styled';
import { Search } from '../../Compounds/search/Search';

export const SearchMenu = () => {
  const dispatch = useAppDispatch();

  const openSideBarConfig = () => {
    dispatch(slideBarIsOpen(true));
    dispatch(isCurrentForm('tableConfig'));
  };

  return (
    <>
      <SearchBlock>
        <SearchButtons>
          <TableIcon onClick={openSideBarConfig}>
            <svg
              width='28'
              height='23'
              viewBox='0 0 28 23'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M13.6997 8.17514C14.9346 8.17514 15.945 7.34854 15.945 6.33825C15.945 5.32796 14.9346 4.50136 13.6997 4.50136C12.4648 4.50136 11.4545 5.32796 11.4545 6.33825C11.4545 7.34854 12.4648 8.17514 13.6997 8.17514ZM13.6997 10.012C12.4648 10.012 11.4545 10.8386 11.4545 11.8489C11.4545 12.8592 12.4648 13.6858 13.6997 13.6858C14.9346 13.6858 15.945 12.8592 15.945 11.8489C15.945 10.8386 14.9346 10.012 13.6997 10.012ZM13.6997 15.5227C12.4648 15.5227 11.4545 16.3493 11.4545 17.3596C11.4545 18.3699 12.4648 19.1965 13.6997 19.1965C14.9346 19.1965 15.945 18.3699 15.945 17.3596C15.945 16.3493 14.9346 15.5227 13.6997 15.5227Z'
                fill='#9A9AB0'
              />
            </svg>
          </TableIcon>
        </SearchButtons>
        <CreateTaskButton currentTaskCreate={'task'} />
      </SearchBlock>
      <Search />
    </>
  );
};
