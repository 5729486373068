import { AnyAction, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IGetTask, TaskResValue } from '../../@types/tasksType';
import { fetchTasks, patchTask, postTasks } from '../../api/tasksApi';

interface TaskState {
  taskCreateResponse: TaskResValue | null;
  taskResponse: IGetTask | null;
  taskLoading: boolean;
  taskError?: string;
}

const initialState: TaskState = {
  taskCreateResponse: null,
  taskResponse: null,
  taskLoading: false,
  taskError: undefined,
};

const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchTasks.pending, state => {
        state.taskLoading = true;
        state.taskError = undefined;
      })
      .addCase(fetchTasks.fulfilled, (state, action) => {
        state.taskResponse = action.payload;
        state.taskLoading = false;
      })
      .addCase(postTasks.pending, state => {
        state.taskLoading = true;
        state.taskError = undefined;
      })
      .addCase(postTasks.fulfilled, (state, action) => {
        state.taskCreateResponse = action.payload;
        state.taskLoading = false;
      })
      .addCase(patchTask.pending, state => {
        state.taskLoading = true;
        state.taskError = undefined;
      })
      .addCase(patchTask.fulfilled, (state, action) => {
        state.taskCreateResponse = action.payload;
        state.taskLoading = false;
      })
      .addMatcher(isError, (state, action: PayloadAction<string>) => {
        state.taskError = action.payload;
        state.taskLoading = false;
      });
  },
});

export default taskSlice.reducer;

function isError(action: AnyAction) {
  return action.type.endsWith('rejected');
}
