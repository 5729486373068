import { styled } from '@mui/material';

export const Author = styled('div')({
  marginTop: '10px',
  opacity: '0.5',
});

export const FakeInput = styled('input')({
  margin: 0,
  padding: 0,
  border: 'none',
  height: '0px',
  width: '0px',
  background: 'white',
});

export const DisableInput = styled('input')({
  border: 'none',
  minWidth: '260px',
  background: 'white',
  fontSize: '16px',
});

export const MainTitle = styled('span')({
  display: 'flex',
  fontWeight: '600',
  fontSize: '24px',
  justifyContent: 'center',
  alignItems: 'center',
});
