import * as signalR from '@microsoft/signalr';
import { socketUrl } from '../api/makeRequest';

export const hubConnection = new signalR.HubConnectionBuilder()
  .withUrl(socketUrl, {
    skipNegotiation: true,
    transport: signalR.HttpTransportType.WebSockets,
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
    withCredentials: false,
  })
  .configureLogging(signalR.LogLevel.Information)
  .build();
