import { styled } from '@mui/material';

export const ButtonCreate = styled('button')({
  marginLeft: '20px',
  width: '190px',
  height: '36px',
  borderRadius: '4px',
  border: 'none',
  fontSize: '18px',
  fontWeight: '500',
  background: '#4f51f4',
  color: 'white',
  cursor: 'pointer',
  transition: '0.2s',
  fontFamily: "'Mulish', sans-serif",
  '&:hover': {
    background: '#1314b0',
  },
});

export const TaskButtons = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});
