import { Box } from '@mui/material';
import { useAppSelector } from '../../../Redux/ReduxHooks';
import { TodosList } from './styled';
import { ProjectTaskItem } from '../ProjectTaskItem';
import { Spinner } from '../../../Compounds/spinner/Spinner';

export const ProjectCompletedTodos = () => {
  const { projectResponse, projectLoading } = useAppSelector(state => state.projectSlice);

  return (
    <>
      {projectLoading ? (
        <Box>
          <Spinner />
        </Box>
      ) : (
        <TodosList>
          {projectResponse?.completed && projectResponse?.completed.length > 0 ? (
            projectResponse?.completed.map((response, i) => (
              <ProjectTaskItem key={i} index={i} date={response.date} content={response.content} />
            ))
          ) : (
            <Box
              height='60px'
              fontWeight='600'
              display='flex'
              alignItems='center'
              justifyContent='center'
              width='86vw'
            >
              Выполненных задач нет, либо указаны неверные параметры фильтрации
            </Box>
          )}
        </TodosList>
      )}
    </>
  );
};
