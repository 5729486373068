import { styled } from '@mui/material';

export const ButtonRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '10px',
});

export const ButtonHighPriorityy = styled('button')<{
  isActive: number | undefined;
  disabled: boolean;
}>(({ isActive, disabled }) => ({
  width: '120px',
  height: '40px',
  borderRadius: '8px',
  border: 'none',
  fontSize: '16px',
  fontWeight: '500',
  background: isActive === 0 ? '#D4D4FE' : '#d4d3db',
  cursor: disabled ? 'default' : 'pointer',
  transition: '0.2s',
  fontFamily: "'Mulish', sans-serif",
  '&:hover': {
    background: isActive === 0 ? '#D4D4FE' : '#e6e6e6',
  },
}));

export const ButtonMiddlePriorityy = styled('button')<{
  isActive: number | undefined;
  disabled: boolean;
}>(({ isActive, disabled }) => ({
  width: '120px',
  height: '40px',
  borderRadius: '8px',
  border: 'none',
  fontSize: '16px',
  fontWeight: '500',
  background: isActive === 1 ? '#D4D4FE' : '#d4d3db',
  cursor: disabled ? 'default' : 'pointer',
  transition: '0.2s',
  fontFamily: "'Mulish', sans-serif",
  '&:hover': {
    background: isActive === 1 ? '#D4D4FE' : '#e6e6e6',
  },
}));

export const ButtonLowPriorityy = styled('button')<{
  isActive: number | undefined;
  disabled: boolean;
}>(({ isActive, disabled }) => ({
  width: '120px',
  height: '40px',
  borderRadius: '8px',
  border: 'none',
  fontSize: '16px',
  fontWeight: '500',
  background: isActive === 2 ? '#D4D4FE' : '#d4d3db',
  cursor: disabled ? 'default' : 'pointer',
  transition: '0.2s',
  fontFamily: "'Mulish', sans-serif",
  '&:hover': {
    background: isActive === 2 ? '#D4D4FE' : '#e6e6e6',
  },
}));
