import { Box } from '@mui/material';
import { IAnalyticsItem } from '../../Redux/@types/analyticsType';
import { StaffContentItem, StaffListAnalytics, TableElement, TableItemAnalytics } from './styled';
import { useAppSelector } from '../../Redux/ReduxHooks';

interface AnalyticsTableItemProps {
  index: number;
  item: IAnalyticsItem;
}

export const AnalyticsTableItem: React.FC<AnalyticsTableItemProps> = ({ index, item }) => {
  const { analyticCurrent, analyticCompleted } = useAppSelector(state => state.showTableSlice);

  const workTime = item.projWorkTime
    .toString()
    .split(':')
    .map(item => Math.floor(+item))
    .map(item => (item < 10 ? '0' + item.toString() : item))
    .join(':');

  return (
    <TableItemAnalytics currentIndex={index}>
      <Box
        display='flex'
        flexWrap='wrap'
        justifyContent='center'
        alignItems='center'
        minWidth='100px'
        width='100%'
        maxWidth='190px'
        textAlign='center'
        borderLeft='1px solid #d4d3db'
      >
        {item.projectCode}
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        minWidth='100px'
        width='100%'
        maxWidth='190px'
        textAlign='center'
        borderLeft='1px solid #d4d3db'
      >
        {workTime}
      </Box>
      <Box
        display='flex'
        flexWrap='wrap'
        justifyContent='center'
        alignItems='center'
        width='100%'
        textAlign='center'
        borderLeft='1px solid #d4d3db'
      >
        {item.staffsContent.map((elem, i) => (
          <StaffListAnalytics key={i}>
            <StaffContentItem>
              <TableElement>
                {elem.staffName.length > 30 ? elem.staffName.slice(0, 30) + ' ...' : elem.staffName}
              </TableElement>
            </StaffContentItem>
            <StaffContentItem>
              <TableElement>
                {elem.staffPost.length > 40 ? elem.staffPost.slice(0, 40) + ' ...' : elem.staffPost}
              </TableElement>
            </StaffContentItem>
            <StaffContentItem>
              <TableElement>
                {elem.staffTiming
                  .toString()
                  .split(':')
                  .map(item => Math.floor(+item))
                  .map(item => (item < 10 ? '0' + item.toString() : item))
                  .join(':')}
              </TableElement>
            </StaffContentItem>
            {analyticCurrent && (
              <StaffContentItem>
                <TableElement>{elem.activTasksCount}</TableElement>
              </StaffContentItem>
            )}
            {analyticCompleted && (
              <StaffContentItem>
                <TableElement>{elem.completedTasksCount}</TableElement>
              </StaffContentItem>
            )}
            <StaffContentItem>
              <TableElement>{elem.fromTo_activTasksCount}</TableElement>
            </StaffContentItem>
          </StaffListAnalytics>
        ))}
      </Box>
    </TableItemAnalytics>
  );
};
