import { Box, styled } from '@mui/material';
import { useAppSelector } from '../../../Redux/ReduxHooks';

const Head = styled('div')({
  marginTop: '20px',
  width: '86vw',
  alignItems: 'center',
  color: '#11142D',
  fontWeight: '600',
  fontSize: '14px',
});

const Menu = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#F7F7FC',
});

export const TaskHeader = () => {
  const {
    dateRow,
    projectCodeRow,
    taskRow,
    statusRow,
    respomsobleRow,
    reassignRow,
    priorityRow,
    planTimeRow,
    factTimeRow,
    beganRow,
    completedRow,
    dedlineRow,
    chatRow,
  } = useAppSelector(state => state.showTableSlice);

  return (
    <Head>
      <Menu>
        {dateRow && (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='66px'
            minWidth='58px'
            width='100%'
            textAlign='center'
            borderRight='1px solid #d4d3db'
            padding='2px'
          >
            Дата
          </Box>
        )}
        {projectCodeRow && (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='66px'
            minWidth='56px'
            width='100%'
            textAlign='center'
            borderRight='1px solid #d4d3db'
            padding='2px'
          >
            Шифр проекта
          </Box>
        )}
        {taskRow && (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='66px'
            minWidth='150px'
            width='100%'
            textAlign='center'
            borderRight='1px solid #d4d3db'
            padding='2px'
          >
            Задача
          </Box>
        )}
        {chatRow && (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='66px'
            minWidth='28px'
            width='100%'
            textAlign='center'
            borderRight='1px solid #d4d3db'
            padding='2px'
          >
            Чат
          </Box>
        )}
        {statusRow && (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='66px'
            minWidth='130px'
            width='100%'
            textAlign='center'
            borderRight='1px solid #d4d3db'
            padding='2px'
          >
            Статус
          </Box>
        )}
        {respomsobleRow && (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='66px'
            minWidth='120px'
            width='100%'
            textAlign='center'
            borderRight='1px solid #d4d3db'
            padding='2px'
          >
            Контроль
          </Box>
        )}
        {reassignRow && (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='66px'
            minWidth='110px'
            width='100%'
            textAlign='center'
            borderRight='1px solid #d4d3db'
            padding='2px'
          >
            Исполнитель
          </Box>
        )}
        {priorityRow && (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            minWidth='80px'
            width='100%'
            textAlign='center'
            borderRight='1px solid #d4d3db'
            padding='2px'
          >
            Приоритет
          </Box>
        )}
        {dedlineRow && (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='66px'
            minWidth='60px'
            width='100%'
            textAlign='center'
            borderRight='1px solid #d4d3db'
            padding='2px'
          >
            Дедлайн
          </Box>
        )}
        {planTimeRow && (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='66px'
            minWidth='60px'
            width='100%'
            textAlign='center'
            borderRight='1px solid #d4d3db'
            padding='2px'
          >
            План время
          </Box>
        )}
        {factTimeRow && (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='66px'
            minWidth='64px'
            width='100%'
            textAlign='center'
            borderRight='1px solid #d4d3db'
            padding='2px'
          >
            Факт время
          </Box>
        )}
        {beganRow && (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='66px'
            minWidth='74px'
            width='100%'
            textAlign='center'
            borderRight='1px solid #d4d3db'
            padding='2px'
          >
            Начал
          </Box>
        )}
        {completedRow && (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='66px'
            minWidth='84px'
            width='100%'
            textAlign='center'
            padding='2px'
          >
            Завершил
          </Box>
        )}
      </Menu>
    </Head>
  );
};
