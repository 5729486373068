import { useEffect, useRef, useState } from 'react';
import { BottomMenu, ChatInput, FileBlock, FileInput } from './styled';
import { SendMessageButton } from './SendMessageButton';
import { Box } from '@mui/material';
import { AddFileIcon } from './files/AddFileIcon';
import { FileIcon } from './files/FileIcon';
import { hubConnection } from '../../../Redux/Socket/socket';
import { HubConnectionState } from '@microsoft/signalr';
import { useAppSelector } from '../../../Redux/ReduxHooks';
import { fileToBase64 } from '../../../helpers/fileToBase64';

interface ChatFooterProps {
  id: number | null;
}

export const ChatFooter: React.FC<ChatFooterProps> = ({ id }) => {
  const { userResponse } = useAppSelector(state => state.userSlice);

  const [inputValue, setInputValue] = useState('');
  const [fileList, setFileList] = useState<FileList | null>(null);
  const [fileByte, setFileByte] = useState<any>([]);
  const filePicker = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const convertToBase64 = () => {
      if (fileList)
        for (let index = 0; index < fileList.length; index++) {
          const element = fileList[index];
          setFileByte([...fileByte, fileToBase64(element)]);
        }
    };
    convertToBase64();
  }, [fileList]);

  const refreshToken = userResponse?.refresh_token;

  const data = { id: id, message: inputValue, token: refreshToken, files: [] };

  const sendMessage = async () => {
    if (hubConnection.state === HubConnectionState.Disconnected) {
      hubConnection.start();
    }

    try {
      await hubConnection.invoke('PostChatMessage', data);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = () => {
    if (inputValue.trim()) {
      sendMessage();
      setInputValue('');
      setFileList(null);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && e.shiftKey) {
      setInputValue(inputValue + '\n');
      return;
    }

    if (e.key === 'Enter' && inputValue.trim()) {
      e.preventDefault();
      sendMessage();
      setInputValue('');
      setFileList(null);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFileList(e.target.files);
    }
  };

  return (
    <BottomMenu>
      <AddFileIcon
        onClick={() => {
          filePicker.current?.click();
        }}
      />
      <FileInput type='file' multiple ref={filePicker} onChange={handleFileChange} />
      <Box display='flex' flexDirection='column'>
        {fileList && fileList?.length !== 0 && (
          <FileBlock>
            {Array.from(fileList ?? []).map((item, i) => (
              <FileIcon key={item.name} name={item.name} />
            ))}
          </FileBlock>
        )}
        <ChatInput
          value={inputValue}
          placeholder='Введите сообщение ...'
          required={true}
          onKeyDown={handleKeyPress}
          onChange={e => setInputValue(e.target.value)}
        />
      </Box>
      <SendMessageButton onClick={onSubmit} />
    </BottomMenu>
  );
};
