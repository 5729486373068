import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../Redux/ReduxHooks';
import {
  isCurrentForm,
  openStaffById,
  openTaskFromId,
  slideBarIsOpen,
} from '../../Redux/Slices/SlideBar.slice';
import { Spinner } from '../../Compounds/spinner/Spinner';
import { SuccessButton, SuccessBlock } from './styled';
import { Box } from '@mui/material';
import { fetchAllTasks, fetchTasks } from '../../Redux/api/tasksApi';
import { fetchAllProjects } from '../../Redux/api/projectsApi';
import { fetchAllStaffs } from '../../Redux/api/staffApi';
import { modalClose } from '../../Redux/Slices/staff.slice';

export const SuccessTaskCreate = () => {
  const dispatch = useAppDispatch();
  const { taskCurrentList } = useAppSelector(state => state.currentListSlice);
  const { taskCreateResponse, taskLoading } = useAppSelector(state => state.taskSlice);
  const { allTaskLoading } = useAppSelector(state => state.allTasksSlice);
  const { projectCreateResponse, projectLoading, myProjectLoading } = useAppSelector(
    state => state.projectSlice
  );
  const { sliderBarIsOpen, currentAction, currentViewTaskId, currentStaffId } = useAppSelector(
    state => state.slideBarSlice
  );
  const { staffCreateResponse, staffLoading } = useAppSelector(state => state.staffSlice);

  const filterDate = taskCurrentList;

  useEffect(() => {
    dispatch(fetchAllTasks());
    dispatch(fetchAllProjects());
    dispatch(
      fetchAllStaffs({
        stringDepartmentParam: null,
        stringJobTitleParam: null,
        stringRoleParam: null,
        stringActiveParam: null,
      })
    );
    dispatch(
      fetchTasks({
        stringSupervisorParam: null,
        stringRecipientParam: null,
        filterStatus: null,
        filterDate: '',
      })
    );
  }, [taskCreateResponse, projectCreateResponse, staffCreateResponse, dispatch]);

  if (!sliderBarIsOpen) {
    return null;
  }

  const onClose = () => {
    dispatch(slideBarIsOpen(false));
  };

  const openById = () => {
    if (currentAction === 'taskCreate' && taskCreateResponse !== null) {
      dispatch(openTaskFromId(taskCreateResponse.task.id));
      dispatch(isCurrentForm('viewTask'));
      return;
    }
    if (currentAction === 'taskChange' && currentViewTaskId !== null) {
      dispatch(openTaskFromId(currentViewTaskId));
      dispatch(isCurrentForm('viewTask'));
      return;
    }
    if (currentAction === 'projectCreate' && projectCreateResponse !== null) {
      dispatch(openTaskFromId(projectCreateResponse.task.id));
      dispatch(isCurrentForm('viewProject'));
      return;
    }
    if (currentAction === 'projectChange' && currentViewTaskId !== null) {
      dispatch(openTaskFromId(currentViewTaskId));
      dispatch(isCurrentForm('viewProject'));
      return;
    }
    if (currentAction === 'staffCreate' && staffCreateResponse !== null) {
      dispatch(openStaffById(staffCreateResponse.value.staff.id));
      dispatch(modalClose());
      dispatch(isCurrentForm('viewStaff'));

      return;
    }
    if (currentAction === 'staffChange' && currentStaffId !== null) {
      dispatch(openStaffById(currentStaffId));
      dispatch(modalClose());
      dispatch(isCurrentForm('viewStaff'));
      return;
    }
    return alert('Что то пошло не так');
  };

  return (
    <SuccessBlock>
      {taskLoading || projectLoading || staffLoading || allTaskLoading || myProjectLoading ? (
        <Spinner />
      ) : (
        <>
          {currentAction === 'taskCreate' ? `Задача успешно создана` : null}
          {currentAction === 'projectCreate' ? `Проект успешно создан` : null}
          {currentAction === 'taskChange' ? `Задача успешно отредактирована` : null}
          {currentAction === 'projectChange' ? `Проект успешно отредактирован` : null}
          {currentAction === 'staffCreate' ? `Сотрудник Добавлен` : null}
          {currentAction === 'staffChange' ? `Карточка сотрудника отредактирована` : null}
          <Box width='100%' display='flex' justifyContent='space-between'>
            <SuccessButton onClick={() => openById()}>Просмотр</SuccessButton>
            <SuccessButton onClick={() => onClose()}>закрыть</SuccessButton>
          </Box>
        </>
      )}
    </SuccessBlock>
  );
};
