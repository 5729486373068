import { useLayoutEffect, useState } from 'react';
import { FakeInput, MainTitle } from './styled';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IFormInput } from '../../../Redux/@types/formTypes';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { ErrorInForm } from '../../../Compounds/form/ErrorInForm';
import { Button } from '@mui/joy';
import { useAppDispatch, useAppSelector } from '../../../Redux/ReduxHooks';
import { postStaff } from '../../../Redux/api/staffApi';
import { isCurrentAction, isCurrentForm } from '../../../Redux/Slices/SlideBar.slice';

export const passwordRegEx = /(?=.*\d.*)(?=.*[a-zA-Z].*).{8,}/;
export const mailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i;

export const StaffForm = () => {
  const dispatch = useAppDispatch();
  const { staffFilters, AllStaffResponse, staffCreateResponse } = useAppSelector(
    state => state.staffSlice
  );

  const [division, setDivision] = useState(' ');
  const [supervisor, setSupervisor] = useState(' ');
  const [post, setPost] = useState(' ');

  useLayoutEffect(() => {
    if (staffCreateResponse !== null) {
      dispatch(isCurrentAction('staffCreate'));
      dispatch(isCurrentForm('success'));
    }
  }, [staffCreateResponse, dispatch]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = data => {
    dispatch(postStaff({ data }));
    if (staffCreateResponse === null) {
      return;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MainTitle>Добавление Сотрудника</MainTitle>
      <Box marginTop='20px'>
        <FormControl fullWidth>
          <TextField
            id='name'
            label='ФИО*'
            InputLabelProps={{
              shrink: true,
            }}
            variant='outlined'
            multiline
            {...register('name', {
              required: true,
            })}
          />
        </FormControl>
        {errors.name && <ErrorInForm value={'Укажите ФИО Сотрудника'} />}
      </Box>
      <Box marginTop='20px'>
        <FormControl fullWidth>
          <TextField
            type='email'
            id='mail'
            label='Email*'
            InputLabelProps={{
              shrink: true,
            }}
            variant='outlined'
            multiline
            {...register('mail', {
              required: true,
              pattern: mailRegEx,
            })}
          />
        </FormControl>
        {errors.mail && <ErrorInForm value={'Укажите корректный Email Сотрудника'} />}
      </Box>
      <Box marginTop='20px'>
        <FormControl fullWidth>
          <TextField
            id='password'
            label='Пароль*'
            InputLabelProps={{
              shrink: true,
            }}
            variant='outlined'
            multiline
            {...register('password', {
              required: true,
              minLength: 8,
              pattern: passwordRegEx,
            })}
          />
        </FormControl>
        {errors.password && (
          <ErrorInForm value={'Пароль должен содержать 8 символов, заглавную букву и цифру'} />
        )}
      </Box>
      <Box marginTop='20px'>
        <FormControl fullWidth>
          <InputLabel id='division'>Отдел*</InputLabel>
          <Select
            labelId='division'
            id='select-division'
            value={division}
            label={'Отдел'}
            disabled={false}
            onChange={e => setDivision(e.target.value)}
          >
            {staffFilters?.filterDivisions.map((item, i) => (
              <MenuItem key={i} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FakeInput
          disabled={true}
          value={division}
          {...register('division', {
            required: true,
            minLength: 3,
            value: division,
          })}
        />
        {errors.supervisor && <ErrorInForm value={'Укажите отдел'} />}
      </Box>
      <Box marginTop='20px'>
        <FormControl fullWidth>
          <InputLabel id='supervisorId'>Руководитель*</InputLabel>
          <Select
            labelId='supervisorId'
            id='select-supervisor'
            value={supervisor}
            label={'Руководитель'}
            disabled={false}
            onChange={e => setSupervisor(e.target.value)}
          >
            {AllStaffResponse?.value.map((item, i) => (
              <MenuItem key={i} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FakeInput
          disabled={true}
          value={supervisor}
          {...register('supervisor', {
            required: true,
            minLength: 3,
            value: supervisor,
          })}
        />
        {errors.supervisor && <ErrorInForm value={'Укажите руководителя'} />}
      </Box>
      <Box marginTop='20px'>
        <FormControl fullWidth>
          <InputLabel id='post'>Должность*</InputLabel>
          <Select
            labelId='post'
            id='select-post'
            value={post}
            label={'Должность'}
            disabled={false}
            onChange={e => setPost(e.target.value)}
          >
            {staffFilters?.filterPosts.map((item, i) => (
              <MenuItem key={i} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FakeInput
          disabled={true}
          value={post}
          {...register('post', {
            required: true,
            minLength: 3,
            value: post,
          })}
        />
        {errors.post && <ErrorInForm value={'Укажите ответственного'} />}
      </Box>
      <Button fullWidth sx={{ mt: 4, fontSize: '20px' }} type='submit'>
        Создать
      </Button>
    </form>
  );
};
