import { Box } from '@mui/material';
import { Table, TableHeader, List } from './styled';
import { useAppSelector } from '../../Redux/ReduxHooks';
import { AnalyticsTableItem } from './AnalyticsTableItem';

export const AnalyticsTable = () => {
  const { analyticsFilterTaskFrom, analyticsFilterTaskTo } = useAppSelector(
    state => state.filtersSlice
  );
  const { analyticsResponse } = useAppSelector(state => state.analyticsSlice);
  const { analyticCurrent, analyticCompleted } = useAppSelector(state => state.showTableSlice);

  return (
    <Table>
      <TableHeader>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='66px'
          minWidth='100px'
          width='100%'
          maxWidth='190px'
          textAlign='center'
          borderLeft='1px solid #d4d3db'
        >
          Проект
        </Box>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='66px'
          minWidth='100px'
          width='100%'
          maxWidth='190px'
          textAlign='center'
          borderLeft='1px solid #d4d3db'
        >
          Время на проект
        </Box>

        <Box display='flex' width='100%' marginRight='1px'>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='66px'
            minWidth='150px'
            width='100%'
            textAlign='center'
            borderLeft='1px solid #d4d3db'
          >
            Сотрудник
          </Box>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='66px'
            minWidth='150px'
            width='100%'
            textAlign='center'
            borderLeft='1px solid #d4d3db'
          >
            Должность
          </Box>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='66px'
            minWidth='150px'
            width='100%'
            textAlign='center'
            borderLeft='1px solid #d4d3db'
          >
            Время сотрудника
          </Box>
          {analyticCurrent && (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              height='66px'
              minWidth='150px'
              width='100%'
              textAlign='center'
              borderLeft='1px solid #d4d3db'
            >
              Текущие задачи
            </Box>
          )}
          {analyticCompleted && (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              height='66px'
              minWidth='150px'
              width='100%'
              textAlign='center'
              borderLeft='1px solid #d4d3db'
            >
              Выполненные
            </Box>
          )}
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='66px'
            minWidth='150px'
            width='100%'
            textAlign='center'
            borderLeft='1px solid #d4d3db'
          >
            {`За период: с ${analyticsFilterTaskFrom !== null ? analyticsFilterTaskFrom : '_'} по ${
              analyticsFilterTaskTo !== null ? analyticsFilterTaskTo : '_'
            }`}
          </Box>
        </Box>
      </TableHeader>
      <List>
        {analyticsResponse?.value.map((item, i) => (
          <AnalyticsTableItem key={i} index={i} item={item} />
        ))}
      </List>
    </Table>
  );
};
