import { styled } from '@mui/material';

export const FileImage = styled('div')({
  position: 'relative',
  width: '50px',
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '10px',
  marginTop: '10px',
});

export const FileName = styled('div')({
  fontSize: '14px',
  wordWrap: 'break-word',
  // cursor: 'help',
});

export const HoverText = styled('div')({
  minHeight: '50px',
  zIndex: '3',
  maxWidth: '200px',
  pointerEvents: 'none',
  boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.4)',
  borderRadius: '4px',
  padding: '4px',
  position: 'absolute',
  fontSize: '16px',
  wordWrap: 'break-word',
  background: 'white',
  bottom: '0px',
});

export const AddFileIconChat = styled('div')({
  cursor: 'pointer',
});
