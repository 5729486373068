import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectToken } from '../Slices/user.slice';
import { RootState } from '../store';
import { makeRequest } from './makeRequest';
import { ISearchResponse } from '../@types/searchTypes';

export const GetSearch = createAsyncThunk<
  ISearchResponse,
  { searchValue: string },
  { rejectValue: string }
>('search/GetSearch', async ({ searchValue }, { rejectWithValue, getState }) => {
  const token = selectToken(getState() as RootState);
  const res = await makeRequest.get(`/GetSearch`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
    params: {
      param: searchValue,
    },
  });

  if (res.status !== 200) {
    return rejectWithValue('Не удалось получить данные');
  }

  return res.data;
});
