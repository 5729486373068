import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { selectToken } from '../Slices/user.slice';
import { makeRequest } from './makeRequest';
import { IAnalyticsFilterRes, IAnalyticsResponse } from '../@types/analyticsType';

export const fetchAnalytics = createAsyncThunk<
  IAnalyticsResponse,
  {
    filterProj: string | null;
    filterStaff: string | null;
    filterJobTitle: string | null;
    filterTaskFrom: string | null;
    filterTaskTo: string | null;
    filterArchiveProj: string | null;
  },
  { rejectValue: string }
>(
  'analytics/GetTatistic',
  async (
    { filterProj, filterStaff, filterJobTitle, filterTaskFrom, filterTaskTo, filterArchiveProj },
    { rejectWithValue, getState }
  ) => {
    const token = selectToken(getState() as RootState);
    const res = await makeRequest.get(`/GetStatistic`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
      params: {
        filterProj: filterProj,
        filterStaff: filterStaff,
        filterJobTitle: filterJobTitle,
        filterTaskFrom: filterTaskFrom,
        filterTaskTo: filterTaskTo,
        filterArchiveProj: filterArchiveProj,
      },
    });

    if (res.status !== 200) {
      return rejectWithValue('Не удалось получить данные');
    }
    if (res.data.statusCode === 401) {
      return rejectWithValue('needAuth');
    }

    return res.data;
  }
);

export const GetStatisticFilters = createAsyncThunk<
  IAnalyticsFilterRes,
  void,
  { rejectValue: string }
>('analytics/GetStatisticFilters', async (_, { rejectWithValue, getState }) => {
  const token = selectToken(getState() as RootState);
  const res = await makeRequest.get(`/GetStatisticFilters`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });

  if (res.data.statusCode === 401) {
    return rejectWithValue('needAuth');
  }

  return res.data;
});
