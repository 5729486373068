import { Box } from '@mui/material';
import { ChatContent } from './ChatContent';
import { ChatFooter } from './ChatFooter';
import { useAppDispatch, useAppSelector } from '../../../Redux/ReduxHooks';
import { ITaskItem } from '../../../Redux/@types/taskItem';
import { ChatHeader } from './styled';
import { SearchIcon } from './SearchIcon';
import { useEffect, useRef, useState } from 'react';
import { SearchChatInput } from './SearchChatInput';
import { hubConnection } from '../../../Redux/Socket/socket';
import { HubConnectionState } from '@microsoft/signalr';
import { closeChat } from '../../../Redux/Slices/chat.slice';

export const Chat = () => {
  const { currentChatId } = useAppSelector(state => state.slideBarSlice);
  const { allTaskResponse } = useAppSelector(state => state.allTasksSlice);
  const dispatch = useAppDispatch();

  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setSearchIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (hubConnection.state == HubConnectionState.Disconnected) {
      hubConnection.start();
    }

    return () => {
      hubConnection.stop();
      dispatch(closeChat());
    };
  });

  const foundElement = allTaskResponse?.value.find((item: ITaskItem) => item.id === currentChatId);

  return (
    <Box display='flex' flexDirection='column'>
      <ChatHeader>
        <Box width='400px'>Обсуждение: {!searchIsOpen && foundElement?.desc}</Box>
        <SearchIcon setSearchIsOpen={setSearchIsOpen} />
        {searchIsOpen && (
          <SearchChatInput
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            inputRef={inputRef}
          />
        )}
      </ChatHeader>
      <Box height='60vh'>
        <ChatContent searchValue={searchValue} />
      </Box>
      <Box display='flex' alignItems='center' justifyContent='center'>
        <ChatFooter id={currentChatId} />
      </Box>
    </Box>
  );
};
