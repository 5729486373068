import { SearchIconChat } from './styled';

interface SearchIconProps {
  setSearchIsOpen: (value: boolean) => void;
}

export const SearchIcon: React.FC<SearchIconProps> = ({ setSearchIsOpen }) => {
  return (
    <SearchIconChat onClick={() => setSearchIsOpen(true)}>
      <svg
        width='20'
        height='20'
        viewBox='0 0 26 26'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M26 24.6402L18.7373 17.3775C20.4826 15.2823 21.3529 12.5949 21.1672 9.8743C20.9815 7.15375 19.7541 4.60952 17.7403 2.77089C15.7265 0.932263 13.0814 -0.0592068 10.3552 0.00273714C7.62902 0.0646811 5.03167 1.17527 3.10347 3.10347C1.17527 5.03167 0.0646811 7.62902 0.00273714 10.3552C-0.0592068 13.0814 0.932263 15.7265 2.77089 17.7403C4.60952 19.7541 7.15375 20.9815 9.8743 21.1672C12.5949 21.3529 15.2823 20.4826 17.3775 18.7373L24.6402 26L26 24.6402ZM1.95777 10.613C1.95777 8.90113 2.46539 7.22774 3.41643 5.8044C4.36748 4.38106 5.71924 3.2717 7.30077 2.61661C8.8823 1.96152 10.6226 1.79011 12.3015 2.12408C13.9805 2.45804 15.5227 3.28237 16.7331 4.49282C17.9436 5.70327 18.7679 7.24548 19.1019 8.92443C19.4358 10.6034 19.2644 12.3436 18.6093 13.9252C17.9542 15.5067 16.8449 16.8585 15.4215 17.8095C13.9982 18.7606 12.3248 19.2682 10.613 19.2682C8.31825 19.2656 6.11824 18.3529 4.49563 16.7303C2.87302 15.1077 1.96031 12.9077 1.95777 10.613Z'
          fill='black'
        />
      </svg>
    </SearchIconChat>
  );
};
