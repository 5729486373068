import { Box } from '@mui/material';
import { StructureHeadBlock } from '../StructurePage/styled';
import {
  DateNotifInfo,
  DateNotifTableInfo,
  NotifInfo,
  NotifTableInfo,
  NotifTableItem,
} from './styled';
import { useAppSelector } from '../../Redux/ReduxHooks';
import dayjs from 'dayjs';

export const NotifTable = () => {
  const { notifResponse } = useAppSelector(state => state.notifSlice);

  return (
    <Box display='flex' flexDirection='column' marginTop='30px' width='86vw'>
      <StructureHeadBlock>
        <DateNotifTableInfo>Дата и время</DateNotifTableInfo>
        <NotifTableInfo>Уведомление</NotifTableInfo>
      </StructureHeadBlock>
      {notifResponse?.map((item, i) => (
        <NotifTableItem opacity={item.see} currentIndex={i}>
          <DateNotifInfo>{dayjs(item.date).format('HH:mm DD.MM.YYYY ')}</DateNotifInfo>
          <NotifInfo>{item.content}</NotifInfo>
        </NotifTableItem>
      ))}
    </Box>
  );
};
