import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectToken } from '../Slices/user.slice';
import { RootState } from '../store';
import { makeRequest } from './makeRequest';
import { ISettingsType } from '../@types/settingsType';

export const PatchNotificationSettings = createAsyncThunk<
  unknown,
  { data: ISettingsType },
  { rejectValue: string }
>('settings/PatchNotificationSettings', async ({ data }, { rejectWithValue, getState }) => {
  const token = selectToken(getState() as RootState);
  const res = await makeRequest.patch(
    `/PatchNotificationSettings`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
      params: {
        brow_newTask: data.brow_newTask,
        brow_changeStatus: data.brow_changeStatus,
        brow_confirmation: data.brow_confirmation,
        brow_changePrioroty: data.brow_changePrioroty,
        brow_changeDedline: data.brow_changeDedline,
        brow_reassigning: data.brow_reassigning,
        mail_newTask: data.mail_newTask,
        mail_changeStatus: data.mail_changeStatus,
        mail_confirmation: data.mail_confirmation,
        mail_changePrioroty: data.mail_changePrioroty,
        mail_changeDedline: data.mail_changeDedline,
        mail_reassigning: data.mail_reassigning,
      },
    }
  );

  if (res.status !== 200) {
    return rejectWithValue('Не удалось получить данные');
  }
  if (res.data.statusCode === 401) {
    return rejectWithValue('needAuth');
  }

  return res.data;
});
