import { ITaskItem } from '../../Redux/@types/taskItem';
import { useAppDispatch } from '../../Redux/ReduxHooks';
import { isCurrentForm, openTaskFromId, slideBarIsOpen } from '../../Redux/Slices/SlideBar.slice';
import React from 'react';
import { CurrentTask, CurrnetTaskInfo } from './styled';

interface TaskInfoProps {
  tasks: ITaskItem[];
}

export const ProjectTaskInfo: React.FC<TaskInfoProps> = ({ tasks }) => {
  const dispatch = useAppDispatch();

  const viewCurrentTask = (id: number) => {
    dispatch(openTaskFromId(id));
    dispatch(isCurrentForm('viewTask'));
    dispatch(slideBarIsOpen(true));
  };

  return (
    <CurrnetTaskInfo>
      {tasks !== null && tasks?.length > 0 ? (
        tasks?.map((todo, i) => (
          <CurrentTask onClick={() => viewCurrentTask(todo.id)} key={i}>
            {todo.desc?.length > 20 ? todo.desc.slice(0, 20) + ' ...' : todo.desc}
          </CurrentTask>
        ))
      ) : (
        <span>-</span>
      )}
    </CurrnetTaskInfo>
  );
};
