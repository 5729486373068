import { Box } from '@mui/material';
import { SettingsHeaderItem } from './styled';

export const SettingsHeader = () => {
  return (
    <SettingsHeaderItem>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        borderLeft='1px solid #d4d3db'
        minWidth='800px'
        width='100%'
      >
        Уведомления
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        borderLeft='1px solid #d4d3db'
        minWidth='100px'
        width='100%'
      >
        На почту
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        borderLeft='1px solid #d4d3db'
        minWidth='100px'
        width='100%'
      >
        Внутри cистемы
      </Box>
    </SettingsHeaderItem>
  );
};
