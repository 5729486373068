import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Button } from '@mui/joy';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { IFormInput } from '../../../Redux/@types/formTypes';
import { ErrorInForm } from '../../../Compounds/form/ErrorInForm';
import { useAppDispatch, useAppSelector } from '../../../Redux/ReduxHooks';
import dayjs, { Dayjs } from 'dayjs';
import { PriorityButtons } from '../../../Compounds/form/PriorityButtons';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useState } from 'react';
import { isCurrentAction, isCurrentForm } from '../../../Redux/Slices/SlideBar.slice';
import { postTasks } from '../../../Redux/api/tasksApi';
import { TimeSelect } from '../TimeSelect';
import 'dayjs/locale/ru';
import { MainTitle, Author, FakeInput, DisableInput } from './styled';

dayjs.locale('ru');

export const TaksForm = () => {
  const dispatch = useAppDispatch();
  const currentDate = dayjs().format('YYYY.MM.DD');
  const [priority, setPriority] = useState(0);
  const [dateValue, setDateValue] = useState<Dayjs | null>(dayjs());
  const { userResponse } = useAppSelector(state => state.userSlice);
  const { taskError } = useAppSelector(state => state.taskSlice);
  const { projectResponse } = useAppSelector(state => state.projectSlice);
  const { staffResponse } = useAppSelector(state => state.staffSlice);

  const authorName = userResponse?.user_name;
  const role = userResponse?.user_role;

  const [projectCode, setProjectCode] = useState(' ');
  const [recipient, setRecipient] = useState(' ');
  const [supervisor, setSupervisor] = useState(' ');
  const [number, setNumber] = useState('0');
  const [timeValue, setTimeValue] = useState('Минут');
  const [planTimeInForm, setPlanTimeInForm] = useState('');

  useEffect(() => {
    setPlanTimeInForm(`${number} ${timeValue}`);
  }, [number, timeValue]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = data => {
    dispatch(postTasks({ data }));
    if (taskError) {
      alert('Что то пошло не так или неверный формат');
      return;
    }
    dispatch(isCurrentAction('taskCreate'));
    dispatch(isCurrentForm('success'));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MainTitle>Создание Задачи</MainTitle>
      <Author>
        {'Автор: '}
        <DisableInput color='neutral' disabled={true} value={authorName} />
      </Author>
      <Author>
        {'Дата создания: '}
        <DisableInput
          color='neutral'
          disabled={true}
          value={dayjs(currentDate).format('DD.MM.YYYY')}
          {...register('date', {
            required: true,
            value: currentDate,
          })}
        />
      </Author>
      <Box marginTop='20px'>
        <FormControl fullWidth>
          <InputLabel shrink={true} id='projectCode'>
            Шифр Проекта*
          </InputLabel>
          <Select
            labelId='projectCode'
            id='select-project'
            value={projectCode}
            label={'Шифр Проекта'}
            disabled={false}
            onChange={e => setProjectCode(e.target.value)}
          >
            {projectResponse?.projects.map((item, i) => (
              <MenuItem key={i} value={item.code}>
                {item.code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FakeInput
          disabled={true}
          value={projectCode}
          {...register('projectCode', {
            required: true,
            minLength: 3,
            value: projectCode,
          })}
        />
        {errors.projectCode && <ErrorInForm value={'Укажите шифр проекта'} />}
      </Box>
      <Box marginTop='20px'>
        <FormControl fullWidth>
          <TextField
            id='desc'
            label='Описание*'
            InputLabelProps={{
              shrink: true,
            }}
            variant='outlined'
            multiline
            {...register('desc', {
              required: true,
            })}
          />
        </FormControl>
        {errors.desc && <ErrorInForm value={'Укажите описание'} />}
      </Box>
      {role !== 'Сотрудник' && (
        <Box marginTop='20px'>
          <FormControl fullWidth>
            <InputLabel id='supervisorId'>Контроль*</InputLabel>
            <Select
              labelId='supervisorId'
              id='select-supervisor'
              value={supervisor}
              label={'Контроль'}
              disabled={false}
              onChange={e => setSupervisor(e.target.value)}
            >
              {staffResponse?.value.map((item, i) => (
                <MenuItem key={i} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FakeInput
            disabled={true}
            value={supervisor}
            {...register('supervisor', {
              required: true,
              minLength: 3,
              value: supervisor,
            })}
          />
          {errors.supervisor && <ErrorInForm value={'Укажите Ответственного'} />}
        </Box>
      )}
      {role !== 'Сотрудник' && (
        <Box marginTop='20px'>
          <FormControl fullWidth>
            <InputLabel id='recipientId'>Исполнитель</InputLabel>
            <Select
              labelId='recipientId'
              id='select-recipient'
              value={recipient}
              label={'Исполнитель'}
              disabled={false}
              onChange={e => setRecipient(e.target.value)}
            >
              {staffResponse?.value.map((item, i) => (
                <MenuItem key={i} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FakeInput
            disabled={true}
            value={recipient}
            {...register('recipient', {
              required: false,
              value: recipient,
            })}
          />
          {errors.recipient && <ErrorInForm value={'Исполнитель'} />}
        </Box>
      )}
      <Box display='flex' justifyContent='cneter' alignItems='center' marginTop='20px'>
        <FormControl fullWidth>
          <Controller
            control={control}
            name='date'
            render={() => (
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
                <DatePicker
                  format='DD.MM.YYYY'
                  label='Дата завершения*'
                  value={dateValue}
                  onChange={newValue => setDateValue(newValue)}
                  disablePast={true}
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>
      </Box>
      <FakeInput
        disabled={true}
        value={dateValue?.format('YYYY.MM.DD')}
        {...register('dedline', {
          required: true,
          value: dateValue?.format('YYYY.MM.DD'),
        })}
      />
      {errors.dedline && <ErrorInForm value={'Укажите дату завершения'} />}
      <Box fontSize='14px'>Планируемое время исполнения*</Box>
      <TimeSelect
        foundPlannedTime={undefined}
        setNumber={setNumber}
        setTimeValue={setTimeValue}
        number={number}
        timeValue={timeValue}
      />
      <FakeInput
        disabled={true}
        value={planTimeInForm}
        {...register('plannedTime', {
          required: true,
          value: planTimeInForm,
        })}
      />
      {errors.plannedTime && <ErrorInForm value={'Укажите планируемое время исполнения'} />}
      <Box marginTop='10px' fontSize='14px'>
        Приоритет*
      </Box>
      <PriorityButtons disabled={false} priority={priority} setPriority={setPriority} />
      <FakeInput
        disabled={true}
        value={priority}
        {...register('priority', {
          required: true,
          value: priority,
        })}
      />
      <Button fullWidth sx={{ mt: 4, fontSize: '20px' }} type='submit'>
        Создать
      </Button>
    </form>
  );
};
