import { Link } from 'react-router-dom';
import { NotFoundPage, MainInfo } from './styled';
import { ButtonCreate } from '../../Compounds/buttons/styled';
import { Box } from '@mui/joy';

export const NeedAuthPage = () => {
  return (
    <NotFoundPage>
      <MainInfo>
        <Box marginBottom='20px'>Необходимо войти в приложение</Box>
        <Link to={'/SignIn'}>
          <ButtonCreate>К авторизации</ButtonCreate>
        </Link>
      </MainInfo>
    </NotFoundPage>
  );
};
