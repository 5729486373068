import { styled } from '@mui/material';

export const NavigationIcons = styled('div')({
  background: 'white',

  width: '130px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRight: '3px solid #f2f2f2',
  zIndex: '2',
});

export const NavBarWrapper = styled('div')({
  minWidth: '130px',
  height: '100%',
  zIndex: '1',
});

export const Dashboard = styled('div')<{ isActive: string }>(({ isActive }) => ({
  fontWeight: '600',
  width: '130px',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: '0.2s',
  '&:hover': {
    background: '#F8F8F8',
  },
  background: isActive === 'Tasks' ? '#f2f2f2' : 'white',
}));

export const Employee = styled('div')<{ isActive: string }>(({ isActive }) => ({
  fontWeight: '600',
  width: '130px',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: '0.2s',
  '&:hover': {
    background: '#F8F8F8',
  },
  background: isActive === 'Staffs' ? '#f2f2f2' : 'white',
}));

export const Product = styled('div')<{ isActive: string }>(({ isActive }) => ({
  fontWeight: '600',
  width: '130px',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: '0.2s',
  '&:hover': {
    background: '#F8F8F8',
  },
  background: isActive === 'Project' ? '#f2f2f2' : 'white',
}));

export const Coupon = styled('div')<{ isActive: string }>(({ isActive }) => ({
  fontWeight: '600',
  width: '130px',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: '0.2s',
  '&:hover': {
    background: '#F8F8F8',
  },
  background: isActive === 'Coupon' ? '#f2f2f2' : 'white',
}));

export const Analytics = styled('div')<{ isActive: string }>(({ isActive }) => ({
  fontWeight: '600',
  width: '130px',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: '0.2s',
  '&:hover': {
    background: '#F8F8F8',
  },
  background: isActive === 'Analytics' ? '#f2f2f2' : 'white',
}));

export const Settings = styled('div')<{ isActive: string }>(({ isActive }) => ({
  fontWeight: '600',
  width: '130px',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: '0.2s',
  '&:hover': {
    background: '#F8F8F8',
  },
  background: isActive === 'Settings' ? '#f2f2f2' : 'white',
}));

export const Notifications = styled('div')<{ isActive: string }>(({ isActive }) => ({
  marginTop: '40px',
  fontWeight: '600',
  width: '130px',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: '0.2s',
  '&:hover': {
    background: '#F8F8F8',
  },
  background: isActive === 'Notifications' ? '#f2f2f2' : 'white',
}));

export const Dot = styled('div')({
  background: 'red',
  borderRadius: '50%',
  width: '10px',
  height: '10px',
});

export const Exit = styled('div')({
  bottom: '0px',
  fontWeight: '600',
  width: '130px',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: '0.2s',
  '&:hover': {
    background: '#F8F8F8',
  },
});
