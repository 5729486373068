import { createSlice } from '@reduxjs/toolkit';
import { GetSearch } from '../api/searchApi';
import { ISearchResponse } from '../@types/searchTypes';

interface SearchState {
  searchResponse: ISearchResponse | null;
  searchLoading: boolean;
  searchError?: string;
}

const initialState: SearchState = {
  searchResponse: null,
  searchLoading: false,
  searchError: undefined,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(GetSearch.pending, state => {
        state.searchLoading = true;
        state.searchError = undefined;
      })
      .addCase(GetSearch.fulfilled, (state, action) => {
        state.searchResponse = action.payload;
        state.searchLoading = false;
      })
      .addCase(GetSearch.rejected, (state, action) => {
        state.searchError = action.error.message;
        state.searchLoading = false;
      });
  },
});

export default searchSlice.reducer;
