import { styled } from '@mui/material';

export const SettingsHeaderItem = styled('div')({
  display: 'flex',
  height: '60px',
  justifyContent: 'space-between',
  fontWeight: '600',
  background: '#f6f6f6',
});

export const SettingsTableItem = styled('div')({
  display: 'flex',
  height: '60px',
  justifyContent: 'space-between',
});

export const SaveButton = styled('button')({
  width: '190px',
  height: '36px',
  borderRadius: '4px',
  border: 'none',
  fontSize: '18px',
  fontWeight: '500',
  background: '#4f51f4',
  color: 'white',
  cursor: 'pointer',
  transition: '0.2s',
  fontFamily: "'Mulish', sans-serif",
  '&:hover': {
    background: '#1314b0',
  },
});
