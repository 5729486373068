import { keyframes, styled } from '@mui/material';

export const slideInRight = keyframes`
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }`;

export const opacityConfig = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}`;

export const Overlay = styled('div')({
  position: 'fixed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  height: '100vh',
  width: '100vw',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  animationName: `${opacityConfig}`,
  animationDuration: '0.5s',
  zIndex: '3',
  transition: '0.4s',
});

export const ChatSideBar = styled('div')({
  borderRadius: '10px',
  border: '1px solid gray',
  position: 'absolute',
  width: '450px',
  background: '#ffffff',
  maxHeight: '84vh',
  overflowY: 'scroll',
  animationName: `${slideInRight}`,
  animationDuration: '0.5s',
  '::-webkit-scrollbar': {
    width: '0px',
  },
});

export const SideBar = styled('div')({
  borderRadius: '10px',
  border: '1px solid gray',
  padding: '30px',
  position: 'absolute',
  width: '420px',
  background: '#ffffff',
  maxHeight: '84vh',
  overflowY: 'scroll',
  animationName: `${slideInRight}`,
  animationDuration: '0.5s',
  '::-webkit-scrollbar': {
    width: '0px',
  },
});

export const Shadow = styled('div')({
  width: '100%',
  height: '100%',
});

export const DisableDateInput = styled('input')({
  border: 'none',
  width: '200px',
  background: 'white',
  fontSize: '16px',
});

export const ConfirmButton = styled('button')({
  border: 'none',
  marginTop: '20px',
  width: '200px',
  height: '40px',
  borderRadius: '4px',
  fontWeight: '500',
  fontSize: '18px',
  background: '#4f51f4',
  color: 'white',
  cursor: 'pointer',
  transition: '0.3s',
  fontFamily: "'Mulish', sans-serif",
  '&:hover': {
    background: '#1314b0',
  },
});

export const SuccessBlock = styled('div')({
  marginTop: '40vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: '600',
  fontSize: '18px',
  textAlign: 'center',
});

export const SuccessButton = styled('button')({
  border: 'none',
  marginTop: '30px',
  width: '200px',
  height: '40px',
  borderRadius: '4px',
  fontWeight: '500',
  fontSize: '18px',
  background: '#4f51f4',
  color: 'white',
  cursor: 'pointer',
  transition: '0.2s',
  fontFamily: "'Mulish', sans-serif",
  '&:hover': {
    background: '#1314b0',
  },
});

export const CloseButton = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  left: '260px',
  top: '4vh',
  color: 'white',
  position: 'absolute',
  cursor: 'pointer',
});
