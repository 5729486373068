import { keyframes, styled } from '@mui/material';

const rotateAnimation = keyframes`
 0% { transform: rotate(0deg)}
 100% { transform: rotate(360deg) }`;

export const StyledSpiner = styled('svg')({
  animation: `${rotateAnimation} 1s infinite`,
});

export const SpinnerBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const LoadingInfo = styled('div')({
  marginTop: '20px',
  opacity: '0.5',
});
