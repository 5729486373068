import { Box } from '@mui/material';
import { LayoutAnalytics } from './styled';
import { PageTitle } from '../ProjectPage/styled';
import { AnalyticFilters } from './AnalyticFilters';
import { Spinner } from '../../Compounds/spinner/Spinner';
import { AnalyticsTable } from './AnalyticsTable';
import { useAppSelector } from '../../Redux/ReduxHooks';

export const Analytics = () => {
  const { analyticsLoading } = useAppSelector(state => state.analyticsSlice);

  return (
    <LayoutAnalytics>
      <Box display='flex' alignItems='center' marginTop='4px'>
        <PageTitle>Аналитика</PageTitle>
      </Box>
      <AnalyticFilters />
      {analyticsLoading ? (
        <Box height='50vh' width='86vw' display='flex' justifyContent='center' alignItems='center'>
          <Spinner />
        </Box>
      ) : (
        <AnalyticsTable />
      )}
    </LayoutAnalytics>
  );
};
