import { Box, styled } from '@mui/material';
import dayjs from 'dayjs';
import { ProjectTaskInfo } from './ProjectTaskInfo';
import { Content } from '../../Redux/@types/projectTypes';
import React from 'react';

const ProjectTodoInfo = styled('div')<{ currentIndex: number }>(({ currentIndex }) => ({
  display: 'flex',
  minHeight: '40px',
  borderBottom: '1px solid #d4d3db',
  backgroundColor: currentIndex % 2 === 0 ? 'white' : '#f6f6f6',
}));

interface ProjectTaskItemProps {
  date: string;
  content: Content[];
  index: number;
}

export const ProjectTaskItem: React.FC<ProjectTaskItemProps> = ({ date, content, index }) => {
  return (
    <ProjectTodoInfo currentIndex={index}>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        minWidth='174px'
        borderRight='1px solid #d4d3db'
        borderLeft='1px solid #d4d3db'
        padding='2px'
      >
        {dayjs(date).format('DD.MM.YYYY')}
      </Box>
      <Box display='flex'>
        {content.map((items, i) => (
          <ProjectTaskInfo key={i} tasks={items.tasks} />
        ))}
      </Box>
    </ProjectTodoInfo>
  );
};
